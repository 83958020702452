import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const GiftIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24.001"
    viewBox="0 0 24 24.001"
    fill="currentColor"
  >
    <g transform="translate(0 -1)">
      <path
        className="a"
        d="M23,5H20.6A3.69,3.69,0,0,0,21,3.467,3.6,3.6,0,0,0,17.333,0,6.213,6.213,0,0,0,12,3.517,6.184,6.184,0,0,0,6.7,0H6.586A3.594,3.594,0,0,0,3,3.467v.066A3.557,3.557,0,0,0,3.379,5H1A1,1,0,0,0,0,6V9a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V6A1,1,0,0,0,23,5ZM17.364,2A1.6,1.6,0,0,1,19,3.467,1.588,1.588,0,0,1,17.425,5H13.478C14.1,3.741,15.337,2,17.364,2ZM5,3.5A1.6,1.6,0,0,1,6.614,2c2.069.008,3.281,1.731,3.91,3h-3.9A1.616,1.616,0,0,1,5,3.5Z"
        transform="translate(0 0.001)"
      />
      <path
        className="a"
        d="M14,12V24h5a3,3,0,0,0,3-3V12Z"
        transform="translate(0 0.001)"
      />
      <path
        className="a"
        d="M10,12H2v9a3,3,0,0,0,3,3h5Z"
        transform="translate(0 0.001)"
      />
    </g>
  </svg>, 'GiftIcon'
);

export default GiftIcon;
