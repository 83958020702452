import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Typography, Popover } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useAuth from '../../hooks/useAuth';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { experimentalStyled, useTheme } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import PaymentIcon from '@material-ui/icons/Payment';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DonationsIcon from '../../icons/DonationsIcon';
import CashBackIcon from '../../icons/CashbackIcon';
import RewardsIcon from '../../icons/RewardsIcon';
import RecommendIcon from '../../icons/RecommendIcon';
import GiftIcon from '../../icons/GiftIcon';
import EditIcon from '../../icons/EditIcon';
import CoBranderIcon from '../../icons/CoBranderIcon';
import SettingsIcon from '../../icons/SettingsIcon';
import { ROLE, LANGUAGE } from 'src/constants';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { setLanguage } from 'src/api';
import { useTranslation } from 'react-i18next';
import { cognitoConfig } from 'src/config';
import toast from 'react-hot-toast';
import { handleLogout } from 'src/utils/common';
import useStrapiCms from 'src/hooks/useStrapiCms';

const LogoutIcon = '/static/icons/logout_icon.svg';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const languageOptions = {
  en: {
    label: 'EN'
  },
  de: {
    label: 'DE'
  }
};

const StyledDivider = experimentalStyled(Divider)(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.primary.contrastText
    }
  )
);

const LogoutIconWrapper = experimentalStyled('img')(
  {
    display: 'inline-block',
    float: 'left',
    marginTop: '5px',
    height: '13px'
  }
);

const ImageWrapper = experimentalStyled('img')(
  {
    maxWidth: '100%',
    width: '100%',
    display: 'block',
    margin: 'auto'
  }
);

const LanguageContainer = experimentalStyled('div')(
  {
    float: 'right',
    display: 'inline-block',
    marginTop: '3px'
  }
);

const LanguagePopoverText = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      fontSize: '15px',
      letterSpacing: '1.78px',
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      padding: '5px',
      color: theme.palette.primary.main
    }
  )
);

const ExpandMoreIconWrapper = experimentalStyled(ExpandMoreIcon)(
  ({ theme }) => (
    {
      color: theme.palette.primary.contrastText,
      fontSize: '21px',
      marginLeft: '-5px',
      display: 'inline-block'
    }
  )
);

const KeyboardArrowRightIconWrapper = experimentalStyled(KeyboardArrowRightIcon)(
  ({ theme }) => (
    {
      color: theme.palette.primary.contrastText,
      fontSize: '21px',
      marginLeft: '-5px',
      display: 'inline-block'
    }
  )
);

const LanguageTextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.contrastText,
      fontSize: '15px',
      letterSpacing: '1.78px',
      display: 'inline-block',
      verticalAlign: 'super'
    }
  )
);

const UserDetailsNavSection = experimentalStyled(Box)(
  {
    padding: 2,
    bottom: '10px',
    width: '220px',
    marginLeft: '20px',
    marginTop: 'auto'
  }
);

const AdminNavGroup = experimentalStyled(Box)(
  {
    padding: '0px',
    width: '100%',
    paddingLeft: '16px',
    marginTop: '150px',
    marginBottom: '25px'
  }
);

const BoxContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100vh'
  }
);

const LogoutTextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      fontSize: '15px',
      letterSpacing: '1.25px',
      color: theme.palette.primary.contrastText,
      display: 'inline-block',
      marginLeft: '5px',
      marginTop: '2px',
      float: 'left'
    }
  )
);

const sections = [
  {
    title: '',
    items: [
      {
        title: 'general.home',
        path: '/dashboard',
        icon: <HomeIcon fontSize="small" />
      },
      {
        title: 'general.transactions',
        path: '/dashboard/transactions',
        icon: <SyncAltIcon fontSize="small" />
      },
      {
        title: 'general.counos_wallet',
        path: '/dashboard/counos-wallet',
        icon: <PaymentIcon fontSize="small" />
      },
      {
        title: 'general.cashback',
        path: '/dashboard/cashback',
        icon: <CashBackIcon fontSize="small" />
      },
      {
        title: 'general.recommend',
        path: '/dashboard/recommend',
        icon: <RecommendIcon fontSize="small" />
      },
      {
        title: 'general.coupons',
        path: '/dashboard/vouchers',
        icon: <GiftIcon fontSize="small" />
      },
      {
        title: 'general.donations',
        path: '/dashboard/donations',
        icon: <DonationsIcon fontSize="small" />
      },
      {
        title: 'general.shops',
        path: '/dashboard/shops',
        icon: <ShoppingCartIcon fontSize="small" />
      },
      {
        title: 'general.rewards',
        path: '/dashboard/rewards',
        icon: <RewardsIcon fontSize="small" />
      }
    ]
  }
];

const adminSections = [
  {
    title: '',
    items: [
      {
        title: 'general.admin',
        path: '/dashboard/admin',
        icon: <SettingsIcon fontSize="small" />
      },
      {
        title: 'general.voucher_editor',
        path: '/dashboard/voucher-editor',
        icon: <EditIcon fontSize="small" />
      },
      {
        title: 'general.co_brander',
        path: '/dashboard/cobrander',
        icon: <CoBranderIcon fontSize="small" />
      },
      {
        title: 'general.cashback_processor',
        path: '/dashboard/cashbackprocessor',
        icon: <CashBackIcon fontSize="small" />
      }
    ]
  }
];

const partnerSections = [
  {
    title: '',
    items: [
      {
        title: 'general.admin',
        path: '/dashboard/admin',
        icon: <SettingsIcon fontSize="small" />
      },
      {
        title: 'general.voucher_editor',
        path: '/dashboard/voucher-editor',
        icon: <EditIcon fontSize="small" />
      }
    ]
  }
];

const ownerSections = [
  {
    title: '',
    items: [
      {
        title: 'general.admin',
        path: '/dashboard/admin',
        icon: <SettingsIcon fontSize="small" />
      },
      {
        title: 'general.voucher_editor',
        path: '/dashboard/voucher-editor',
        icon: <EditIcon fontSize="small" />
      },
      {
        title: 'general.co_brander',
        path: '/dashboard/cobrander_admin',
        icon: <CoBranderIcon fontSize="small" />
      }
    ]
  }
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user, updateLanguage } = useAuth();
  const { slug } = user.partner;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const theme = useTheme();
  const { i18n } = useTranslation();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { getDefaultSlug, defaultSlug } = useAuth();
  const { isContentLoaded, getRecommendationPageDetails, getDashboardPageDetails, recommendationPageContent, dashboardPageContent } = useStrapiCms();
  const Language = (i18n.languages[0] === LANGUAGE.ENGLISH || i18n.languages[0] === LANGUAGE.GERMAN) ? i18n.languages[0] : LANGUAGE.ENGLISH;

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const logout = async (): Promise<void> => {
    try {
      handleLogout(cognitoConfig);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handleChangeLanguage = async () => {
    if (slug) {
      if (Language === LANGUAGE.ENGLISH) {
        i18n.changeLanguage(LANGUAGE.GERMAN);
        setLanguage(LANGUAGE.GERMAN);
        await getRecommendationPageDetails(slug || localStorage.getItem('slug'), LANGUAGE.GERMAN);
        await getDashboardPageDetails(slug || localStorage.getItem('slug'), LANGUAGE.GERMAN);
      } else {
        i18n.changeLanguage(LANGUAGE.ENGLISH);
        setLanguage(LANGUAGE.ENGLISH);
        await getRecommendationPageDetails(slug || localStorage.getItem('slug'), LANGUAGE.ENGLISH);
        await getDashboardPageDetails(slug || localStorage.getItem('slug'), LANGUAGE.ENGLISH);
      }
    } else {
      await getDefaultSlug();
    }
    setOpen(false);

    if (user) {
      updateLanguage(i18n.language);
    }
  };

  const getContentFromCMS = async (partner: string) => {
    await getDashboardPageDetails(partner, Language);
    await getRecommendationPageDetails(partner, Language);
  };

  useEffect(() => {
    i18n.changeLanguage(user?.language);
    setLanguage(user?.language);
  }, []);

  useEffect(() => {
    getRecommendationPageDetails(slug || localStorage.getItem('slug'), Language);
    getDashboardPageDetails(slug || localStorage.getItem('slug'), Language);
  }, [slug, Language]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (defaultSlug && !slug) {
      localStorage.setItem('slug', defaultSlug);
    }
    if (defaultSlug) {
      getContentFromCMS(defaultSlug);
    }
  }, [defaultSlug]);

  useEffect(() => {
    const fetchDefaultSlug = async () => {
      await getDefaultSlug();
    };

    if (isContentLoaded && !recommendationPageContent && !dashboardPageContent) {
      fetchDefaultSlug();
    }
  }, [isContentLoaded]);

  const selectedOption = languageOptions[Language];
  const popoverOption = Language === LANGUAGE.ENGLISH ? languageOptions[LANGUAGE.GERMAN] : languageOptions[LANGUAGE.ENGLISH];

  const content = (
    <Box
      sx={{
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <BoxContainer>
          <Box>
            <Box sx={{ mb: 4 }}>
              <ImageWrapper
                alt="Partner Logo"
                src={user?.partner?.settings?.logo}
              />
            </Box>
          </Box>
          <Box sx={{ p: 2, padding: '0px', paddingLeft: '16px' }}>
            {sections?.map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 3
                  }
                }}
                {...section}
              />
            ))}
          </Box>
          {
            (user?.role === ROLE.ADMIN) && (
              <AdminNavGroup>
                {adminSections.map((section) => (
                  <NavSection
                    key={section.title}
                    pathname={location.pathname}
                    sx={{
                      '& + &': {
                        mt: 3
                      }
                    }}
                    {...section}
                  />
                ))}
              </AdminNavGroup>
            )
          }
          {
            (user?.role === ROLE.PARTNER) && (
              <AdminNavGroup>
                {partnerSections.map((section) => (
                  <NavSection
                    key={section.title}
                    pathname={location.pathname}
                    sx={{
                      '& + &': {
                        mt: 3
                      }
                    }}
                    {...section}
                  />
                ))}
              </AdminNavGroup>
            )
          }
          {
            (user?.role === ROLE.PARTNER_OWNER) && (
              <AdminNavGroup>
                {ownerSections.map((section) => (
                  <NavSection
                    key={section.title}
                    pathname={location.pathname}
                    sx={{
                      '& + &': {
                        mt: 3
                      }
                    }}
                    {...section}
                  />
                ))}
              </AdminNavGroup>
            )
          }
          <UserDetailsNavSection>
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.profilePicUrl}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                  margin: '5px'
                }}
              />
            </RouterLink>
            <Box sx={{ padding: '5px' }}>
              <Typography
                color={theme.palette.primary.contrastText}
                variant="body1"
              >
                {user.firstName}
              </Typography>
            </Box>

            <StyledDivider />
            <Box sx={{ padding: '5px', cursor: 'pointer' }}>
              <LogoutIconWrapper
                alt="Login"
                onClick={logout}
                src={LogoutIcon}
              />
              <LogoutTextTypography onClick={logout}>
                Logout
              </LogoutTextTypography>
              <LanguageContainer>
                <LanguageTextTypography
                  onClick={handleOpen}
                  ref={anchorRef}
                >
                  {selectedOption.label}
                </LanguageTextTypography>
                {
                  open ? (
                    <ExpandMoreIconWrapper
                      onClick={handleOpen}
                    />
                  ) : (
                    <KeyboardArrowRightIconWrapper
                      onClick={handleOpen}
                    />
                  )
                }
              </LanguageContainer>
            </Box>
          </UserDetailsNavSection>
          <Popover
            anchorEl={anchorRef.current}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'top'
            }}
            transformOrigin={{
              horizontal: 'center',
              vertical: 'bottom'
            }}
            keepMounted
            onClose={handleClose}
            open={open}
            PaperProps={{
              sx: { width: 55 }
            }}
          >
            <LanguagePopoverText
              onClick={handleChangeLanguage}
            >
              {popoverOption.label}
            </LanguagePopoverText>
          </Popover>
        </BoxContainer>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'var(--primary-color)',
            borderRight: 'none',
            height: '100%',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'var(--primary-color)',
          width: 280,
          height: '100%'
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
