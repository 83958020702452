import type { FC } from 'react';
import {
  Box,
  Container,
  Grid,
  experimentalStyled,
  useMediaQuery
} from '@material-ui/core';
import useSettings from '../../hooks/useSettings';
import MainLanding from '../../components/dashboard/landing/MainLanding';
import RecommendBlock from '../../components/dashboard/landing/RecommendBlock';
import CardBlock from '../../components/dashboard/landing/CardBlock';

const BoxContainer = experimentalStyled(Box)(
  {
    backgroundColor: 'background.default',
    minHeight: '100%',
    paddingTop: '24px',
    '@media (max-width:530px) and (min-width: 300px)': {
      '& .MuiContainer-root': {
        paddingRight: '0px',
        paddingLeft: '0px',
      }
    },
  }
);

const Landing: FC = () => {
  const { settings } = useSettings();

  return (
    <>
      <BoxContainer>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            sx={{
              display: 'table'
            }}
            spacing={3}
          >
            <Grid
              container
              sx={{
                display: 'inline-flex',
              }}
              item
              spacing={3}
              md={9}
              xs={12}
            >
              {
                 useMediaQuery('(max-width:900px)') && <CardBlock />
              }
              <MainLanding />
            </Grid>
            <Grid
              container
              sx={{
                display: 'inline-flex',
                verticalAlign: 'top'
              }}
              item
              spacing={3}
              md={3}
            >
              {
                 useMediaQuery('(min-width:901px)') && <CardBlock />
              }
              <RecommendBlock />
            </Grid>
          </Grid>
        </Container>
      </BoxContainer>
    </>
  );
};

export default Landing;
