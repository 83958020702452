import {
  Typography,
  experimentalStyled
} from '@material-ui/core';
import { RegExps } from 'src/constants';

interface CounterProps {
  initialValue: number;
  updateValue?: (value: number) => void;
  updateValueByKey?: (key: string, value: number) => void;
  maxValue?: number;
  objectKey?: string;
  valueType?: string;
  difference: number;
  isInputRequired?: boolean;
  acceptDecimalValue?: boolean;
}

const CashbackDivWrapper = experimentalStyled('div')(
  ({ theme }) => (
    {
      width: '100%',
      border: `2px solid ${theme.palette.primary.main}`,
      height: '35px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '25px',
      background: '#E7F0F5',
      padding: '2px 10px',
    }
  )
);

const LeftTextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      cursor: 'pointer',
      fontSize: '25px',
      color: theme.palette.primary.main
    }
  )
);

const RightTextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      cursor: 'pointer',
      fontSize: '25px',
      marginTop: '2px',
      color: theme.palette.primary.main
    }
  )
);

const CenterTextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      fontSize: '18px',
      color: theme.palette.primary.main
    }
  )
);

const InputBlockWrapper = experimentalStyled('input')(
  ({ theme }) => (
    {
      width: 'auto',
      maxWidth: '55px',
      textAlign: 'center',
      fontSize: '17px',
      background: '#E7F0F5',
      color: theme.palette.primary.main,
      border: 'none',
      outline: 'none !important',
      '@media (max-width: 600px)': {
        maxWidth: '45px'
      }
    }
  )
);

const ButtonGroupCounter = (props: CounterProps) => {
  const { initialValue, valueType, updateValue, difference, objectKey, updateValueByKey, isInputRequired, acceptDecimalValue, maxValue } = props;

  const decrement = () => {
    if (initialValue > 0) {
      const value = parseFloat((Number(initialValue) - difference).toFixed(2));

      if (objectKey) {
        updateValueByKey(objectKey, value);
      } else {
        updateValue(value);
      }
    }
  };

  const increment = () => {
    if (maxValue || maxValue === 0) {
      if (initialValue < maxValue) {
        const value = parseFloat((Number(initialValue) + difference).toFixed(2));
        if (objectKey) {
          updateValueByKey(objectKey, value);
        } else {
          updateValue(value);
        }
      }
    } else {
      const value = parseFloat((Number(initialValue) + difference).toFixed(2));
      if (objectKey) {
        updateValueByKey(objectKey, value);
      } else {
        updateValue(value);
      }
    }
  };

  const onChange = (e) => {
    const reqularExpression = acceptDecimalValue ? RegExps.ACCEPT_DECIMAL_NUMBER_REGX : RegExps.ACCEPT_NUMBER_REGX;
    const value = acceptDecimalValue ? e.target.value : Number(e.target.value);
    if (e.target.value === '' || reqularExpression.test(e.target.value)) {
      if (objectKey) {
        updateValueByKey(objectKey, value);
      } else {
        updateValue(value);
      }
    }
  };

  return (
    <CashbackDivWrapper>
      <LeftTextTypography
        onClick={decrement}
      >
        -
      </LeftTextTypography>
      {
        isInputRequired ? (
          <CenterTextTypography>
            <InputBlockWrapper
              value={initialValue}
              onChange={(event) => onChange(event)}
            />
            {valueType}
          </CenterTextTypography>
        ) : (
          <CenterTextTypography>
            {initialValue}
            {valueType}
          </CenterTextTypography>
        )
      }
      <RightTextTypography
        onClick={increment}
      >
        +
      </RightTextTypography>
    </CashbackDivWrapper>
  );
};

export default ButtonGroupCounter;
