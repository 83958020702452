import { useState } from 'react';
import {
  Grid,
  Box,
  experimentalStyled,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import useAuth from 'src/hooks/useAuth';
import { STRAPI_URL } from 'src/config';

const GridContainer = experimentalStyled(Grid)(
  {
    '@media (max-width:530px) and (min-width: 300px)': {
      paddingLeft: '0px',
      paddingRight: '0px',
      '& .MuiContainer-root .MuiContainer-maxWidthXl': {
        paddingRight: '0px'
      },
      '@media (max-width: 530px)': {
        '& .MuiPaper-root': {
          borderRadius: '0px'
        }
      }
    },
  }
);

const BoxContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: 'var(--primary-color)',
    position: 'relative'
  }
);

const LabelContainer = experimentalStyled(Box)(
  ({ theme }) => (
    {
      backgroundColor: 'var(--secondary-color)',
      color: theme.palette.primary.main,
      textAlign: 'center',
      borderRadius: '8px',
      width: '56px',
      height: '56px',
      left: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: '-35px',
      fontSize: '24px',
      fontWeight: 700,
      position: 'absolute'
    }
  )
);

const CardHovered = experimentalStyled(Box)(
  {
    paddingLeft: '30px',
    borderRadius: '8px',
    height: '180px',
    padding: '15px',
    transition: 'height 0.5s',
    display: 'flex',
    paddingTop: '15%',
    justifyContent: 'center',
    background: 'linear-gradient( 106.94deg, rgba(255, 255, 255, 0.57), rgba(255, 255, 255, 0.27) )',
    '&:hover': {
      background: 'var(--secondary-color)',
      height: '250px',
      transition: 'height 0.5s'
    },
    '@media (max-width: 768px)': {
      marginBottom: '20px',
      padding: '20px'
    }
  }
);

const BlockContainer = experimentalStyled(Box)(
  {
    backgroundColor: 'var(--primary-color)',
    textAlign: 'center',
    color: 'white',
    height: '230px',
    width: '80%',
    position: 'relative',
    margin: 'auto',
    '&:hover': {
      '& .label-container': {
        boxShadow: '0 4px 2px -2px gray'
      },
    },
  }
);

const FooterContainer = experimentalStyled(Box)(
  {
    padding: '20px',
    paddingBottom: '40px',
    backgroundColor: 'var(--primary-color)',
    width: '100%',
    color: 'white',
    '@media (max-width: 768px)': {
      textAlign: 'center'
    }
  }
);

const FooterMenu = experimentalStyled(Box)(
  {
    paddingLeft: '30px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      padding: '20px'
    }
  }
);

const SideTextContainer = experimentalStyled(Box)(
  {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    right: '5%',
    alignItems: 'center',
    top: '15%'
  }
);

const DotWrapper = experimentalStyled(Box)(
  {
    height: '18px',
    width: '18px',
    borderRadius: '10px',
    background: 'white',
    marginBottom: '10px'
  }
);

const VerticalAlignText = experimentalStyled(Typography)(
  {
    writingMode: 'vertical-rl',
    fontSize: '14px',
    color: 'white'
  }
);

const TextContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '85px',
    color: 'white',
    textAlign: 'center',
    '@media (max-width: 1024px)': {
      marginTop: '30px'
    }
  }
);

const TextTypography = experimentalStyled(Typography)(
  {
    width: '60%',
    textAlign: 'center',
    '@media (max-width: 1024px)': {
      width: '100%'
    }
  }
);

const DescriptionTextTypography = experimentalStyled(Typography)(
  {
    fontSize: '16px',
    width: '40%',
    marginTop: '30px',
    marginLeft: '20px',
    '@media (max-width: 1024px)': {
      width: '100%'
    }
  }
);

const QuestionTypography = experimentalStyled(Typography)(
  {
    fontSize: '20px',
    display: 'inline-block',
    color: 'var(--primary-color)',
    fontWeight: 800
  }
);

const AnswerTypography = experimentalStyled(Typography)(
  {
    fontSize: '16px',
    color: 'white',
    fontWeight: 400,
    marginTop: '20px'
  }
);

const ShareImageWrapper = experimentalStyled('img')(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      width: '20px',
      display: 'inline-block',
      margin: '0 5px'
    }
  )
);

const CarouselWrapper = experimentalStyled(Carousel)(
  {
    height: '450px'
  }
);

const InnerConatiner = experimentalStyled(Box)(
  {
    display: 'block',
    width: '100%'
  }
);

const LogoWrapper = experimentalStyled('img')(
  {
    maxHeight: '70px',
    marginLeft: '40px',
    marginBottom: '20px',
    '@media (max-width: 768px)': {
      marginLeft: 0
    }
  }
);

const FooterBlock = experimentalStyled(Box)(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
      flexDirection: 'column'
    }
  }
);

const FooterInnerBlock = experimentalStyled(Box)(
  {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
);

interface FAQS {
  id?: number,
  question: string,
  answer: string
}

interface SocialMedia {
  id?: number,
  name: string,
  url: string,
  icon: string
}

interface FooterProps {
  goodToKnowIntroduction: {
    title: string,
    heading: string,
    subHeading: string,
  },
  faqs: FAQS[],
  socialMedia: SocialMedia[]
}

const Footer = (props: FooterProps) => {
  const { goodToKnowIntroduction, faqs, socialMedia, ...rest } = props;
  const [value, setValue] = useState(0);
  const { isPartnerLogoLoaded, partnerLogo } = useAuth();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1900 },
      items: 4,
      partialVisibilityGutter: 40
    },
    smallDesktop: {
      breakpoint: { max: 1900, min: 1024 },
      items: 3,
    },
    largeTablet: {
      breakpoint: { max: 1024, min: 900 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 900, min: 768 },
      items: 2
    },
    largeMobile: {
      breakpoint: { max: 769, min: 550 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1
    }
  };

  return (
    <GridContainer
      {...rest}
      item
      md={12}
      xs={12}
    >
      <BoxContainer>
        {
          useMediaQuery('(min-width:1025px)') && (
            <SideTextContainer>
              <DotWrapper />
              <VerticalAlignText>
                {goodToKnowIntroduction?.title}
              </VerticalAlignText>
            </SideTextContainer>
          )
        }
        <InnerConatiner>
          <TextContainer>
            <TextTypography variant="h1">
              {goodToKnowIntroduction?.heading}
            </TextTypography>
            <DescriptionTextTypography>
              {goodToKnowIntroduction?.subHeading}
            </DescriptionTextTypography>
          </TextContainer>
          <Box>
            <Box sx={{ width: '100%' }}>
              {
                faqs && (
                  <CarouselWrapper
                    responsive={responsive}
                    arrows={false}
                    partialVisible={false}
                    showDots
                    autoPlay
                    infinite
                    autoPlaySpeed={5000}
                  >
                    {
                      faqs?.map((item, index) => (
                        <BlockContainer key={item.id}>
                          <CardHovered
                            onMouseEnter={() => setValue(item.id)}
                            onMouseLeave={() => setValue(0)}
                          >
                            <Box>
                              <QuestionTypography>
                                {item.question}
                              </QuestionTypography>
                              {
                                (item.id === value) && (
                                  <AnswerTypography>
                                    {item.answer}
                                  </AnswerTypography>
                                )
                              }
                            </Box>
                          </CardHovered>
                          <LabelContainer className="label-container">
                            {index + 1}
                          </LabelContainer>
                        </BlockContainer>
                      ))
                    }
                  </CarouselWrapper>
                )
              }
            </Box>
          </Box>
        </InnerConatiner>
      </BoxContainer>
      <FooterContainer>
        {
          isPartnerLogoLoaded && (
            <LogoWrapper
              src={partnerLogo}
              alt="partner logo"
            />
          )
        }
        <FooterBlock>
          <FooterInnerBlock>
            {
              socialMedia?.map((item: any) => (
                <FooterMenu
                  onClick={() => window.open(`//${item?.url}`, '_blank')}
                  key={item?.id}
                >
                  <ShareImageWrapper
                    alt={item?.name}
                    src={`${STRAPI_URL}${item?.icon}`}
                  />
                  {item?.name}
                </FooterMenu>
              ))
            }
          </FooterInnerBlock>
        </FooterBlock>
      </FooterContainer>
    </GridContainer>
  );
};

export default Footer;
