import {
  Box,
  experimentalStyled,
  Popover,
  Typography,
  Button
} from '@material-ui/core';
import { useRef, useState } from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import { LANGUAGE, Routes } from 'src/constants';
import { handleLogin, handleLogout } from 'src/utils/common';
import { useLocation, useParams } from 'react-router-dom';
import { cognitoConfig } from 'src/config';
import toast from 'react-hot-toast';
import { setLanguage } from 'src/api';
import useStrapiCms from 'src/hooks/useStrapiCms';

const languageOptions = {
  en: {
    label: 'EN'
  },
  de: {
    label: 'DE'
  }
};

const BoxContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 20,
    width: '100%',
    backdropFilter: 'blur(25px)',
    top: 0,
    boxShadow: '0px 0px 4px 4px rgb(0 0 0 / 25%)',
    padding: '15px 50px',
    '& .MuiButton-contained': {
      padding: '8px 25px',
      marginRight: '20px',
      borderRadius: '20px',
      '@media (max-width: 600px)': {
        marginRight: 0
      }
    },
    '@media (max-width: 600px)': {
      padding: '15px'
    },
    '@media (max-width: 450px)': {
      padding: '15px 5px'
    }
  }
);

const LanguageContainer = experimentalStyled('div')(
  {
    margin: '0 20px',
    cursor: 'pointer',
    height: '25px',
    '@media (max-width: 450px)': {
      margin: 0
    }
  }
);

const ButtonContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    alignItems: 'center'
  }
);

const LanguagePopoverText = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      fontSize: '15px',
      letterSpacing: '1.78px',
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      padding: '5px',
      color: theme.palette.primary.main
    }
  )
);

const ExpandMoreIconWrapper = experimentalStyled(ExpandMoreIcon)(
  {
    color: 'black',
    fontSize: '21px',
    marginLeft: '-5px',
    display: 'inline-block'
  }
);

const KeyboardArrowRightIconWrapper = experimentalStyled(KeyboardArrowRightIcon)(
  {
    color: 'black',
    fontSize: '21px',
    marginLeft: '-5px',
    display: 'inline-block'
  }
);

const LanguageTextTypography = experimentalStyled(Typography)(
  {
    color: 'black',
    fontSize: '15px',
    letterSpacing: '1.78px',
    display: 'inline-block',
    verticalAlign: 'super'
  }
);

const LogoWrapper = experimentalStyled('img')(
  {
    maxHeight: '70px',
    '@media (max-width: 450px)': {
      width: '150px'
    }
  }
);

const AlphaLandingTopBar = () => {
  const { user, partnerLogo, isPartnerLogoLoaded, updateLanguage } = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const location = useLocation();
  const { slug } = useParams();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const Language = (i18n.languages[0] === LANGUAGE.ENGLISH || i18n.languages[0] === LANGUAGE.GERMAN) ? i18n.languages[0] : LANGUAGE.ENGLISH;
  const { getGlassMorphismLandingPageDetails } = useStrapiCms();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleSignIn = (): void => {
    handleLogin(cognitoConfig);
  };

  const handleSignOut = async (): Promise<void> => {
    try {
      handleLogout(cognitoConfig);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handleChangeLanguage = async () => {
    if (Language === LANGUAGE.ENGLISH) {
      i18n.changeLanguage(LANGUAGE.GERMAN);
      setLanguage(LANGUAGE.GERMAN);
      await getGlassMorphismLandingPageDetails(slug || localStorage.getItem('slug'), LANGUAGE.GERMAN);
    } else {
      i18n.changeLanguage(LANGUAGE.ENGLISH);
      setLanguage(LANGUAGE.ENGLISH);
      await getGlassMorphismLandingPageDetails(slug || localStorage.getItem('slug'), LANGUAGE.ENGLISH);
    }
    setOpen(false);
    if (user) {
      updateLanguage(i18n.language);
    }
  };

  const selectedOption = languageOptions[Language];
  const popoverOption = Language === LANGUAGE.ENGLISH ? languageOptions[LANGUAGE.GERMAN] : languageOptions[LANGUAGE.ENGLISH];

  return (
    <BoxContainer>
      {
        isPartnerLogoLoaded && (
          <LogoWrapper
            src={partnerLogo}
            alt="partner logo"
          />
        )
      }
      <ButtonContainer>
        <LanguageContainer>
          <LanguageTextTypography
            onClick={handleOpen}
            ref={anchorRef}
          >
            {selectedOption.label}
          </LanguageTextTypography>
          {
            open ? (
              <KeyboardArrowRightIconWrapper
                onClick={handleOpen}
              />
            ) : (
              <ExpandMoreIconWrapper
                onClick={handleOpen}
              />
            )
          }
        </LanguageContainer>
        <Popover
          anchorEl={anchorRef.current}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom'
          }}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }}
          keepMounted
          onClose={handleClose}
          open={open}
          PaperProps={{
            sx: { width: 55 }
          }}
        >
          <LanguagePopoverText
            onClick={handleChangeLanguage}
          >
            {popoverOption.label}
          </LanguagePopoverText>
        </Popover>
        {
          location.pathname === Routes.REGISTER && (
            <Box sx={{
              marginLeft: '40px'
            }}
            >
              <Box sx={{ padding: '5px' }}>
                <Typography
                  color="white"
                  variant="body1"
                  marginRight="20px"
                >
                  {user.firstName}
                </Typography>
              </Box>
            </Box>
          )
        }
        {
          location.pathname === Routes.REGISTER ? (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleSignOut}
            >
              Sign Out
            </Button>
          ) : (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleSignIn}
            >
              Sign In
            </Button>
          )
        }
      </ButtonContainer>
    </BoxContainer>
  );
};

export default AlphaLandingTopBar;
