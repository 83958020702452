import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const CoBranderIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="26"
    viewBox="0 0 21 30"
    fill="currentColor"
  >
    <path
      className="a"
      d="M18,3A10.512,10.512,0,0,0,7.5,13.5C7.5,21.375,18,33,18,33S28.5,21.375,28.5,13.5A10.512,10.512,0,0,0,18,3Zm0,3a3,3,0,1,1-3,3A3.009,3.009,0,0,1,18,6Zm0,15a7.174,7.174,0,0,1-6-3.225c.03-1.98,4.005-3.075,6-3.075s5.97,1.1,6,3.075A7.174,7.174,0,0,1,18,21Z"
      transform="translate(-7.5 -3)"
    />
  </svg>, 'CoBranderIcon'
);

export default CoBranderIcon;
