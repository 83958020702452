import { useEffect, useRef, useState } from 'react';
import {
  experimentalStyled,
  Box
} from '@material-ui/core';
import { STRAPI_URL } from 'src/config';

const ImageWrapper = experimentalStyled(Box)(
  {
    width: '350px',
    '@media (max-width: 600px)': {
      width: '250px'
    },
    '@media (max-width: 450px)': {
      width: '150px'
    }
  }
);

interface JourneyProcess {
  id?: number,
  step: string,
  image: string
}

interface StepperIconsProps {
  isVisibleInPort: boolean;
  journeyProcess: JourneyProcess[];
}

const StepperIcons = (props: StepperIconsProps) => {
  const [icon, setIcon] = useState('');
  const { isVisibleInPort, journeyProcess } = props;
  const isInitialMount = useRef(true);
  const [timeOutIds, setTimeOutIds] = useState<any>([]);

  const removeIcon = () => {
    setIcon('');
  };

  const CustomChangeIcons = (index: number) => {
    let currentIndex = index;
    if (index === journeyProcess.length) {
      currentIndex = 0;
      setIcon(`${STRAPI_URL}${journeyProcess[currentIndex]?.image}`);
    } else if (isVisibleInPort) {
      setIcon(`${STRAPI_URL}${journeyProcess[currentIndex]?.image}`);
      currentIndex++;
    }
    if (isVisibleInPort) {
      const timerID = setTimeout(() => CustomChangeIcons(currentIndex), 2500);
      const tempArray = timeOutIds;
      timeOutIds.push(timerID);
      setTimeOutIds(tempArray);
    } else {
      removeIcon();
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (!isVisibleInPort) {
      removeIcon();
      timeOutIds.forEach((item) => {
        clearTimeout(item);
      });
    } else if (isVisibleInPort) {
      setTimeout(() => { CustomChangeIcons(0); }, 2000);
    }
  }, [isVisibleInPort]);

  return (
    <>
      <ImageWrapper>
        {
          icon && (
            <img
              src={icon}
              width="100%"
              alt="Notes Gif"
            />
          )
        }
      </ImageWrapper>
    </>
  );
};

export default StepperIcons;
