import { useState, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { experimentalStyled } from '@material-ui/core/styles';
import OnboardSideBar from './landingSidebar';
import DashboardNavbar from './DashboardNavbar';
import SessionExpiredModal from './SessionExpiredModal';
import useAuth from 'src/hooks/useAuth';
import Landing from 'src/pages/dashboard/Landing';
import { LANGUAGE, Routes } from 'src/constants';
import { cognitoConfig } from 'src/config';
import { handleLogin } from 'src/utils/common';
import useStrapiCms from 'src/hooks/useStrapiCms';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlphaLanding from 'src/pages/dashboard/AlphaLanding';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    }
  )
);

const CircularProgressWrapper = experimentalStyled(CircularProgress)(
  {
    position: 'absolute',
    height: '60px !important',
    width: '60px !important',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  }
);

const DashboardLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => (
    {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '280px'
      }
    }
  )
);

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  '@media (max-width:1279px)': {
    paddingTop: '65px'
  }
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const OnboardDashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false);
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { updatePartnerLogo, updateIsPartnerLogoLoaded, getDefaultSlug, isSlugExists, defaultSlug, getLandingPageLayout, layout } = useAuth();
  const { getLandingPageDetails, getGlassMorphismLandingPageDetails, isContentLoaded, landingPageContent, glassMorphismLandingPageContent } = useStrapiCms();
  const [landingLayout, setLayout] = useState(false);

  const getContentFromCMS = async (partner: string) => {
    const Language = (i18n.languages[0] === LANGUAGE.ENGLISH || i18n.languages[0] === LANGUAGE.GERMAN) ? i18n.languages[0] : LANGUAGE.ENGLISH;
    if (layout === 'default') {
      await getLandingPageDetails(partner, Language);
    } else if (layout === 'alpha') {
      await getGlassMorphismLandingPageDetails(partner, Language);
    }
  };

  const setPartnerLogo = async () => {
    if (slug) {
      await updatePartnerLogo(slug);
      localStorage.setItem('slug', slug);
    } else {
      await updateIsPartnerLogoLoaded();
      await getDefaultSlug();
    }
  };

  const handleClose = () => {
    setShowSessionExpiredModal(false);
  };

  const login = () => {
    handleLogin(cognitoConfig);
  };

  useEffect(() => {
    if (location.pathname !== Routes.DEACTIVATED && location.pathname !== Routes.REGISTER) {
      setPartnerLogo();
    } else {
      updateIsPartnerLogoLoaded();
      getContentFromCMS(localStorage.getItem('slug'));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (layout && layout === 'alpha') {
      setLayout(true);
    } else {
      setLayout(false);
    }
    getContentFromCMS(slug);
  }, [layout]);

  useEffect(() => {
    getLandingPageLayout(slug || localStorage.getItem('slug'));
  }, []);

  useEffect(() => {
    if (location.pathname !== Routes.DEACTIVATED && location.pathname !== Routes.REGISTER) {
      if (!isSlugExists) {
        navigate('/');
      }

      if (defaultSlug && !slug) {
        localStorage.setItem('slug', defaultSlug);
      }
    }
  }, [isSlugExists, defaultSlug]);

  useEffect(() => {
    if (sessionStorage.getItem('sessionExpired')) {
      setShowSessionExpiredModal(true);
      sessionStorage.removeItem('sessionExpired');
    }
  }, []);

  useEffect(() => {
    if (defaultSlug) {
      getContentFromCMS(defaultSlug);
    }
  }, [defaultSlug]);

  useEffect(() => {
    const fetchDefaultSlug = async () => {
      await getDefaultSlug();
    };

    if (isContentLoaded && ((layout === 'default' && !landingPageContent) || (layout === 'alpha' && !glassMorphismLandingPageContent))) {
      fetchDefaultSlug();
    }
  }, [isContentLoaded]);

  return (
    <div>
      {
        landingLayout ? (
          <AlphaLanding />
        ) : (
          <DashboardLayoutRoot>
            {
              isContentLoaded ? (
                <>
                  <DashboardNavbar
                    onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
                  />
                  <OnboardSideBar
                    onMobileClose={(): void => setIsSidebarMobileOpen(false)}
                    openMobile={isSidebarMobileOpen}
                  />
                  <DashboardLayoutWrapper>
                    <DashboardLayoutContainer>
                      <DashboardLayoutContent>
                        <Landing />
                      </DashboardLayoutContent>
                    </DashboardLayoutContainer>
                  </DashboardLayoutWrapper>
                </>
              ) : (
                <CircularProgressWrapper />
              )
            }
            {
              showSessionExpiredModal && (
                <SessionExpiredModal
                  openModal={showSessionExpiredModal}
                  handleClose={handleClose}
                  login={login}
                  title={t('general.session_expired')}
                  message={t('general.your_session_has_expired')}
                />
              )
            }
          </DashboardLayoutRoot>
        )
      }
    </div>
  );
};

export default OnboardDashboardLayout;
