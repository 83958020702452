import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const HamburgerMenu = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      clipRule="evenodd"
      d="M3 5C3 4.44772 3.44772 4 4 4H16C16.5523 4 17 4.44772 17 5C17 5.55228 16.5523 6 16 6H4C3.44772 6 3 5.55228 3 5Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M3 10C3 9.44772 3.44772 9 4 9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H4C3.44772 11 3 10.5523 3 10Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M3 15C3 14.4477 3.44772 14 4 14H10C10.5523 14 11 14.4477 11 15C11 15.5523 10.5523 16 10 16H4C3.44772 16 3 15.5523 3 15Z"
      fillRule="evenodd"
    />
  </svg>,
  'HamburgerMenu'
);

export default HamburgerMenu;
