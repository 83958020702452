import { DefaultApi } from 'strapi-cms-api';
import axios from 'axios';

// Create axios instance
const axiosInstance = axios.create();

// Configuration and base path are not provided
const cmsApiService = new DefaultApi(undefined, process.env.REACT_APP_STRAPI_URL, axiosInstance);

export { cmsApiService, axiosInstance };
