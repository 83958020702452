/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { User } from '../types/user';
import { apiService } from '../api';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { TOKENS } from 'src/constants';
import { Bank } from 'src/types/bank';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  cashbackRedeemSync: Date;
  preSignupAtDashboard: boolean;
  user: User | null;
  partnerLogo: string;
  partnerCardUrl: string;
  isSlugExists: boolean;
  defaultSlug: string;
  layout: string;
  isPartnerLogoLoaded: boolean;
  bankDetails: Bank | null;
  fileUrl: string;
  userBankAccountStatusMessage: string;
  userBankAccountStatus: string;
  isAccountBalanceLoaded: boolean;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  getAwsTokens: (code: string, slug: string) => Promise<void>;
  logout: () => Promise<void>;
  updatePartner: (data: User) => Promise<void>;
  getDefaultSlug: () => Promise<void>;
  getLandingPageLayout: (slug: string) => Promise<void>;
  updatePartnerLogo: (slug: string) => Promise<void>;
  updateIsPartnerLogoLoaded: () => Promise<void>;
  updateProfilePic: (profilePicUrl: string) => Promise<void>;
  updateLanguage: (language: string) => Promise<void>;
  updatePartnerCardUrl: (partnerCardUrl: string, id: string) => Promise<void>;
  fetchBankDetails: () => Promise<void>;
  updateBankDetails: (balance: number) => Promise<void>;
  updateIsCashbackPageVisited: (isCashbackPageVisited: boolean) => Promise<void>;
  cashbackFileUpload: (fileName: string, fileUrl: string) => Promise<void>;
  notifyCashbackRedeemSuccess: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    preSignupAtDashboard: boolean;
    user: User | null;
    partnerLogo: string;
    userBankAccountStatusMessage: string;
    userBankAccountStatus: string;
  };
};

type CashbackRedeemAction = {
  type: 'CASHBACKREDEEMSUCCESS';
  payload: {
    cashbackRedeemSync: Date;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    partnerLogo: string;
  };
};

type GetAwsTokensActions = {
  type: 'GETAWSTOKENS';
  payload: {
    user: User;
    preSignupAtDashboard: boolean;
    partnerLogo: string;
    userBankAccountStatusMessage: string;
    userBankAccountStatus: string;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type UpdateProfilePicAction = {
  type: 'UPDATEPROFILEPIC';
  payload: {
    user: User;
  };
};

type UpdateLanguageAction = {
  type: 'UPDATELANGUAGE';
  payload: {
    user: User;
  };
};

type UpdateIsCashbackPageVisitedAction = {
  type: 'UPDATEISCASHBACKPAGEVISITED';
  payload: {
    user: User;
  };
};

type DefaultSlugAction = {
  type: 'GETDEFAULTSLUG';
  payload: {
    defaultSlug: string;
    partnerLogo: string;
    layout: string;
  };
};

type LandingPageLayoutAction = {
  type: 'GETLANDINGPAGELAYOUT',
  payload: {
    layout: string
  }
};

type CashbackFileUploadAction = {
  type: 'CASHBACKFILEUPLOAD';
  payload: {
    name: string;
    fileUrl: string;
  };
};

type UpdatePartnerCardUrlAction = {
  type: 'UPDATEPARTNERCARDURL';
  payload: {
    user: User;
  };
};

type UpdatePartnerAction = {
  type: 'UPDATEPARTNER';
  payload: {
    user: User;
  };
};

type UpdatePartnerLogoAction = {
  type: 'UPDATEPARTNERLOGO',
  payload: {
    partnerLogo: string;
    partnerCardUrl: string;
    isSlugExists: boolean;
    isPartnerLogoLoaded: boolean;
  }
};

type UpdateIsPartnerLogoLoadedAction = {
  type: 'UPDATEISPARTNERLOGOLOADED',
  payload: {
    isPartnerLogoLoaded: boolean;
    isSlugExists?: boolean;
  }
};

type FetchBankDetailsAction = {
  type: 'FETCHBANKDETAILS',
  payload: {
    bankDetails: Bank;
    isAccountBalanceLoaded: boolean;
  }
};

type UpdateBankDetailsAction = {
  type: 'UPDATEBANKDETAILS',
  payload: {
    bankDetails: Bank;
  }
};

type Action =
  | InitializeAction
  | CashbackRedeemAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | UpdateProfilePicAction
  | UpdateLanguageAction
  | UpdatePartnerAction
  | UpdatePartnerCardUrlAction
  | UpdatePartnerLogoAction
  | GetAwsTokensActions
  | UpdateIsCashbackPageVisitedAction
  | CashbackFileUploadAction
  | FetchBankDetailsAction
  | UpdateBankDetailsAction
  | UpdateIsPartnerLogoLoadedAction
  | DefaultSlugAction
  | LandingPageLayoutAction;

const initialState: State = {
  fileUrl: '',
  isAuthenticated: false,
  isInitialized: false,
  cashbackRedeemSync: new Date(),
  preSignupAtDashboard: false,
  user: null,
  partnerLogo: '',
  partnerCardUrl: '',
  isSlugExists: true,
  defaultSlug: '',
  layout: '',
  bankDetails: null,
  isPartnerLogoLoaded: false,
  userBankAccountStatusMessage: '',
  userBankAccountStatus: '',
  isAccountBalanceLoaded: false
};

const handlers: Record<string, (state: State, action: Action) => State> = {

  CASHBACKREDEEMSUCCESS: (state: State, action: CashbackRedeemAction): State => {
    const { cashbackRedeemSync } = action.payload;
    return {
      ...state,
      cashbackRedeemSync
    };
  },
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user, preSignupAtDashboard, userBankAccountStatusMessage, userBankAccountStatus } = action.payload;
    if (user && user?.partner?.settings?.primaryColor && user?.partner?.settings?.secondaryColor) {
      document.documentElement.style.setProperty('--primary-color', user?.partner?.settings?.primaryColor);
      document.documentElement.style.setProperty('--secondary-color', user?.partner?.settings?.secondaryColor);
    }
    return {
      ...state,
      isAuthenticated,
      preSignupAtDashboard,
      userBankAccountStatusMessage,
      userBankAccountStatus,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user, partnerLogo } = action.payload;
    if (user.partner.settings.primaryColor && user.partner.settings.secondaryColor) {
      document.documentElement.style.setProperty('--primary-color', user.partner.settings.primaryColor);
      document.documentElement.style.setProperty('--secondary-color', user.partner.settings.secondaryColor);
    }

    return {
      ...state,
      isAuthenticated: true,
      user,
      partnerLogo
    };
  },
  UPDATEPARTNER: (state: State, action: LoginAction): State => {
    const { user } = action.payload;
    if (user.partner.settings.primaryColor && user.partner.settings.secondaryColor) {
      document.documentElement.style.setProperty('--primary-color', user.partner.settings.primaryColor);
      document.documentElement.style.setProperty('--secondary-color', user.partner.settings.secondaryColor);
    }

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  UPDATEPROFILEPIC: (state: State, action: UpdateProfilePicAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      user
    };
  },
  UPDATELANGUAGE: (state: State, action: UpdateLanguageAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      user
    };
  },
  UPDATEISCASHBACKPAGEVISITED: (state: State, action: UpdateIsCashbackPageVisitedAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      user
    };
  },
  CASHBACKFILEUPLOAD: (state: State, action: CashbackFileUploadAction): State => {
    const { fileUrl } = action.payload;
    return {
      ...state,
      fileUrl
    };
  },
  UPDATEPARTNERCARDURL: (state: State, action: UpdatePartnerCardUrlAction): State => {
    const { user } = action.payload;
    return { ...state, user };
  },
  GETDEFAULTSLUG: (state: State, action: DefaultSlugAction): State => {
    const { defaultSlug, partnerLogo, layout } = action.payload;
    return {
      ...state,
      defaultSlug,
      partnerLogo,
      layout
    };
  },
  GETLANDINGPAGELAYOUT: (state: State, action: LandingPageLayoutAction): State => {
    const { layout } = action.payload;
    return {
      ...state,
      layout
    };
  },
  UPDATEPARTNERLOGO: (state: State, action: UpdatePartnerLogoAction): State => {
    const { partnerLogo, isPartnerLogoLoaded, partnerCardUrl, isSlugExists } = action.payload;

    return {
      ...state,
      partnerLogo,
      partnerCardUrl,
      isSlugExists,
      isPartnerLogoLoaded
    };
  },
  UPDATEISPARTNERLOGOLOADED: (state: State, action: UpdateIsPartnerLogoLoadedAction): State => {
    const { isPartnerLogoLoaded, isSlugExists } = action.payload;

    return {
      ...state,
      isPartnerLogoLoaded,
      isSlugExists
    };
  },
  GETAWSTOKENS: (state: State, action: GetAwsTokensActions): State => {
    const { user, partnerLogo, preSignupAtDashboard, userBankAccountStatusMessage, userBankAccountStatus } = action.payload;
    if (user?.partner?.settings?.primaryColor && user?.partner?.settings?.secondaryColor) {
      document.documentElement.style.setProperty('--primary-color', user.partner.settings.primaryColor);
      document.documentElement.style.setProperty('--secondary-color', user.partner.settings.secondaryColor);
    }

    return {
      ...state,
      isAuthenticated: true,
      preSignupAtDashboard,
      userBankAccountStatusMessage,
      userBankAccountStatus,
      user,
      partnerLogo
    };
  },
  FETCHBANKDETAILS: (state: State, action: FetchBankDetailsAction): State => {
    const { bankDetails, isAccountBalanceLoaded } = action.payload;

    return {
      ...state,
      bankDetails,
      isAccountBalanceLoaded
    };
  },

  UPDATEBANKDETAILS: (state: State, action: UpdateBankDetailsAction): State => {
    const { bankDetails } = action.payload;

    return {
      ...state,
      bankDetails
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateProfilePic: () => Promise.resolve(),
  updateLanguage: () => Promise.resolve(),
  updatePartnerCardUrl: () => Promise.resolve(),
  updatePartner: () => Promise.resolve(),
  getDefaultSlug: () => Promise.resolve(),
  getLandingPageLayout: () => Promise.resolve(),
  updatePartnerLogo: () => Promise.resolve(),
  updateIsPartnerLogoLoaded: () => Promise.resolve(),
  getAwsTokens: () => Promise.resolve(),
  fetchBankDetails: () => Promise.resolve(),
  updateBankDetails: () => Promise.resolve(),
  updateIsCashbackPageVisited: () => Promise.resolve(),
  cashbackFileUpload: () => Promise.resolve(),
  notifyCashbackRedeemSuccess: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation();

  const notifyCashbackRedeemSuccess = async (): Promise<void> => {
    dispatch({
      type: 'CASHBACKREDEEMSUCCESS',
      payload: {
        cashbackRedeemSync: new Date()
      }
    });
  };

  const updateBankDetails = async (balance: number): Promise<void> => {
    const { bankDetails } = state;
    bankDetails.balance.amount = balance;
    dispatch({
      type: 'UPDATEBANKDETAILS',
      payload: {
        bankDetails
      }
    });
  };
  const fetchBankDetails = async (): Promise<void> => {
    await apiService.fetchBankDetails().then((data: any) => {
      const bankDetails = data?.data?.data?.account;
      const { isAccountBalanceLoaded } = data?.data?.data;

      dispatch({
        type: 'FETCHBANKDETAILS',
        payload: {
          bankDetails,
          isAccountBalanceLoaded
        }
      });
    }).catch(() => {
      dispatch({
        type: 'FETCHBANKDETAILS',
        payload: {
          bankDetails: null,
          isAccountBalanceLoaded: false
        }
      });
    });
  };

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {
          fetchBankDetails();
          const userData = await apiService.getUser();
          const { user } = userData?.data?.data;
          if (!user.isCashbackPageVisited) {
            user.isCashbackPageVisited = false;
          }
          user.id = user?._id;
          user.partner = userData?.data?.data?.partner;
          user.isRegisteredAtShop = userData?.data?.data?.isRegisteredAtShop;
          const { preSignupAtDashboard, userBankAccountStatusMessage, userBankAccountStatus } = userData?.data?.data;
          const partnerLogo = userData?.data?.data?.partner?.settings?.logo;
          user.partnerId = userData?.data?.data?.partner?._id;
          user.role = userData?.data?.data?.user?.roles[0]?.code;
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              preSignupAtDashboard,
              userBankAccountStatusMessage,
              userBankAccountStatus,
              user,
              partnerLogo
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              preSignupAtDashboard: false,
              userBankAccountStatusMessage: '',
              userBankAccountStatus: '',
              user: null,
              partnerLogo: ''
            }
          });
        }
      } catch (err) {
        const refreshToken = window.localStorage.getItem('refreshToken');
        if (refreshToken) {
          fetchBankDetails();
          const refreshData = await apiService.refreshToken({ refreshToken });
          localStorage.setItem(TOKENS.ACCESS_TOKEN, refreshData.data.accessToken);
          const userData = await apiService.getUser();
          const { user } = userData.data.data;
          const { preSignupAtDashboard, userBankAccountStatusMessage, userBankAccountStatus } = userData.data.data;
          user.id = user._id;
          user.partner = userData?.data?.data?.partner;
          const partnerLogo = userData?.data?.data?.partner?.settings?.logo;
          user.partnerId = userData?.data?.data?.partner?._id;
          user.role = userData?.data?.data?.user?.roles[0]?.code;
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              preSignupAtDashboard,
              userBankAccountStatusMessage,
              userBankAccountStatus,
              user,
              partnerLogo
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              preSignupAtDashboard: false,
              userBankAccountStatusMessage: '',
              userBankAccountStatus: '',
              user: null,
              partnerLogo: ''
            }
          });
        }
      }
    };

    initialize();
  }, []);

  const login = async (password: string, email?: string, name?: string): Promise<void> => {
    const loginData = await apiService.login({ email, password, name });
    const { accessToken, refreshToken } = loginData.data.data.tokens;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    const userData = await apiService.getUser();
    const { user } = userData.data.data;
    user.id = user._id;
    user.partner = userData.data.data.partner;
    user.partnerId = userData.data.data.partner._id;
    user.role = userData.data.data.user.roles[0].code;
    const partnerLogo = user.partner.settings?.logo;

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        partnerLogo
      }
    });
  };

  const updatePartner = async (data: User): Promise<void> => {
    await apiService.updatePartner(data).then((partnerData) => {
      const { user } = state;
      user.partner = partnerData.data.data;
      dispatch({
        type: 'UPDATEPARTNER',
        payload: {
          user
        }
      });
      toast.success(`${t('cobrander.partner_updated_successfully')}`);
    }).catch((error) => {
      toast.error(`${error?.response?.data?.message}`);
    });
  };

  const getDefaultSlug = async (): Promise<void> => {
    await apiService.getPartner('default').then((data: any) => {
      const { partner } = data?.data?.data;
      const defaultSlug = partner?.slug;
      const partnerLogo = partner?.settings?.logo;
      const layout = partner?.landingLayout;

      dispatch({
        type: 'GETDEFAULTSLUG',
        payload: {
          defaultSlug,
          partnerLogo,
          layout
        }
      });
    });
  };

  const getLandingPageLayout = async (slug: string): Promise<void> => {
    await apiService.getPartner(slug).then((data: any) => {
      const layout = data?.data?.data?.partner?.landingLayout;

      dispatch({
        type: 'GETLANDINGPAGELAYOUT',
        payload: {
          layout
        }
      });
    });
  };

  const logout = async (): Promise<void> => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    dispatch({ type: 'LOGOUT' });
  };

  const updateProfilePic = async (profilePicUrl: string): Promise<void> => {
    await apiService.updateUser({ profilePicUrl }).then((data) => {
      const { user } = state;
      user.profilePicUrl = data && data.data && data.data.data && data.data.data.profilePicUrl;

      dispatch({
        type: 'UPDATEPROFILEPIC',
        payload: {
          user
        }
      });
      toast.success(`${t('general.profile_pic_updated_successfully')}`);
    }).catch((error) => {
      toast.error(`${error.response.data.message}`);
    });
  };

  const updateLanguage = async (language: string): Promise<void> => {
    await apiService.updateUser({ language }).then((data) => {
      const { user } = state;
      user.language = data && data.data && data.data.data && data.data.data.language;

      dispatch({
        type: 'UPDATELANGUAGE',
        payload: {
          user
        }
      });
      toast.success(`${t('general.language_updated_successfully')}`);
    }).catch((error) => {
      toast.error(`${error.response.data.message}`);
    });
  };

  const updatePartnerCardUrl = async (partnerCardUrl: string, id: string): Promise<void> => {
    await apiService.updatePartner({ partnerCardUrl, id }).then((data) => {
      const { user } = state;
      user.partner.partnerCardUrl = data && data.data && data.data.data && data.data.data.partnerCardUrl;
      dispatch({
        type: 'UPDATEPARTNERCARDURL',
        payload: {
          user
        }
      });
      toast.success(`${t('general.card_updated_successfully')}`);
    }).catch((error) => {
      toast.error(`${error.response.data.message}`);
    });
  };

  const updatePartnerLogo = async (slug: string): Promise<void> => {
    await apiService.getPartner(slug).then((data) => {
      const { settings, partnerCardUrl } = data.data.data.partner;
      const partnerLogo = settings.logo;
      document.documentElement.style.setProperty('--primary-color', settings.primaryColor);
      document.documentElement.style.setProperty('--secondary-color', settings.secondaryColor);

      dispatch({
        type: 'UPDATEPARTNERLOGO',
        payload: {
          partnerLogo,
          partnerCardUrl,
          isSlugExists: true,
          isPartnerLogoLoaded: true
        }
      });
    }).catch(() => {
      dispatch({
        type: 'UPDATEISPARTNERLOGOLOADED',
        payload: {
          isPartnerLogoLoaded: true,
          isSlugExists: false
        }
      });
    });
  };

  const updateIsPartnerLogoLoaded = async (): Promise<void> => {
    dispatch({
      type: 'UPDATEISPARTNERLOGOLOADED',
      payload: {
        isPartnerLogoLoaded: true
      }
    });
  };

  const getAwsTokens = async (code: string, slug: string): Promise<void> => {
    const userData = await apiService.getAwsTokens({ code, slug });
    const tokenData = userData.data.data.tokens;
    const accessToken = tokenData.access_token;
    const refreshToken = tokenData.refresh_token;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    const { user } = userData.data.data;
    if (!user.isCashbackPageVisited) {
      user.isCashbackPageVisited = false;
    }
    user.isRegisteredAtShop = userData.data.data.isRegisteredAtShop;
    const { preSignupAtDashboard, userBankAccountStatusMessage, userBankAccountStatus } = userData.data.data;
    user.id = user._id;
    user.partner = userData.data.data.partner;
    user.partnerId = userData.data.data.partner._id;
    user.role = userData.data.data.user.roles[0].code;
    const partnerLogo = user.partner.settings?.logo;
    localStorage.setItem('slug', user.partner.slug);

    dispatch({
      type: 'GETAWSTOKENS',
      payload: {
        user,
        partnerLogo,
        preSignupAtDashboard,
        userBankAccountStatusMessage,
        userBankAccountStatus
      }
    });
    return userData?.data?.data;
  };

  const updateIsCashbackPageVisited = async (isCashbackPageVisited: boolean): Promise<void> => {
    await apiService.updateUser({ isCashbackPageVisited }).then((data) => {
      const { user } = state;
      user.isCashbackPageVisited = data && data.data && data.data.data && data.data.data.isCashbackPageVisited;

      dispatch({
        type: 'UPDATEISCASHBACKPAGEVISITED',
        payload: {
          user
        }
      });
    }).catch((error) => {
      toast.error(`${error.response.data.message}`);
    });
  };

  const cashbackFileUpload = async (name: string, fileUrl: string): Promise<void> => {
    await apiService.cashbackFileUpload({ name, fileUrl }).then(() => {
      dispatch({
        type: 'CASHBACKFILEUPLOAD',
        payload: {
          name,
          fileUrl,
        }
      });
    }).catch((error) => {
      toast.error(`${error.response.data.message}`);
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        updatePartner,
        getDefaultSlug,
        getLandingPageLayout,
        updatePartnerLogo,
        updateIsPartnerLogoLoaded,
        getAwsTokens,
        fetchBankDetails,
        updateBankDetails,
        updateProfilePic,
        updateLanguage,
        updatePartnerCardUrl,
        updateIsCashbackPageVisited,
        cashbackFileUpload,
        notifyCashbackRedeemSuccess
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
