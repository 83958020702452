import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Typography,
  Popover,
  Avatar
} from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Scrollbar from '../Scrollbar';
import { experimentalStyled, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LANGUAGE, Routes } from 'src/constants';
import { setLanguage } from 'src/api';
import PrimaryButton from '../widgets/buttons/PrimaryButton';
import useAuth from 'src/hooks/useAuth';
import { cognitoConfig, STRAPI_URL } from 'src/config';
import toast from 'react-hot-toast';
import { handleLogin, handleLogout } from 'src/utils/common';
import useStrapiCms from 'src/hooks/useStrapiCms';

const IcebackIcon = '/static/icons/iceback.png';
const LoginIcon = '/static/icons/login_icon.svg';
const LogoutIcon = '/static/icons/logout_icon.svg';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const languageOptions = {
  en: {
    label: 'EN'
  },
  de: {
    label: 'DE'
  }
};

const StyledDivider = experimentalStyled(Divider)(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.primary.contrastText,
      marginLeft: '40px',
      marginRight: '32px'
    }
  )
);

const LogoWrapper = experimentalStyled('img')(
  {
    maxWidth: '100%',
    display: 'block',
    margin: 'auto',
    width: '100%'
  }
);

const GiftIconWrapper = experimentalStyled('img')(
  {
    maxWidth: '100%',
    display: 'block',
    margin: 'auto',
    width: '100%',
    padding: '0 17px'
  }
);

const LoginIconWrapper = experimentalStyled('img')(
  {
    display: 'inline-block',
    float: 'left',
    marginTop: '5px',
    height: '15px'
  }
);

const TextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.contrastText,
      fontSize: '14px',
      padding: '0 40px',
      letterSpacing: '0.32px'
    }
  )
);

const LoginBlockContainer = experimentalStyled(Box)(
  {
    bottom: '10px',
    width: '100%'
  }
);

const LoginTextWrapper = experimentalStyled(Box)(
  {
    padding: '5px',
    cursor: 'pointer',
    marginLeft: '40px'
  }
);

const ButtonWrapper = experimentalStyled('div')(
  {
    width: '-webkit-fill-available',
    margin: '10px 30px 30px 30px'
  }
);

const LanguageTextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.contrastText,
      fontSize: '15px',
      letterSpacing: '1.78px',
      display: 'inline-block',
      verticalAlign: 'super'
    }
  )
);

const LanguageContainer = experimentalStyled('div')(
  {
    float: 'right',
    display: 'inline-block',
    marginRight: '20px',
    marginTop: '3px'
  }
);

const LanguagePopoverText = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      fontSize: '15px',
      letterSpacing: '1.78px',
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      padding: '5px',
      color: theme.palette.primary.main
    }
  )
);

const LoginTextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      fontSize: '15px',
      letterSpacing: '1.25px',
      color: theme.palette.primary.contrastText,
      display: 'inline-block',
      marginLeft: '5px',
      marginTop: '2px',
      float: 'left'
    }
  )
);

const ExpandMoreIconWrapper = experimentalStyled(ExpandMoreIcon)(
  ({ theme }) => (
    {
      color: theme.palette.primary.contrastText,
      fontSize: '21px',
      marginLeft: '-5px',
      display: 'inline-block'
    }
  )
);

const KeyboardArrowRightIconWrapper = experimentalStyled(KeyboardArrowRightIcon)(
  ({ theme }) => (
    {
      color: theme.palette.primary.contrastText,
      fontSize: '21px',
      marginLeft: '-5px',
      display: 'inline-block'
    }
  )
);

const UserNavSection = experimentalStyled(Box)(
  {
    bottom: '10px',
    marginTop: 'auto'
  }
);

const BoxContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100vh'
  }
);

const OnboardSideBar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { partnerLogo, isPartnerLogoLoaded, user, defaultSlug, updateLanguage } = useAuth();
  const { slug } = useParams();
  const navigate = useNavigate();
  const { landingPageContent, getLandingPageDetails } = useStrapiCms();
  const Language = (i18n.languages[0] === LANGUAGE.ENGLISH || i18n.languages[0] === LANGUAGE.GERMAN) ? i18n.languages[0] : LANGUAGE.ENGLISH;
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    i18n.changeLanguage(user?.language);
    setLanguage(user?.language);
  }, []);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleChangeLanguage = async () => {
    if (Language === LANGUAGE.ENGLISH) {
      i18n.changeLanguage(LANGUAGE.GERMAN);
      setLanguage(LANGUAGE.GERMAN);
      await getLandingPageDetails(slug || localStorage.getItem('slug'), LANGUAGE.GERMAN);
    } else {
      i18n.changeLanguage(LANGUAGE.ENGLISH);
      setLanguage(LANGUAGE.ENGLISH);
      await getLandingPageDetails(slug || localStorage.getItem('slug'), LANGUAGE.ENGLISH);
    }
    setOpen(false);
    if (user) {
      updateLanguage(i18n.language);
    }
  };

  const logout = async (): Promise<void> => {
    try {
      handleLogout(cognitoConfig);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const selectedOption = languageOptions[Language];
  const popoverOption = Language === LANGUAGE.ENGLISH ? languageOptions[LANGUAGE.GERMAN] : languageOptions[LANGUAGE.ENGLISH];

  const content = (
    <Box
      sx={{
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <BoxContainer>
          <Box>
            {
              location.pathname === Routes.REGISTER ? (
                <LogoWrapper
                  alt="Partner Logo"
                  src={user?.partner?.settings?.logo}
                />
              ) : (
                isPartnerLogoLoaded && (
                  <Box sx={{ mb: 4 }}>
                    {
                      partnerLogo ? (
                        <LogoWrapper
                          alt="Partner Logo"
                          src={partnerLogo}
                        />
                      ) : (
                        <LogoWrapper
                          alt="Partner Logo"
                          src={IcebackIcon}
                        />
                      )
                    }
                  </Box>
                )
              )
            }
          </Box>
          <Box>
            <Box sx={{ mb: 4 }}>
              <GiftIconWrapper
                alt="Gift Voucher"
                src={`${STRAPI_URL}${landingPageContent?.CobranderMessage?.image}`}
              />
            </Box>
          </Box>
          <Box>
            <Box sx={{ mb: 2 }}>
              <TextTypography>
                {landingPageContent?.CobranderMessage?.message}
              </TextTypography>
            </Box>
          </Box>
          <Box>
            <ButtonWrapper>
              {
                location.pathname === Routes.REGISTER ? (
                  <PrimaryButton
                    letterSpacing="1.25px"
                    fontSize="14px"
                    onClick={() => navigate('/onboard')}
                  >
                    {t('landing.order_now')}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    letterSpacing="1.25px"
                    fontSize="14px"
                    onClick={() => navigate(`/${slug || defaultSlug}/signup`)}
                  >
                    {t('landing.sign_up')}
                  </PrimaryButton>
                )
              }
            </ButtonWrapper>
          </Box>
          <UserNavSection>
            <LoginBlockContainer>
              {
                location.pathname === Routes.REGISTER && (
                  <Box sx={{
                    marginLeft: '40px'
                  }}
                  >
                    <Avatar
                      src={user?.profilePicUrl}
                      sx={{
                        cursor: 'pointer',
                        height: 48,
                        width: 48,
                        margin: '5px'
                      }}
                    />
                    <Box sx={{ padding: '5px' }}>
                      <Typography
                        color={theme.palette.primary.contrastText}
                        variant="body1"
                      >
                        {user.firstName}
                      </Typography>
                    </Box>
                  </Box>
                )
              }
              <StyledDivider />
              <LoginTextWrapper>
                {
                  location.pathname === Routes.REGISTER ? (
                    <Box onClick={logout}>
                      <LoginIconWrapper
                        alt="Logout"
                        src={LogoutIcon}
                      />
                      <LoginTextTypography>
                        Logout
                      </LoginTextTypography>
                    </Box>
                  ) : (
                    <Box onClick={() => handleLogin(cognitoConfig)}>
                      <LoginIconWrapper
                        alt="Login"
                        src={LoginIcon}
                      />
                      <LoginTextTypography>
                        Login
                      </LoginTextTypography>
                    </Box>
                  )
                }
                <LanguageContainer>
                  <LanguageTextTypography
                    onClick={handleOpen}
                    ref={anchorRef}
                  >
                    {selectedOption.label}
                  </LanguageTextTypography>
                  {
                    open ? (
                      <ExpandMoreIconWrapper
                        onClick={handleOpen}
                      />
                    ) : (
                      <KeyboardArrowRightIconWrapper
                        onClick={handleOpen}
                      />
                    )
                  }
                </LanguageContainer>
              </LoginTextWrapper>
            </LoginBlockContainer>
            <Popover
              anchorEl={anchorRef.current}
              anchorOrigin={{
                horizontal: 'center',
                vertical: 'top'
              }}
              transformOrigin={{
                horizontal: 'center',
                vertical: 'bottom'
              }}
              keepMounted
              onClose={handleClose}
              open={open}
              PaperProps={{
                sx: { width: 55 }
              }}
            >
              <LanguagePopoverText
                onClick={handleChangeLanguage}
              >
                {popoverOption.label}
              </LanguagePopoverText>
            </Popover>
          </UserNavSection>
        </BoxContainer>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'var(--primary-color)',
              borderRight: 'none',
              height: '100%',
              width: 280
            }
          }}
          variant="permanent"
        >
          {content}
        </Drawer>
        )
      </>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.main,
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

OnboardSideBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default OnboardSideBar;
