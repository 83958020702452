import {
  Card,
  CardContent,
  Grid,
  Typography,
  experimentalStyled,
  CardActions
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import useStrapiCms from 'src/hooks/useStrapiCms';

const HeadingTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      marginTop: '27px',
      marginLeft: '10%',
      lineSpacing: '0.56px',
      '@media (max-width: 1025px)': {
        marginLeft: '2%'
      },
      '@media (max-width: 530px)': {
        marginTop: '10px',
        marginLeft: '5%'
      }
    }
  )
);

const DetailsTextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.secondary.contrastText,
      lineSpacing: '1.6px',
      margin: '10px 20px 20px 0',
      fontSize: '15px',
      textTransform: 'uppercase',
      '@media (max-width: 530px)': {
        marginTop: 0
      }
    }
  )
);

const CardActionsWrapper = experimentalStyled(CardActions)(
  {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end'
  }
);

const GridContainer = experimentalStyled(Grid)(
  {
    '@media (max-width:530px) and (min-width: 300px)': {
      paddingLeft: '0px',
      paddingRight: '0px',
      '& .MuiContainer-root .MuiContainer-maxWidthXl': {
        paddingRight: '0px'
      },
      '@media (max-width: 530px)': {
        '& .MuiPaper-root': {
          borderRadius: '0px'
        },
        marginBottom: '24px'
      }
    },
  }
);

const RecommendBlock = () => {
  const { t } = useTranslation();
  const { landingPageContent } = useStrapiCms();

  return (
    <GridContainer
      item
      md={12}
      xs={12}
    >
      <Card>
        <CardContent>
          <div>
            <HeadingTypography
              variant="h6"
            >
              {landingPageContent?.Recommendation.primaryText}
            </HeadingTypography>
          </div>
          <HeadingTypography
            variant="h6"
          >
            {landingPageContent?.Recommendation?.secondaryText}
          </HeadingTypography>
        </CardContent>
        <CardActionsWrapper>
          <RouterLink to="/dashboard/recommend">
            <DetailsTextTypography variant="subtitle1">
              {t('landing.together_we_change')}
            </DetailsTextTypography>
          </RouterLink>
        </CardActionsWrapper>
      </Card>
    </GridContainer>
  );
};

export default RecommendBlock;
