import {
  Box,
  Grid,
  experimentalStyled,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { STRAPI_URL } from 'src/config';

const GridContainer = experimentalStyled(Grid)(
  {
    marginTop: '120px',
    '@media (max-width:530px) and (min-width: 300px)': {
      paddingLeft: '0px',
      paddingRight: '0px',
      '& .MuiContainer-root .MuiContainer-maxWidthXl': {
        paddingRight: '0px'
      },
      '@media (max-width: 530px)': {
        '& .MuiPaper-root': {
          borderRadius: '0px'
        }
      }
    },
    '@media (max-width: 1024px)': {
      marginTop: 0,
    }
  }
);

const BoxContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    alignItems: 'center',
    padding: '40px',
    position: 'relative',
    justifyContent: 'center',
    '@media (max-width: 1024px)': {
      display: 'block',
      padding: '20px',
      textAlign: 'center'
    }
  }
);

const TextContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingLeft: '30px',
    '@media (max-width: 1024px)': {
      paddingLeft: 0
    }
  }
);

const ExchangeTypography = experimentalStyled(Typography)(
  {
    color: 'var(--secondary-color)',
    marginBottom: '20px'
  }
);

const TextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      width: '60%',
      marginBottom: '20px',
      '@media (max-width: 1550px)': {
        width: '80%',
      },
      '@media (max-width: 1200px)': {
        width: '100%',
      }
    }
  )
);

const DescriptionTextTypography = experimentalStyled(Typography)(
  {
    color: '#333333',
    fontSize: '16px',
    width: '60%',
    '@media (max-width: 1550px)': {
      width: '80%',
    },
    '@media (max-width: 1200px)': {
      width: '100%',
    }
  }
);

const ImageContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    flex: 1,
    '@media (max-width: 1024px)': {
      display: 'block',
      textAlign: 'center',
      marginTop: '25px'
    }
  }
);

const SideTextContainer = experimentalStyled(Box)(
  {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    right: '5%',
    alignItems: 'center',
    top: '5%'
  }
);

const DotWrapper = experimentalStyled(Box)(
  ({ theme }) => (
    {
      height: '18px',
      width: '18px',
      borderRadius: '10px',
      background: theme.palette.primary.main,
      marginBottom: '10px'
    }
  )
);

const VerticalAlignText = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      writingMode: 'vertical-rl',
      fontSize: '14px',
      color: theme.palette.primary.main
    }
  )
);

const ImageWrapper = experimentalStyled('img')(
  {
    width: '85%',
    '@media (max-width: 1024px)': {
      width: '90%'
    },
    '@media (max-width: 600px)': {
      width: '100%'
    }
  }
);

interface AlphaLandingBrandProps {
  branding: {
    title: string,
    heading: string,
    subHeading: string,
    content: string,
    brandImage: string
  };
}

const AlphaLandingBrands = (props: AlphaLandingBrandProps) => {
  const { branding } = props;

  return (
    <GridContainer
      item
      md={12}
      xs={12}
    >
      <BoxContainer>
        {
          useMediaQuery('(min-width:1025px)') && (
            <SideTextContainer>
              <DotWrapper />
              <VerticalAlignText>
                {branding?.title}
              </VerticalAlignText>
            </SideTextContainer>
          )
        }
        <TextContainer>
          <ExchangeTypography variant="h1">
            {branding?.heading}
          </ExchangeTypography>
          <TextTypography variant="h1">
            {branding?.subHeading}
          </TextTypography>
          <DescriptionTextTypography>
            {branding?.content}
          </DescriptionTextTypography>
        </TextContainer>
        <ImageContainer>
          <ImageWrapper
            src={`${STRAPI_URL}${branding?.brandImage}`}
            alt="Brands"
          />
        </ImageContainer>
      </BoxContainer>
    </GridContainer>
  );
};

export default AlphaLandingBrands;
