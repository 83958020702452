import { experimentalStyled } from '@material-ui/core';
import type { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { apiService } from 'src/api';

const Container = experimentalStyled('div')(
  {
    minHeight: '100vh',
    background: 'white'
  }
);

const CircularProgressWrapper = experimentalStyled(CircularProgress)(
  {
    position: 'absolute',
    height: '60px !important',
    width: '60px !important',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  }
);

const Confirmation: FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const confirmationCode = urlParams.get('confirmationCode');
  const username = urlParams.get('username');
  const navigate = useNavigate();

  const accountConfirmation = async () => {
    await apiService.confirmAccount({ username, confirmationCode }).then(async (data: any) => {
      toast.success(data?.data?.message);
      navigate('/');
    }).catch((err: any) => {
      toast.error(err?.response?.data?.message);
      navigate('/');
    });
  };

  useEffect(() => {
    accountConfirmation();
  }, []);

  return (
    <Container>
      <CircularProgressWrapper />
    </Container>
  );
};

export default Confirmation;
