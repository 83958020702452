import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  experimentalStyled,
  useTheme
} from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';

interface QuestionsAnswersBlockProps {
  question: string;
  answer: string;
  index: number;
}

const AccordionWrapper = experimentalStyled('div')(
  ({ theme }) => (
    {
      '& .MuiAccordion-rounded': {
        boxShadow: 'none',
        borderRadius: '0px !important',
        paddingLeft: '0px'
      },
      '& .MuiAccordion-root .MuiPaper-root .MuiPaper-elevation': {
        boxShadow: 'none',
        paddingLeft: '0px'
      },
      '& .MuiAccordionSummary-root': {
        paddingLeft: '0px',
        borderRadius: '0px'
      },
      '& .MuiTypography-root': {
        color: theme.palette.primary.main,
        letterSpacing: '0.36px'
      },
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      boxShadow: 'none',
      marginRight: '25px'
    }
  )
);

const QuestionsAnswersBlock = (props: QuestionsAnswersBlockProps) => {
  const { question, answer, index } = props;
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string | false>(false);
  // const { t } = useTranslation();

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <AccordionWrapper>
      <Accordion
        sx={{ borderTop: index === 0 ? `2px solid ${theme.palette.primary.main}` : 'none' }}
        expanded={expanded === 'panel'}
        onChange={handleChange('panel')}
      >
        <AccordionSummary
          expandIcon={expanded === 'panel' ? <ExpandMoreIcon sx={{ color: theme.palette.primary.main }} /> : <KeyboardArrowRightIcon sx={{ color: theme.palette.primary.main }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </AccordionWrapper>
  );
};

export default QuestionsAnswersBlock;
