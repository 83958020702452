import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  experimentalStyled,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import VerticalWizardStepper from './VerticalWizardStepper';
import StepperIcons from './StepperIcons';

const GridContainer = experimentalStyled(Grid)(
  {
    marginTop: '120px',
    '@media (max-width:530px) and (min-width: 300px)': {
      paddingLeft: '0px',
      paddingRight: '0px',
      '& .MuiContainer-root .MuiContainer-maxWidthXl': {
        paddingRight: '0px'
      },
      '@media (max-width: 530px)': {
        '& .MuiPaper-root': {
          borderRadius: '0px'
        }
      }
    },
    '@media (max-width: 1024px)': {
      marginTop: 0
    }
  }
);

const BoxContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    padding: '40px',
    position: 'relative',
    '@media (max-width: 1024px)': {
      display: 'block',
      padding: '20px'
    }
  }
);

const TextContainer = experimentalStyled(Box)(
  {
    padding: '30px',
    flex: 1,
    '@media (max-width: 1024px)': {
      display: 'block',
      padding: '20px',
      textAlign: 'center'
    }
  }
);

const TextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main
    }
  )
);

const SideTextContainer = experimentalStyled(Box)(
  {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    right: '5%',
    alignItems: 'center'
  }
);

const DotWrapper = experimentalStyled(Box)(
  ({ theme }) => (
    {
      height: '18px',
      width: '18px',
      borderRadius: '10px',
      background: theme.palette.primary.main,
      marginBottom: '10px'
    }
  )
);

const VerticalAlignText = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      writingMode: 'vertical-rl',
      fontSize: '14px',
      color: theme.palette.primary.main
    }
  )
);

const DescriptionTextTypography = experimentalStyled(Typography)(
  {
    fontSize: '16px',
    width: '80%',
    marginTop: '20px',
    '@media (max-width: 1024px)': {
      width: '100%'
    }
  }
);

const WizardWrapper = experimentalStyled(Box)(
  {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingTop: '100px',
    paddingBottom: '100px',
    '@media (max-width: 1024px)': {
      width: '100%',
      justifyContent: 'center'
    }
  }
);

interface JourneyProcess {
  id?: number,
  step: string,
  image: string
}

interface AlphaLandingJourneyProps {
  journey: {
    title: string,
    heading: string,
    description1: string,
    description2: string,
    journeyProcess: JourneyProcess[]
  }
}

const AlphaLandingJourney = (props: AlphaLandingJourneyProps) => {
  const { journey } = props;
  const [parentNode, setParentNode] = useState<any>();
  const [isVisibleInPort, setIsVisibleInPort] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const parentRef = useCallback((node) => {
    if (node !== null) {
      setParentNode(node);
    }
  }, []);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      if (parentNode) {
        const rect = parentNode.getBoundingClientRect();
        const isInViewport = rect.top >= 0
          && rect.left >= 0
          && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
          && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
        setIsVisibleInPort(isInViewport);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return (
    <GridContainer
      {...props}
      item
      md={12}
      xs={12}
    >
      <BoxContainer>
        {
          useMediaQuery('(min-width:1025px)') && (
            <SideTextContainer>
              <DotWrapper />
              <VerticalAlignText>
                {journey?.title}
              </VerticalAlignText>
            </SideTextContainer>
          )
        }
        <TextContainer>
          <TextTypography
            variant="h1"
            sx={{ textTransform: 'capitalize' }}
          >
            {journey?.heading}
          </TextTypography>
          <DescriptionTextTypography>
            {journey?.description1}
          </DescriptionTextTypography>
          <DescriptionTextTypography>
            {journey?.description2}
          </DescriptionTextTypography>
        </TextContainer>
        <WizardWrapper ref={parentRef}>
          <VerticalWizardStepper
            isVisibleInPort={isVisibleInPort}
            journeyProcess={journey?.journeyProcess}
          />
          <StepperIcons
            isVisibleInPort={isVisibleInPort}
            journeyProcess={journey?.journeyProcess}
          />
        </WizardWrapper>
      </BoxContainer>
    </GridContainer>
  );
};

export default AlphaLandingJourney;
