import type { FC } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { AppBarProps } from '@material-ui/core';
import HamburgerMenu from '../../icons/HamburgerMenu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useAuth from 'src/hooks/useAuth';

const IcebackIcon = '/static/icons/iceback.png';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.background.paper,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      zIndex: theme.zIndex.drawer + 100,
      width: useMediaQuery('(max-width:1279px)') ? '100%' : 'calc(99% - 360px)',
      borderRadius: useMediaQuery('(max-width:1279px)') ? '' : '0px 0px 20px 20px',
      marginRight: useMediaQuery('(max-width:1279px)') ? '' : '60px',
    }
  )
);

const LogoWrapper = experimentalStyled('img')(
  {
    display: 'block',
    margin: 'auto',
    width: '200px'
  }
);

const MenuIconWrapper = experimentalStyled(HamburgerMenu)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      width: '30px',
      height: '30px'
    }
  )
);

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { user, partnerLogo, isAuthenticated, isPartnerLogoLoaded } = useAuth();

  return (
    <DashboardNavbarRoot
      {...other}
      sx={{
        display: {
          lg: 'none'
        }
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
        >
          <MenuIconWrapper fontSize="small" />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        {
          isAuthenticated && (
            user?.partner?.settings?.logo ? (
              <LogoWrapper
                alt="Partner Logo"
                src={user?.partner?.settings?.logo}
              />
            ) : (
              <LogoWrapper
                alt="Partner Logo"
                src={IcebackIcon}
              />
            )
          )
        }
        {
          (!isAuthenticated && isPartnerLogoLoaded) && (
            partnerLogo ? (
              <LogoWrapper
                alt="Partner Logo"
                src={partnerLogo}
              />
            ) : (
              <LogoWrapper
                alt="Partner Logo"
                src={IcebackIcon}
              />
            )
          )
        }
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
