import type { FC } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  experimentalStyled,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'src/components/widgets/buttons/PrimaryButton';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import useStrapiCms from 'src/hooks/useStrapiCms';

const HeadingTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      marginTop: '27px',
      marginLeft: '10%',
      '@media (max-width: 1025px)': {
        marginLeft: '5%'
      },
      '@media (max-width: 530px)': {
        marginTop: '15px',
        marginLeft: '5%'
      }
    }
  )
);

const ButtonWrapper = experimentalStyled('div')(
  {
    width: '-webkit-fill-available',
    margin: '58px 10% 80px 10%',
    '@media (max-width: 1025px)': {
      marginLeft: '5%',
      marginTop: '45px',
      marginBottom: '15px'
    },
    '@media (max-width: 530px)': {
      '& .MuiPaper-root': {
        margin: '58px 5% 60px 5%'
      },
      margin: '20px 5% 10px 5%'
    }
  }
);

const GridContainer = experimentalStyled(Grid)(
  {
    '@media (max-width:530px) and (min-width: 300px)': {
      paddingLeft: '0px',
      paddingRight: '0px',
      '& .MuiContainer-root .MuiContainer-maxWidthXl': {
        paddingRight: '0px'
      },
      '@media (max-width: 530px)': {
        '& .MuiPaper-root': {
          borderRadius: '0px'
        }
      }
    },
  }
);

const CardBlock: FC = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { slug } = useParams();
  const { isAuthenticated, defaultSlug } = useAuth();
  const { landingPageContent } = useStrapiCms();

  return (
    <GridContainer
      {...props}
      item
      md={12}
      xs={12}
    >
      <Card>
        <CardContent>
          <div>
            <HeadingTypography variant="h5">
              {landingPageContent?.CobranderCard?.message}
            </HeadingTypography>
          </div>
          <ButtonWrapper>
            <PrimaryButton
              letterSpacing="1.25px"
              onClick={() => {
                if (isAuthenticated) {
                  navigate('/onboard');
                } else {
                  navigate(`/${slug || defaultSlug}/signup`);
                }
              }}
            >
              {`${t('landing.my_own_iceback_card_prefix')} ${slug || defaultSlug} ${t('landing.my_own_iceback_card_postfix')}`}
            </PrimaryButton>
          </ButtonWrapper>
        </CardContent>
      </Card>
    </GridContainer>
  );
};

export default CardBlock;
