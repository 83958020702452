import { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import WebSocketHandler from './WebSocketHandler';
import { useIdleTimer } from 'react-idle-timer';
import { cognitoConfig, IdleTimeOut } from 'src/config';
import toast from 'react-hot-toast';
import DonateModal from '../RewardsCashbackModal';
import { apiService } from 'src/api';
import { InvitationStatus } from 'src/types/common';
import useAuth from 'src/hooks/useAuth';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    }
  )
);

const DashboardLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => (
    {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      paddingTop: '24px',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '280px'
      }
    }
  )
);

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
  '@media (max-width:1279px)': {
    paddingTop: '65px'
  }
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  const [open, setOpenModal] = useState(true);
  const [invitation, setInvitation] = useState(undefined);
  const { layout } = useAuth();

  const handleClose = () => {
    setOpenModal(false);
  };

  const checkNewRewards = async () => {
    const invitations = await apiService.fetchNewRewards();
    if (invitations) {
      const newInvitations = invitations?.data?.data?.filter((item: any) => item?.rewarded && item?.status === InvitationStatus.ACCEPTED && !item?.showed_to_user);
      if (newInvitations && newInvitations.length) {
        setInvitation(newInvitations[0]);
      }
    }
  };

  useEffect(() => {
    checkNewRewards();
  }, [layout]);

  const handleSessionExpiry = async (): Promise<void> => {
    try {
      sessionStorage.setItem('sessionExpired', 'true');
      const { domain, clientId, responseType, scope, redirectUri, logoutUri } = cognitoConfig;
      window.location.href = `${domain}/logout?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectUri}&logout_uri=${logoutUri}`;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useIdleTimer({
    timeout: IdleTimeOut,
    onIdle: handleSessionExpiry
  });

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
      />
      <DashboardSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
      <WebSocketHandler />
      {
        invitation && open && (
          <DonateModal
            openModal={open}
            handleClose={handleClose}
            invitation={invitation}
          />
        )
      }
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
