import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const SettingsIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.678"
    height="23.314"
    viewBox="0 0 22.678 23.314"
    fill="currentColor"
  >
    <path
      className="a"
      d="M23.409,15.8a9.085,9.085,0,0,0,.082-1.142,9.085,9.085,0,0,0-.082-1.142l2.46-1.923a.588.588,0,0,0,.14-.746L23.677,6.812a.586.586,0,0,0-.711-.256l-2.9,1.166a8.517,8.517,0,0,0-1.97-1.142L17.65,3.49A.568.568,0,0,0,17.079,3H12.416a.568.568,0,0,0-.571.49L11.4,6.579a8.956,8.956,0,0,0-1.97,1.142l-2.9-1.166a.569.569,0,0,0-.711.256L3.487,10.845a.575.575,0,0,0,.14.746l2.46,1.923A9.245,9.245,0,0,0,6,14.657,9.245,9.245,0,0,0,6.086,15.8l-2.46,1.923a.588.588,0,0,0-.14.746L5.818,22.5a.586.586,0,0,0,.711.256l2.9-1.166a8.517,8.517,0,0,0,1.97,1.142l.443,3.089a.568.568,0,0,0,.571.49h4.663a.568.568,0,0,0,.571-.49l.443-3.089a8.956,8.956,0,0,0,1.97-1.142l2.9,1.166a.569.569,0,0,0,.711-.256l2.331-4.033a.588.588,0,0,0-.14-.746L23.409,15.8Zm-8.661,2.938a4.08,4.08,0,1,1,4.08-4.08A4.084,4.084,0,0,1,14.748,18.737Z"
      transform="translate(-3.406 -3)"
    />
  </svg>, 'SettingsIcon'
);

export default SettingsIcon;
