import type { FC } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  experimentalStyled
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import WizardStepper from 'src/components/WizardStepper';
import QuestionsAnswersBlock from './QuestionsAnswersBlock';
import { Routes } from 'src/constants';
import useStrapiCms from 'src/hooks/useStrapiCms';
import { STRAPI_URL } from 'src/config';

const TitleTextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      marginRight: '10px',
      fontSize: '20px',
      fontWeight: 'bold',
      '@media (max-width:600px)': {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
      }
    }
  )
);

const SubTextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      marginRight: '10px',
      fontSize: '15px',
      fontWeight: 'bold',
      '@media (max-width:600px)': {
        color: theme.palette.secondary.contrastText,
        fontSize: '12px',
        letterSpacing: '0.6px'
      }
    }
  )
);

const BenefitTextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      marginLeft: '40px',
      fontWeight: 'bold',
      fontSize: '18px',
      verticalAlign: '-webkit-baseline-middle',
      letterSpacing: '1.78px',
      '@media (max-width:600px)': {
        fontSize: '15px'
      }
    }
  )
);

const CheckIconWrapper = experimentalStyled(CheckIcon)(
  ({ theme }) => (
    {
      color: theme.palette.secondary.main,
      display: 'inline-block',
      fontSize: '30px',
      float: 'left'
    }
  )
);

const LogoWrapper = experimentalStyled('img')(
  {
    width: '380px',
    marginTop: '20px',
    display: 'inline-block',
    float: 'left',
    '@media (max-width:800px)': {
      width: '380px',
      display: 'block',
      margin: 'auto',
      float: 'none'
    },
    '@media (max-width:600px)': {
      width: '300px'
    }
  }
);

const CardContentWrapper = experimentalStyled(CardContent)(
  {
    paddingLeft: '43px',
    paddingTop: '24px',
    '@media (max-width:765px)': {
      paddingLeft: '10%'
    }
  }
);

const AdvantageTextBlockContainer = experimentalStyled('div')(
  {
    display: 'inline-block',
    float: 'left',
    marginTop: '20px',
    marginLeft: '20px',
    '@media (max-width:765px)': {
      marginTop: '20px',
      marginLeft: '-15px'
    }
  }
);

const AdvantageTextInnerContainer = experimentalStyled('div')(
  {
    marginTop: '21px'
  }
);

const DividerWrapper = experimentalStyled(Divider)(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      margin: '20px 25px 20px 0px'
    }
  )
);

const ImageBlockContainer = experimentalStyled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
    marginTop: '30px',
    '@media (max-width:800px)': {
      display: 'inline-block',
    }
  }
);

const DeactivatedText = experimentalStyled(Typography)(
  {
    color: '#EF6262',
    fontSize: '23px',
    fontWeight: 'bold',
    '@media (max-width:600px)': {
      textAlign: 'center'
    }
  }
);

const DeactivedBlock = experimentalStyled('div')(
  {
    border: '4px solid #EF6262',
    borderRadius: '20px',
    padding: '20px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
    background: '#F2F4F4'
  }
);

const WelcomeText = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: '23px',
      '@media (max-width:600px)': {
        textAlign: 'center'
      }
    }
  )
);

const WelcomeBlock = experimentalStyled('div')(
  ({ theme }) => (
    {
      border: `4px solid ${theme.palette.primary.main}`,
      borderRadius: '20px',
      padding: '20px',
      marginBottom: '24px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: '#F2F4F4'
    }
  )
);

const AccessDashboardText = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      fontSize: '17px',
      marginTop: '20px',
      width: '55%',
      textAlign: 'center',
      '@media (max-width:600px)': {
        width: 'auto'
      }
    }
  )
);

const GridContainer = experimentalStyled(Grid)(
  {
    marginBottom: '40px',
    '@media (max-width:530px) and (min-width: 300px)': {
      paddingLeft: '0px',
      paddingRight: '0px',
      '& .MuiContainer-root .MuiContainer-maxWidthXl': {
        paddingRight: '0px'
      },
      '@media (max-width: 530px)': {
        '& .MuiPaper-root': {
          borderRadius: '0px'
        }
      }
    },
  }
);

const MainLanding: FC = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { landingPageContent } = useStrapiCms();

  return (
    <GridContainer
      {...props}
      item
      md={12}
      xs={12}
    >
      <Card>
        <CardContentWrapper>
          {
            location.pathname === Routes.DEACTIVATED && (
              <DeactivedBlock>
                <DeactivatedText>
                  {t('landing.your_account_has_been_deactivated')}
                </DeactivatedText>
              </DeactivedBlock>
            )
          }
          {
            location.pathname === Routes.REGISTER && (
              <WelcomeBlock>
                <WelcomeText>
                  {t('landing.welcome_to_dashboard')}
                </WelcomeText>
                <AccessDashboardText>
                  {t('landing.you_can_access_all_dashboard')}
                </AccessDashboardText>
              </WelcomeBlock>
            )
          }
          <div>
            <TitleTextTypography>
              {landingPageContent?.Introduction?.primaryText}
            </TitleTextTypography>

            <SubTextTypography>
              {landingPageContent?.Introduction?.secondaryText}
            </SubTextTypography>
          </div>
          <ImageBlockContainer>
            <LogoWrapper
              alt="Partner Logo"
              src={`${STRAPI_URL}${landingPageContent?.cardUrl}`}
            />
            <AdvantageTextBlockContainer>
              {
                landingPageContent?.Advantages.map((item) => (
                  <AdvantageTextInnerContainer key={item.id}>
                    <CheckIconWrapper />
                    <BenefitTextTypography>
                      {item.advantage}
                    </BenefitTextTypography>
                  </AdvantageTextInnerContainer>
                ))
              }
            </AdvantageTextBlockContainer>
          </ImageBlockContainer>
          <div>
            <DividerWrapper />
            <WizardStepper />
            <DividerWrapper />
          </div>
          <div>
            <TitleTextTypography
              sx={{ marginBottom: '25px' }}
            >
              {t('landing.answers_to_ticket_counter')}
            </TitleTextTypography>
            {
              landingPageContent?.FAQ.map((item, index) => (
                <QuestionsAnswersBlock
                  key={`${item.question}${item.answer}${item.id}`}
                  index={index}
                  question={item.question}
                  answer={item.answer}
                />
              ))
            }
          </div>
        </CardContentWrapper>
      </Card>
    </GridContainer>
  );
};

export default MainLanding;
