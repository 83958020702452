import { experimentalStyled } from '@material-ui/core';
import type { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

const slug = localStorage.getItem('slug');

const Container = experimentalStyled('div')(
  {
    minHeight: '100vh',
    background: 'white'
  }
);

const CircularProgressWrapper = experimentalStyled(CircularProgress)(
  {
    position: 'absolute',
    height: '60px !important',
    width: '60px !important',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  }
);

const Logout: FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    if (slug) {
      await logout();
      navigate(`/${slug}`);
    } else {
      await logout();
      navigate('/');
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <Container>
      <CircularProgressWrapper />
    </Container>
  );
};

export default Logout;
