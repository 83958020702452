import type { FC } from 'react';
import { experimentalStyled } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const Container = experimentalStyled('div')(
  {
    minHeight: '100vh',
    background: 'white'
  }
);

const CircularProgressWrapper = experimentalStyled(CircularProgress)(
  {
    position: 'absolute',
    height: '60px !important',
    width: '60px !important',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    color: 'var(--primary-color)'
  }
);

const SlashScreen: FC = () => (
  <Container>
    <CircularProgressWrapper />
  </Container>
);

export default SlashScreen;
