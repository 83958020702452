import type { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface RoleBasedGuardProps {
  children: ReactNode;
  roles: string[];
}

const RoleBasedGuard = (props: RoleBasedGuardProps) => {
  const { children, roles } = props;
  const { user } = useAuth();

  if (!roles.includes(user.role)) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};

export default RoleBasedGuard;
