import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import OnboardDashboardLayout from './components/dashboard/landingDashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import Authenticate from './components/Authenticate';
import Logout from './components/Logout';
import RoleBasedGuard from './components/RoleBasedGuard';
import { ROLE } from './constants';
import RegisterGuard from './components/RegisterGuard';
import Confirmation from './components/Confirmation';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Transactions = Loadable(lazy(() => import('./pages/dashboard/Transactions')));
const CounosWallet = Loadable(lazy(() => import('./pages/dashboard/CounosWallet')));
const Cashback = Loadable(lazy(() => import('./pages/dashboard/Cashback')));
const Donations = Loadable(lazy(() => import('./pages/dashboard/Donations')));
const Rewards = Loadable(lazy(() => import('./pages/dashboard/Rewards')));
const CoBrander = Loadable(lazy(() => import('./pages/dashboard/CoBrander')));
const CashbackProcessor = Loadable(lazy(() => import('./pages/dashboard/CashbackProcessor')));
const Voucher = Loadable(lazy(() => import('./pages/dashboard/VoucherEditor')));
const CobranderAdmin = Loadable(lazy(() => import('./pages/dashboard/CobranderAdmin')));
const Vouchers = Loadable(lazy(() => import('./pages/dashboard/Vouchers')));
const Shops = Loadable(lazy(() => import('./pages/dashboard/Shops')));
const Home = Loadable(lazy(() => import('./pages/dashboard/Home')));
const Admin = Loadable(lazy(() => import('./pages/dashboard/Admin')));
const Recommend = Loadable(lazy(() => import('./pages/dashboard/Recommend')));
const WaitingPage = Loadable(lazy(() => import('./pages/dashboard/Waiting')));
const ShopsWelcome = Loadable(lazy(() => import('./pages/dashboard/ShopsWelcome')));

// Landing page
const LandingPage = Loadable(lazy(() => import('./pages/dashboard/Landing')));

// Onboard Page
const OnboardPage = Loadable(lazy(() => import('./pages/dashboard/Onboard')));

// Onboard Page Recommended way
const OnboardRecommendedwayPage = Loadable(lazy(() => import('./pages/dashboard/OnboardRecommendedway')));

// Other pages
// const Home = Loadable(lazy(() => import('./pages/Home')));

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login/:slug',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: '/:slug',
    element: (
      <GuestGuard>
        <OnboardDashboardLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: '/',
        element: <LandingPage />
      }
    ]
  },
  {
    path: '/',
    element: (
      <GuestGuard>
        <OnboardDashboardLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: '/',
        element: <LandingPage />
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'counos-wallet',
        element: <CounosWallet />
      },
      {
        path: 'recommend',
        element: <Recommend />
      },
      {
        path: 'cobrander',
        element: (
          <RoleBasedGuard roles={[ROLE.ADMIN]}>
            <CoBrander />
          </RoleBasedGuard>
        )
      },
      {
        path: 'cashbackprocessor',
        element: (
          <RoleBasedGuard roles={[ROLE.ADMIN]}>
            <CashbackProcessor />
          </RoleBasedGuard>
        )
      },
      {
        path: 'voucher-editor',
        element: (
          <RoleBasedGuard roles={[ROLE.ADMIN, ROLE.PARTNER, ROLE.PARTNER_OWNER]}>
            <Voucher />
          </RoleBasedGuard>
        )
      },
      {
        path: 'cobrander_admin',
        element: (
          <RoleBasedGuard roles={[ROLE.PARTNER_OWNER]}>
            <CobranderAdmin />
          </RoleBasedGuard>
        )
      },
      {
        path: 'vouchers',
        element: <Vouchers />
      },
      {
        path: 'shops',
        element: <Shops />
      },
      {
        path: 'shops-welcome',
        element: <ShopsWelcome />
      },
      {
        path: 'waiting',
        element: <WaitingPage />
      },
      {
        path: 'admin',
        element: (
          <RoleBasedGuard roles={[ROLE.ADMIN, ROLE.PARTNER, ROLE.PARTNER_OWNER]}>
            <Admin />
          </RoleBasedGuard>
        )
      },
      {
        path: 'cashback',
        element: <Cashback />
      },
      {
        path: 'donations',
        element: <Donations />
      },
      {
        path: 'rewards',
        element: <Rewards />
      }
    ]
  },
  {
    path: '*',
    children: [
      {
        path: '/:slug',
        element: (
          <GuestGuard>
            <OnboardDashboardLayout>
              <LandingPage />
            </OnboardDashboardLayout>
          </GuestGuard>
        )
      },
      {
        path: '/deactivated',
        element: (
          <GuestGuard>
            <OnboardDashboardLayout>
              <LandingPage />
            </OnboardDashboardLayout>
          </GuestGuard>
        )
      },
      {
        path: '/register',
        element: (
          <RegisterGuard>
            <OnboardDashboardLayout>
              <LandingPage />
            </OnboardDashboardLayout>
          </RegisterGuard>
        )
      },
      {
        path: '/signup',
        element: (
          <Register />
        )
      },
      {
        path: '/:slug/signup',
        element: (
          <Register />
        )
      },
      {
        path: '/:slug/signup/:referralCode',
        element: (
          <Register />
        )
      },
      {
        path: '/login/:slug',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '/login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '/onboard',
        element: (
          <OnboardPage />
        )
      },
      {
        path: '/onboard-recommendedway',
        element: (
          <OnboardRecommendedwayPage />
        )
      },
      {
        path: '/reset-password/:id',
        element: (
          <PasswordReset />
        )
      },
      {
        path: '/authenticate',
        element: (
          <Authenticate />
        )
      },
      {
        path: '/confirmation',
        element: (
          <Confirmation />
        )
      },
      {
        path: '/logout',
        element: (
          <Logout />
        )
      }
    ]
  }
];

export default routes;
