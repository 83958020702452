export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const LANGUAGE = {
  ENGLISH: 'en',
  GERMAN: 'de',
};

export const TOKENS = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
};

export const DATEFORMAT = 'DD.MM.YYYY';

export const ReferUrl = 'www.iceback.com/refer/maximilanmalonasterin';

export const SIDE_BAR_NAV_ITEMS = {
  COUPONS: { en: 'Coupons', de: 'Gutscheine' },
  SHOPS: 'Shops'
};

export const ROLE = {
  CUSTOMER: 'CUSTOMER',
  ADMIN: 'ADMIN',
  PARTNER: 'PARTNER',
  PARTNER_OWNER: 'PARTNER_OWNER'
};

export const STATUSCODE = {
  SUCCESS: '10000',
  FAILURE: '10001',
  RETRY: '10002',
  INVALID_ACCESS_TOKEN: '10003'
};

export const VOUCHERTYPE = {
  DIGITAL: 'digital', // digital
  COUPON: 'coupon', // coupon
};

export const QUANTITYTYPE = {
  LIMITED: 'limited',
  UNLIMITED: 'unlimited',
};

export const DISCOUNTTYPE = {
  PERCENTAGE: 'percentage',
  AMOUNT: 'amount',
};

export const ACCESS_ERRORS = {
  USERNOTREGISTERED: 'UserNotRegistered',
  USERDISABLED: 'UserIsDisabled'
};

export const CASHBACKDATA = {
  CREDIT: 'CREDIT',
  PURCHASES: 'PURCHASES',
  COUPONS: 'COUPONS',
  REDEMPTION: 'REDEMPTION'
};

export const CREDIT = 'credit';

export const Routes = {
  DEACTIVATED: '/deactivated',
  REGISTER: '/register'
};

export const WebSocketActions = {
  ACCOUNT_SYNC: 'ACCOUNT_SYNC',
  ACCOUNT_SYNC_FAILURE: 'ACCOUNT_SYNC_FAILURE',
  CASHBACK_REDEEM: 'CASHBACK_REDEEM',
  CASHBACK_REDEEM_FAILURE: 'CASHBACK_REDEEM_FAILURE',
  TRANSACTIONS_SYNC: 'TRANSACTIONS_SYNC',
  TRANSACTIONS_SYNC_FAILURE: 'TRANSACTIONS_SYNC_FAILURE',
  VOUCHER_PURCHASE: 'VOUCHER_PURCHASE',
  VOUCHER_PURCHASE_FAILURE: 'VOUCHER_PURCHASE_FAILURE'
};

export const RegExps = {
  ACCEPT_DECIMAL_NUMBER_REGX: /^[0-9]*[.]?[0-9]*$/,
  ACCEPT_NUMBER_REGX: /^[0-9]*\.?[0-9]*$/,
  ACCEPT_NUMBER_WITH_PLUS_REGX: /^[\d ()+]+$/
};

export const CSVFileType = 'vnd.ms-excel';

export const RegExToRemoveParanthesisAndSpaces = /[ )(]/g;

export const DISCOUNTREPORTSDOWNLOADPATHNAME = 'discountReports/download';

export const ExcelReqHeaders = {
  openXMLHeader: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  arrayBuffer: 'arraybuffer'
};

export const BankAccountStatus = {
  rejected: 'rejected',
  pending: 'pending'
};
