import { experimentalStyled } from '@material-ui/core';
import type { FC } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { ACCESS_ERRORS } from 'src/constants';
import toast from 'react-hot-toast';

const Container = experimentalStyled('div')(
  {
    minHeight: '100vh',
    background: 'white'
  }
);

const CircularProgressWrapper = experimentalStyled(CircularProgress)(
  {
    position: 'absolute',
    height: '60px !important',
    width: '60px !important',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  }
);

const slug = localStorage.getItem('slug');

const Authenticate: FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('code');
  const { getAwsTokens, fetchBankDetails, isAuthenticated, preSignupAtDashboard } = useAuth() as any;
  const navigate = useNavigate();

  const fetchTokens = async () => {
    await getAwsTokens(token, slug).then(async (data: any) => {
      if (data?.preSignupAtDashboard && data?.user?.isOnboardSessionReceivedAtBank === undefined) {
        navigate('/register');
      } else {
        await fetchBankDetails();
        navigate('/dashboard');
      }
    }).catch((err: any) => {
      if (err && err.response && err.response.data && (err.response.data.message === ACCESS_ERRORS.USERNOTREGISTERED)) {
        navigate('/register');
      } else if (err && err.response && err.response.data && err.response.data.message === ACCESS_ERRORS.USERDISABLED) {
        navigate('/deactivated');
      }

      if (err?.response?.data?.message !== ACCESS_ERRORS.USERNOTREGISTERED && err?.response?.data?.message !== ACCESS_ERRORS.USERDISABLED) {
        toast.error(err?.response?.data?.message);
        navigate('/');
      }
    });
  };

  useEffect(() => {
    if (isAuthenticated && !preSignupAtDashboard) {
      navigate('/dashboard');
    } else {
      fetchTokens();
    }
  }, []);

  return (
    <Container>
      <CircularProgressWrapper />
    </Container>
  );
};

export default Authenticate;
