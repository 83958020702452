import {
  Box,
  Grid,
  experimentalStyled,
  Typography,
  Button,
  useMediaQuery
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { Routes } from 'src/constants';
import { STRAPI_URL } from 'src/config';

const MouseLineIcon = '/static/icons/MouseLine.png';

const GridContainer = experimentalStyled(Grid)(
  {
    marginBottom: '40px',
    overflow: 'hidden',
    '@media (max-width:530px) and (min-width: 300px)': {
      paddingLeft: '0px',
      paddingRight: '0px',
      '& .MuiContainer-root .MuiContainer-maxWidthXl': {
        paddingRight: '0px'
      },
      '@media (max-width: 530px)': {
        '& .MuiPaper-root': {
          borderRadius: '0px'
        }
      }
    },
    '@media (max-width: 1024px)': {
      marginBottom: 0
    }
  }
);

const BannerContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: 'var(--primary-color)',
    height: '90vh',
    minHeight: '750px',
    position: 'relative',
    '@media (max-width: 1024px)': {
      display: 'block',
      height: 'auto'
    }
  }
);

const TextTypography = experimentalStyled(Typography)(
  {
    color: 'white',
    paddingBottom: '20px',
    zIndex: 10
  }
);

const TextContainer = experimentalStyled(Box)({
  paddingLeft: '50px',
  paddingRight: '20px',
  flex: 1,
  zIndex: 3,
  '@media (max-width: 1024px)': {
    marginTop: '150px',
    padding: 0,
    textAlign: 'center',
    marginBottom: '50px',
    position: 'relative '
  }
});

const MainImageWrapper = experimentalStyled('img')(
  {
    width: '500px',
    height: 'auto',
    zIndex: 5,
    opacity: 0.9,
    '@media (max-width: 600px)': {
      minWidth: '350px',
      width: '50%',
      opacity: 0.7
    },
    '@media (max-width: 420px)': {
      minWidth: '280px',
      opacity: 0.7
    }
  }
);

const SubImageWrapper = experimentalStyled('img')(
  {
    width: '430px',
    height: 'auto',
    transform: 'rotate(12deg)',
    zIndex: 4,
    opacity: 0.9,
    marginLeft: '16%',
    marginTop: '-115px',
    '@media (max-width: 600px)': {
      minWidth: '310px',
      width: '45%',
      opacity: 0.7,
      marginTop: '-80px'
    },
    '@media (max-width: 420px)': {
      minWidth: '250px',
      marginTop: '-65px'
    }
  }
);

const ImageContainer = experimentalStyled(Box)(
  {
    flex: 1,
    position: 'relative'
  }
);

const BoxContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '120px',
    '@media (max-width: 1024px)': {
      marginTop: 0,
      alignItems: 'center'
    }
  }
);

const CustomButton = experimentalStyled(Button)(() => (
  {
    backgroundColor: 'var(--secondary-color)',
    letterSpacing: '0.69px',
    width: 'auto',
    color: 'var(--primary-color)',
    fontSize: '16px',
    padding: '10px 40px',
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: 'var(--secondary-color)',
    }
  }
));

const LargeBubbleWrapper = experimentalStyled(Box)(
  {
    background: 'linear-gradient(220.23deg, #FFF5C8 -10.94%, #87BEFF 56.07%)',
    width: '200px',
    height: '200px',
    borderRadius: '100px',
    position: 'absolute',
    animation: 'largeCircle 50s infinite',
    animationDirection: 'alternate',
    zIndex: 1,
    '@keyframes largeCircle': {
      '0%': {
        left: 0,
        top: '65%'
      },
      '25%': {
        left: '70%',
        top: 0
      },
      '50%': {
        left: '50%',
        top: '55%'
      },
      '75%': {
        left: 0,
        top: '65%'
      },
      '100%': {
        left: '25%',
        top: 0
      }
    }
  }
);

const MediumBubbleWrapper = experimentalStyled(Box)(
  {
    background: 'linear-gradient(220.23deg, #FFF5C8 -10.94%, #87BEFF 56.07%)',
    width: '80px',
    height: '80px',
    borderRadius: '40px',
    position: 'absolute',
    animation: 'mediumCircle 50s infinite',
    zIndex: 1,
    animationDirection: 'alternate',
    '@keyframes mediumCircle': {
      '0%': {
        left: '80%',
        top: '20%'
      },
      '25%': {
        left: '50%',
        top: '20%'
      },
      '50%': {
        left: '55%',
        top: '0'
      },
      '75%': {
        left: '25%',
        top: '0'
      },
      '100%': {
        left: '55%',
        top: '50%'
      }
    }
  }
);

const SmallBubbleWrapper = experimentalStyled(Box)(
  {
    background: 'linear-gradient(220.23deg, #FFF5C8 -10.94%, #87BEFF 56.07%)',
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    position: 'absolute',
    animation: 'smallCircle 50s infinite',
    zIndex: 1,
    animationDirection: 'alternate',
    '@keyframes smallCircle': {
      '0%': {
        left: '80%',
        top: '80%'
      },
      '25%': {
        left: '20%',
        top: '60%'
      },
      '50%': {
        left: '85%',
        top: '0'
      },
      '75%': {
        left: '75%',
        top: '80%'
      },
      '100%': {
        left: '10%',
        top: '80%'
      }
    }
  }
);

const ScrollToBottomContainer = experimentalStyled(Box)(
  {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    bottom: '20px',
    textAlign: 'center',
    '@media (max-width: 1024px)': {
      flexDirection: 'column'
    }
  }
);

const ScrollDownTypography = experimentalStyled(Typography)(
  {
    textTransform: 'capitalize',
    color: '#DDEDFF',
    fontSize: '14px',
    '@media (max-width: 1024px)': {
      writingMode: 'vertical-rl',
      letterSpacing: '1px'
    }
  }
);

const RadialGradientLargeBlock = experimentalStyled(Box)(
  {
    width: '200px',
    height: '200px',
    position: 'absolute',
    top: '50px',
    background: '#87BEFF',
    filter: 'blur(120px)',
    left: '35%',
    '@media (max-width: 1024px)': {
      top: 'unset',
      left: 'unset',
      right: '10px',
      bottom: '20px'
    }
  }
);

const RadialGradientMediumBlock = experimentalStyled(Box)(
  {
    width: '200px',
    height: '200px',
    position: 'absolute',
    top: '60%',
    background: '#87BEFF',
    filter: 'blur(120px)',
    left: '2%'
  }
);

const RadialGradientRightBlock = experimentalStyled(Box)(
  {
    width: '200px',
    height: '200px',
    position: 'absolute',
    top: '5px',
    background: '#87BEFF',
    filter: 'blur(80px)',
    right: '30px'
  }
);

const ScrollContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
);

interface AlphaLaningBannerProps {
  banner: {
    cardImage: string,
    heading: string,
    subHeading: string
  }
}

const AlphaLandingBanner = (props: AlphaLaningBannerProps) => {
  const { banner } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { defaultSlug } = useAuth();
  const { slug } = useParams();

  return (
    <GridContainer
      {...props}
      item
      md={12}
      xs={12}
    >
      <BannerContainer>
        <RadialGradientLargeBlock />
        <RadialGradientMediumBlock />
        <RadialGradientRightBlock />
        {
          useMediaQuery('(max-width:1024px)') && (
            <>
              <LargeBubbleWrapper />
              <MediumBubbleWrapper />
              <SmallBubbleWrapper />
            </>
          )
        }
        <TextContainer>
          <TextTypography
            variant="h1"
            sx={{ textTransform: 'capitalize' }}
          >
            {banner?.heading}
          </TextTypography>
          <TextTypography>
            {banner?.subHeading}
          </TextTypography>
          {
            location.pathname === Routes.REGISTER ? (
              <CustomButton
                onClick={() => navigate('/onboard')}
              >
                {t('landing.apply_new_card')}
              </CustomButton>
            ) : (
              <CustomButton
                onClick={() => navigate(`/${slug || defaultSlug}/signup`)}
              >
                {t('landing.apply_new_card')}
              </CustomButton>
            )
          }
        </TextContainer>
        <ImageContainer>
          <BoxContainer>
            <MainImageWrapper
              src={`${STRAPI_URL}${banner?.cardImage}`}
              alt="Main card"
            />
            <SubImageWrapper
              src={`${STRAPI_URL}${banner?.cardImage}`}
              alt="Sub card"
            />
          </BoxContainer>
          {
            useMediaQuery('(min-width:1025px)') && (
              <>
                <LargeBubbleWrapper />
                <MediumBubbleWrapper />
                <SmallBubbleWrapper />
              </>
            )
          }
        </ImageContainer>
        <ScrollToBottomContainer>
          {
            useMediaQuery('(max-width:1024px)') ? (
              <ScrollContainer>
                <ExpandMoreIcon sx={{ color: 'white' }} />
                <ScrollDownTypography>
                  {t('landing.scroll_down')}
                </ScrollDownTypography>
              </ScrollContainer>
            ) : (
              <Box>
                <img
                  src={MouseLineIcon}
                  alt="Scroll down icon"
                />
                <ScrollDownTypography>
                  {t('landing.scroll_down')}
                </ScrollDownTypography>
              </Box>
            )
          }
        </ScrollToBottomContainer>
      </BannerContainer>
    </GridContainer>
  );
};

export default AlphaLandingBanner;
