import {
  Button,
  experimentalStyled
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from '@material-ui/icons/Warning';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  openModal: boolean,
  title: string,
  message: string,
  handleClose: () => void,
  login: () => void
}

export default function SessionExpiredModal(props: ModalProps) {
  const { openModal, handleClose, login, title, message } = props;
  const { t } = useTranslation();

  const DialogWrapper = experimentalStyled(Dialog)(
    {
      '& .MuiDialog-container': {
        alignItems: 'baseline'
      },
      '& .MuiDialogContent-root .MuiDialogContentText-root': {
        color: 'rgb(23, 43, 77)'
      }
    }
  );

  const DialogTitle = experimentalStyled('h2')(
    {
      marginRight: '10px',
      display: 'inline-block'
    }
  );

  const LoginButton = experimentalStyled(Button)(
    {
      border: 'none',
      background: 'var(--secondary-color)',
      color: 'white',
      width: '100px',
      borderRadius: '7px',
      marginLeft: '20px !important',
      marginRight: '10px !important',
      marginBottom: '20px',
      '&:hover': {
        backgroundColor: '#183B86',
      }
    }
  );

  const CancelButton = experimentalStyled(Button)(
    {
      width: '100px',
      borderRadius: '7px',
      marginBottom: '20px'
    }
  );

  const DeleteIconWrapper = experimentalStyled(WarningIcon)(
    {
      color: 'rgb(244, 67, 54)',
      display: 'inline-block',
      verticalAlign: 'sub',
      margin: '0px 5px 0px 10px',
      fontSize: '25px'
    }
  );

  return (
    <div>
      <DialogWrapper
        open={openModal}
        onClose={() => handleClose()}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <DeleteIconWrapper />
          <DialogTitle>
            {title}
          </DialogTitle>
        </div>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton
            color="primary"
            variant="outlined"
            onClick={() => handleClose()}
          >
            Ok
          </CancelButton>
          <LoginButton
            color="primary"
            variant="outlined"
            onClick={() => login()}
          >
            {t('general.login')}
          </LoginButton>
        </DialogActions>
      </DialogWrapper>
    </div>
  );
}
