import {
  Dialog,
  Typography,
  experimentalStyled
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@material-ui/icons/Clear';
import PrimaryButton from '../../widgets/buttons/PrimaryButton';
import { FINSIGNURL } from 'src/config';

const finSignIcon = '/static/icons/finsign.png';

const HeadingTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: '20px',
      '@media (max-width: 600px)': {
        display: 'inline-block'
      }
    }
  )
);

const ButtonWrapper = experimentalStyled('div')(() => (
  {
    margin: '30px 43px 0 40px',
    '@media (max-width: 800px)': {
      margin: '20px 15px'
    }
  }
));

const TextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      marginRight: '10px',
      fontSize: '16px',
      fontWeight: 'bold'
    }
  )
);

const InstructionsTextTypography = experimentalStyled(TextTypography)(
  {
    marginTop: '50px'
  }
);

const InstructionsLinkTextTypography = experimentalStyled(TextTypography)(
  {
    marginTop: '20px',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
);

const DialogWrapper = experimentalStyled(Dialog)(
  {
    '& .MuiDialog-paper': {
      paddingBottom: '40px'
    }
  }
);

const Container = experimentalStyled('div')(
  {
    marginLeft: '43px',
    marginRight: '40px',
    display: 'flex',
    '@media (max-width: 800px)': {
      margin: '0 20px 0 20px',
      display: 'block'
    }
  }
);

const CrossIconWrapper = experimentalStyled('div')(
  ({ theme }) => (
    {
      position: 'absolute',
      top: 10,
      background: theme.palette.primary.main,
      color: 'white',
      width: '20px',
      height: '20px',
      borderRadius: '20px',
      right: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    }
  )
);

const HeaderWrapper = experimentalStyled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '43px',
    marginTop: '24px',
    '@media (max-width: 800px)': {
      marginLeft: '20px',
      marginRight: '20px',
      display: 'block'
    }
  }
);

const TextContainer = experimentalStyled('div')(
  {
    marginTop: '40px',
    flex: 1
  }
);

const LogoWrapper = experimentalStyled('img')(
  {
    width: '350px',
    '@media (max-width: 800px)': {
      width: '300px',
    },
    '@media (max-width: 400px)': {
      width: '250px',
    }
  }
);

const ImageContainer = experimentalStyled('div')(
  {
    flex: 1,
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
);

interface QueriesModalProps {
  openModal: boolean,
  handleClose: () => void,
  isMediaQUeryMatches: boolean
}

const FinSignModal = (props: QueriesModalProps) => {
  const { openModal, handleClose, isMediaQUeryMatches } = props;
  const { t } = useTranslation();

  return (
    <DialogWrapper
      onClose={() => handleClose()}
      aria-labelledby="simple-dialog-title"
      open={openModal}
      maxWidth="md"
      fullWidth
    >
      <HeaderWrapper>
        <HeadingTypography variant="h5">
          {t('voucher.two_factor_verification')}
        </HeadingTypography>
      </HeaderWrapper>
      {
        isMediaQUeryMatches && (
          <ImageContainer>
            <LogoWrapper
              alt="ShopsWelcome Icon"
              src={finSignIcon}
            />
          </ImageContainer>
        )
      }
      <Container>
        <TextContainer>
          <TextTypography>
            {t('voucher.double_protection_two_factor_authentication')}
          </TextTypography>
          <InstructionsTextTypography>
            {t('voucher.instructions_for_activating_finsign_found_here')}
          </InstructionsTextTypography>
          <InstructionsLinkTextTypography
            onClick={() => window.open(FINSIGNURL)}
          >
            {t('voucher.instructions_for_activating_finsign')}
          </InstructionsLinkTextTypography>
        </TextContainer>
        {
          !isMediaQUeryMatches && (
            <ImageContainer>
              <LogoWrapper
                alt="ShopsWelcome Icon"
                src={finSignIcon}
              />
            </ImageContainer>
          )
        }
        <CrossIconWrapper>
          <ClearIcon
            sx={{ fontSize: '15px' }}
            onClick={() => handleClose()}
          />
        </CrossIconWrapper>
      </Container>
      <ButtonWrapper>
        <PrimaryButton
          padding="10px"
          onClick={() => handleClose()}
        >
          {t('voucher.conclude')}
        </PrimaryButton>
      </ButtonWrapper>
    </DialogWrapper>
  );
};

export default FinSignModal;
