import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const RecommendIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="currentColor"
  >
    <g transform="translate(0 -1)">
      <path
        className="a"
        d="M9.884,18.5A6.957,6.957,0,0,0,5.5,17a6.958,6.958,0,0,0-4.383,1.5A2.961,2.961,0,0,0,0,20.836V23H11V20.836A2.96,2.96,0,0,0,9.884,18.5Z"
      />
      <circle
        className="a"
        cx="3.5"
        cy="3.5"
        r="3.5"
        transform="translate(2 9)"
      />
      <path
        className="a"
        d="M22.884,18.5a7.161,7.161,0,0,0-8.767,0A2.961,2.961,0,0,0,13,20.836V23H24V20.836A2.96,2.96,0,0,0,22.884,18.5Z"
      />
      <circle
        className="a"
        cx="3.5"
        cy="3.5"
        r="3.5"
        transform="translate(15 9)"
      />
      <path
        className="a"
        d="M13,9V6h3V4H13V1H11V4H8V6h3V9Z"
      />
    </g>
  </svg>, 'RecommendIcon'
);

export default RecommendIcon;
