import { useEffect, useState } from 'react';
import {
  Dialog,
  Typography,
  experimentalStyled
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ButtonGroupCounter from './ButtonGroupCounter';
import PrimaryButton from './widgets/buttons/PrimaryButton';
import { apiService } from 'src/api';
import useAuth from 'src/hooks/useAuth';
import { PARTNERSTATUS } from 'src/config';

const HeadingTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      marginRight: '10px'
    }
  )
);

const CashbackTextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.text.primary,
      marginRight: '10px',
      marginTop: '10px',
      fontSize: '14px'
    }
  )
);

const Container = experimentalStyled('div')(
  {
    padding: '25px'
  }
);

const CashbackDivWrapper = experimentalStyled('div')(
  {
    width: '125px',
    marginTop: '25px',
    marginBottom: '1px'
  }
);

const ButtonWrapper = experimentalStyled('div')(
  {
    width: '120px',
    float: 'right',
    position: 'absolute',
    bottom: '30px',
    right: '30px',
    marginTop: '40px',
    '@media (max-width:1200px) and (min-width: 701px)': {
      marginTop: '49px'
    },
    '@media (max-width:1440px) and (min-width: 960px)': {
      bottom: '20px'
    },
    '@media (max-width:959px)': {
      position: 'unset',
      marginBottom: '25px'
    },
  }
);

const DialogWrapper = experimentalStyled(Dialog)(
  {
    '& .MuiDialog-paper': {
      paddingBottom: '40px'
    }
  }
);

interface UserCashbackModalProps {
  openModal: boolean
}

const UserCashbackModal = (props: UserCashbackModalProps) => {
  const { openModal } = props;
  const { user, updatePartner } = useAuth();
  const [partnerCashbackShare, setPartnerCashbackShare] = useState(0);
  const [cashback, setCashback] = useState(0);
  const { t } = useTranslation();

  const getPartnerCashbackShare = async (slug) => {
    await apiService.getUser(slug).then((data: any) => {
      setPartnerCashbackShare(data?.data?.data?.partner?.partnerCashbackShare);
    });
  };

  const updatePartnerDetails = () => {
    updatePartner({ id: user.partnerId, userCashbackShare: cashback, isUserCashbackDefined: true, status: PARTNERSTATUS.active });
  };

  const updateCashbackValue = (value) => {
    setCashback(value);
  };

  useEffect(() => {
    getPartnerCashbackShare(user?.partner?.slug);
  }, []);

  return (
    <DialogWrapper
      aria-labelledby="simple-dialog-title"
      open={openModal}
      maxWidth="sm"
      fullWidth
    >
      <Container>
        <div>
          <HeadingTypography variant="h5">
            {t('general.user_cashback_share')}
          </HeadingTypography>
        </div>
        <CashbackTextTypography variant="subtitle1">
          {t('general.in_order_to_continue_to_dashboard')}
        </CashbackTextTypography>
        <CashbackDivWrapper>
          <ButtonGroupCounter
            initialValue={cashback}
            maxValue={partnerCashbackShare}
            difference={0.1}
            updateValue={updateCashbackValue}
            valueType="%"
          />
        </CashbackDivWrapper>
        <ButtonWrapper>
          <PrimaryButton
            onClick={updatePartnerDetails}
          >
            {t('admin.save')}
          </PrimaryButton>
        </ButtonWrapper>
      </Container>
    </DialogWrapper>
  );
};

export default UserCashbackModal;
