import { DefaultApi } from 'omni-finance-api';
import axios from 'axios';
import { DISCOUNTREPORTSDOWNLOADPATHNAME, ExcelReqHeaders, LANGUAGE, TOKENS } from './constants';
import i18n from 'i18next';
import { NETWORKERROR } from './config';

let language;
const accessToken = () => `Bearer ${localStorage.getItem('accessToken')}`;

const initialLanguage = () => {
  language = (i18n.languages[0] === LANGUAGE.ENGLISH || i18n.languages[0] === LANGUAGE.GERMAN) ? i18n.languages[0] : LANGUAGE.ENGLISH;
  return language;
};

// Create axios instance
const axiosInstance = axios.create({ headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY, 'accept-language': initialLanguage() } });

const setLanguage = (switchLanguage) => {
  language = switchLanguage;
};

axiosInstance.interceptors.request.use((config) => {
  config.headers['accept-language'] = language;

  if (config && config.url && config.url.includes(DISCOUNTREPORTSDOWNLOADPATHNAME)) {
    config.headers['Content-Type'] = ExcelReqHeaders.openXMLHeader;
    config.responseType = ExcelReqHeaders.arrayBuffer as any;
  }
  return config;
});

// Configuration and base path are not provided
const apiService = new DefaultApi({ apiKey: accessToken }, process.env.REACT_APP_BASE_URL, axiosInstance);

// Handle  Access Token and Refresh Token expire.
axiosInstance.interceptors.response.use((res) => res, async (err) => {
  const originalRequest = err.config;
  if (err && err?.response && err?.response?.status === 401 && (err?.response?.data?.message === 'Invalid refresh token' || err?.response?.data?.message === 'Token is expired' || err?.response?.data?.message === 'User not registered')) {
    localStorage.removeItem(TOKENS.ACCESS_TOKEN);
    localStorage.removeItem(TOKENS.REFRESH_TOKEN);
    window.location.href = '/';
  } else if (err && err?.response && err?.response?.status === 401) {
    const refreshData = await apiService.refreshToken({ refreshToken: localStorage.getItem(TOKENS.REFRESH_TOKEN) });
    localStorage.setItem(TOKENS.ACCESS_TOKEN, refreshData.data.accessToken);
    originalRequest.headers.Authorization = `Bearer ${refreshData.data.accessToken}`;
    return axiosInstance(originalRequest);
  }

  if ((err.message === NETWORKERROR || err?.response?.status === 502) && localStorage.getItem(TOKENS.ACCESS_TOKEN)) {
    localStorage.removeItem(TOKENS.ACCESS_TOKEN);
    localStorage.removeItem(TOKENS.REFRESH_TOKEN);
    window.location.href = '/';
  }
  return Promise.reject(err);
});

export { apiService, axiosInstance, setLanguage };
