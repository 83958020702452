import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import Landing from 'src/pages/dashboard/Landing';
import OnboardDashboardLayout from './dashboard/landingDashboardLayout';
import { Routes } from 'src/constants';

interface RegisterGuardProps {
  children: ReactNode;
}

const RegisterGuard: FC<RegisterGuardProps> = (props) => {
  const { children } = props;
  const { isAuthenticated, preSignupAtDashboard } = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const { user } = useAuth();

  if ((!isAuthenticated || preSignupAtDashboard) && !user?.isOnboardSessionReceivedAtBank) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    if (!isAuthenticated && location.pathname === Routes.REGISTER) {
      return <Navigate to="/" />;
    }

    return (
      <OnboardDashboardLayout>
        <Landing />
      </OnboardDashboardLayout>
    );
  }

  if (isAuthenticated && (!preSignupAtDashboard || user?.isOnboardSessionReceivedAtBank)) {
    return <Navigate to="/dashboard" />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

RegisterGuard.propTypes = {
  children: PropTypes.node
};

export default RegisterGuard;
