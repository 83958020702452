import {
  experimentalStyled,
  Grid,
  Box,
  Typography,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import { STRAPI_URL } from 'src/config';

const useStyles = makeStyles(() => ({
  line: {
    height: '2px',
    opacity: 0,
    background: '#E9F3FF',
    width: '30%',
    position: 'absolute',
    transition: 'left 0.5s, opacity 0.5s',
    left: '35%',
    '&::after': {
      position: 'absolute',
      content: '""',
      top: '-7px',
      left: 0,
      width: 0,
      height: 0,
      borderTop: '8px solid transparent',
      borderBottom: '8px solid transparent',
      borderRight: '8px solid #E9F3FF'
    }
  },
  outerContainer: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      '& $cardText': {
        color: '#E9F3FF'
      },
      '& $line': {
        opacity: '1 !important',
        left: 0,
        width: '30%',
        transition: 'left 0.5s, opacity 0.5s',
      },
      '& $descriptionText': {
        opacity: '1 !important',
        top: '50px',
        transition: 'top 0.5s, opacity 0.5s',
      }
    }
  },
  lineContainer: {
    width: '30%'
  },
  cardText: {
    color: 'var(--secondary-color)'
  },
  descriptionText: {
    position: 'absolute',
    opacity: 0,
    color: '#E9F3FF',
    top: 0,
    marginLeft: '10px',
    fontSize: '15px',
    transition: 'top 0.5s, opacity 0.5s',
  }
}));

const GridContainer = experimentalStyled(Grid)(
  {
    marginBottom: '40px',
    '@media (max-width:530px) and (min-width: 300px)': {
      paddingLeft: '0px',
      paddingRight: '0px',
      '& .MuiContainer-root .MuiContainer-maxWidthXl': {
        paddingRight: '0px'
      },
      '@media (max-width: 530px)': {
        '& .MuiPaper-root': {
          borderRadius: '0px'
        }
      }
    },
  }
);

const BenefitContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
    paddingTop: '50px',
    backgroundColor: 'var(--primary-color)',
    minHeight: '750px',
    position: 'relative'
  }
);

const TextTypography = experimentalStyled(Typography)(
  {
    color: 'white',
    width: '50%',
    textAlign: 'center',
    '@media (max-width: 1250px)': {
      width: '100%'
    },
  }
);

const DescriptionTextTypography = experimentalStyled(Typography)(
  {
    color: '#E9F3FF',
    marginTop: '25px',
    width: '50%',
    textAlign: 'center',
    '@media (max-width: 1250px)': {
      width: '100%'
    },
  }
);

const InnerContainer = experimentalStyled(Box)(
  {
    marginTop: '250px',
    position: 'relative',
    width: '75%',
    '@media (max-width: 1500px)': {
      width: '90%',
    },
    '@media (max-width: 1250px)': {
      width: '100%',
    },
  }
);

const InnerCardContainer = experimentalStyled(Box)(
  {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    marginTop: '-175px',
    cursor: 'pointer'
  }
);

const ImageWrapper = experimentalStyled('img')(
  {
    minWidth: '300px',
    height: 'auto',
    '@media (max-width: 1250px)': {
      width: '500px',
    },
  }
);

const CardBenefitTexttypography = experimentalStyled(Typography)(
  {
    fontSize: '24px',
    cursor: 'pointer',
    marginLeft: '10px',
    textTransform: 'capitalize',
    '@media (max-width: 1250px)': {
      fontSize: '20px',
    },
  }
);

const SideTextContainer = experimentalStyled(Box)(
  {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    right: '5%',
    alignItems: 'center'
  }
);

const DotWrapper = experimentalStyled(Box)(
  {
    height: '18px',
    width: '18px',
    borderRadius: '10px',
    background: 'white',
    marginBottom: '10px'
  }
);

const VerticalAlignText = experimentalStyled(Typography)(
  {
    writingMode: 'vertical-rl',
    fontSize: '14px',
    color: 'white'
  }
);

const RadialGradientLargeBlock = experimentalStyled(Box)(
  {
    width: '200px',
    height: '200px',
    position: 'absolute',
    top: '100px',
    background: '#87BEFF',
    filter: 'blur(120px)',
    right: '35%'
  }
);

const RadialGradientMediumBlock = experimentalStyled(Box)(
  {
    width: '200px',
    height: '200px',
    position: 'absolute',
    top: '60%',
    background: '#87BEFF',
    filter: 'blur(120px)',
    left: '10%'
  }
);

const ImageMobileViewWrapper = experimentalStyled('img')(
  {
    width: '500px',
    '@media (max-width: 600px)': {
      width: '450px',
    },
    '@media (max-width: 500px)': {
      width: '400px',
    },
    '@media (max-width: 450px)': {
      width: '350px',
    },
    '@media (max-width: 380px)': {
      width: '300px',
    },
  }
);

const MobileViewContainer = experimentalStyled(Box)(
  {
    marginTop: '50px'
  }
);

const MobileTextTypography = experimentalStyled(Typography)(
  {
    fontSize: '24px',
    marginTop: '15px',
    color: '#63A3F2',
    '@media (max-width: 380px)': {
      fontSize: '18px',
    },
  }
);

const MobileInnerContainer = experimentalStyled(Box)(
  {
    textAlign: 'center',
    marginTop: '20px'
  }
);

interface BenefitsDetails {
  id?: number,
  benefit: string,
  description: string,
  cardImage: string
}

interface AlphaLandingBenefitProps {
  benefitsIntroduction: {
    title: string,
    heading: string,
    subHeading: string,
  },
  benefitDetails: BenefitsDetails[]
}

const AlphaLandingBenefit = (props: AlphaLandingBenefitProps) => {
  const { benefitsIntroduction, benefitDetails, ...rest } = props;
  const classes = useStyles();

  return (
    <GridContainer
      {...rest}
      item
      md={12}
      xs={12}
    >
      <BenefitContainer>
        {
          useMediaQuery('(min-width:1025px)') && (
            <>
              <RadialGradientLargeBlock />
              <RadialGradientMediumBlock />
            </>
          )
        }
        {
          useMediaQuery('(min-width:1025px)') && (
            <SideTextContainer>
              <DotWrapper />
              <VerticalAlignText>
                {benefitsIntroduction?.title}
              </VerticalAlignText>
            </SideTextContainer>
          )
        }
        <TextTypography
          variant="h2"
        >
          {benefitsIntroduction?.heading}
        </TextTypography>
        <DescriptionTextTypography>
          {benefitsIntroduction?.subHeading}
        </DescriptionTextTypography>
        {
          useMediaQuery('(min-width:1025px)') ? (
            <InnerContainer>
              {
                benefitDetails?.map((item: any, index: number) => (
                  <InnerCardContainer key={item?.id}>
                    <ImageWrapper
                      src={`${STRAPI_URL}${item?.cardImage}`}
                      alt={item?.benefit}
                      sx={{ opacity: index === benefitDetails?.length - 1 ? 0.8 : 0.9, zIndex: `${6 - index}` }}
                    />
                    <Box className={classes.outerContainer}>
                      <Box
                        className={classes.lineContainer}
                      >
                        <Box
                          className={classes.line}
                        />
                      </Box>
                      <Box>
                        <CardBenefitTexttypography className={classes.cardText}>
                          {item?.benefit}
                        </CardBenefitTexttypography>
                        <Typography className={classes.descriptionText}>
                          {item?.description}
                        </Typography>
                      </Box>
                    </Box>
                  </InnerCardContainer>
                ))
              }
            </InnerContainer>
          ) : (
            <MobileViewContainer>
              {
                benefitDetails?.map((item: any) => (
                  <MobileInnerContainer
                    key={item?.id}
                    sx={{ width: '100%' }}
                  >
                    <ImageMobileViewWrapper
                      src={`${STRAPI_URL}${item?.cardImage}`}
                      alt={item?.benefit}
                    />
                    <MobileTextTypography>
                      {item?.benefit}
                    </MobileTextTypography>
                  </MobileInnerContainer>
                ))
              }
            </MobileViewContainer>
          )
        }
      </BenefitContainer>
    </GridContainer>
  );
};

export default AlphaLandingBenefit;
