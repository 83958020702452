import { shopPortal } from 'src/config';

export const generateReferralID = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const handleLogin = (cognitoConfig: any) => {
  const { domain, clientId, responseType, scope, redirectUri } = cognitoConfig;
  window.location.href = `${domain}/login?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectUri}`;
};

export const handleLogout = (cognitoConfig: any) => {
  const { domain, clientId, responseType, scope, redirectUri, logoutUri } = cognitoConfig;
  window.location.href = `${domain}/logout?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectUri}&logout_uri=${logoutUri}`;
};

export const formatNumber = (number) => Number(number).toLocaleString('en').replace(/,/g, "'");

export const removeSpaces = (value: string) => (value).replace(/ /g, '');

export const REGEXTOREMOVEPARENTHESISANDSPACES = /[ )(]/g;

export const shopRedirect = () => {
  const { domain, clientId, responseType, scope, redirectUri } = shopPortal;
  const url = `${domain}/login?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectUri}`;
  window.open(url);
};
