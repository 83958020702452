import { createContext, useReducer } from 'react';
import type { ReactNode } from 'react';
import { cmsApiService } from '../cms-api';
import { LandingPage, RecommendationPage, DashboardPage, GlassMorphishmLandingPage } from 'src/types/landing-page';

interface State {
  landingPageContent: LandingPage | null;
  recommendationPageContent: RecommendationPage | null;
  dashboardPageContent: DashboardPage | null;
  isContentLoaded: boolean;
  glassMorphismLandingPageContent: GlassMorphishmLandingPage | null;
}

interface StrapiCmsContextValue extends State {
  getLandingPageDetails: (slug: string, language: string) => Promise<void>;
  getRecommendationPageDetails: (slug: string, language: string) => Promise<void>;
  getDashboardPageDetails: (slug: string, language: string) => Promise<void>;
  getGlassMorphismLandingPageDetails: (slug: string, language: string) => Promise<void>;
}

interface StrapiCmsProviderProps {
  children: ReactNode;
}

type GetLandingPageDetailsAction = {
  type: 'GETLANDINGPAGEDETAILSACTION';
  payload: {
    landingPageContent: LandingPage;
    isContentLoaded: boolean;
  };
};

type GetGlassMorphismLandingPageDetailsAction = {
  type: 'GETGlASSMORPHISMLANDINGPAGEDETAILSACTION';
  payload: {
    glassMorphismLandingPageContent: GlassMorphishmLandingPage;
    isContentLoaded: boolean;
  };
};

type GetRecommendationPageDetailsAction = {
  type: 'GETRECOMMENDATIONPAGEDETAILSACTION';
  payload: {
    recommendationPageContent: RecommendationPage;
    isContentLoaded: boolean;
  };
};

type GetDashboardPageDetailsAction = {
  type: 'GETDASHBOARDPAGEDETAILSACTION';
  payload: {
    dashboardPageContent: DashboardPage;
    isContentLoaded: boolean;
  };
};

type UpdateIsContentLoadedAction = {
  type: 'UPDATEISCONTENTLOADEDACTION';
  payload: {
    isContentLoaded: boolean;
  };
};

type Action =
  | GetLandingPageDetailsAction
  | GetRecommendationPageDetailsAction
  | GetDashboardPageDetailsAction
  | UpdateIsContentLoadedAction
  | GetGlassMorphismLandingPageDetailsAction;

const initialState: State = {
  landingPageContent: null,
  recommendationPageContent: null,
  dashboardPageContent: null,
  glassMorphismLandingPageContent: null,
  isContentLoaded: false
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  GETLANDINGPAGEDETAILSACTION: (state: State, action: GetLandingPageDetailsAction): State => {
    const { landingPageContent, isContentLoaded } = action.payload;
    return {
      ...state,
      landingPageContent,
      isContentLoaded
    };
  },
  GETGlASSMORPHISMLANDINGPAGEDETAILSACTION: (state: State, action: GetGlassMorphismLandingPageDetailsAction): State => {
    const { glassMorphismLandingPageContent, isContentLoaded } = action.payload;
    return {
      ...state,
      glassMorphismLandingPageContent,
      isContentLoaded
    };
  },
  GETRECOMMENDATIONPAGEDETAILSACTION: (state: State, action: GetRecommendationPageDetailsAction): State => {
    const { recommendationPageContent, isContentLoaded } = action.payload;
    return {
      ...state,
      recommendationPageContent,
      isContentLoaded
    };
  },
  GETDASHBOARDPAGEDETAILSACTION: (state: State, action: GetDashboardPageDetailsAction): State => {
    const { dashboardPageContent, isContentLoaded } = action.payload;
    return {
      ...state,
      dashboardPageContent,
      isContentLoaded
    };
  },
  UPDATEISCONTENTLOADEDACTION: (state: State, action: UpdateIsContentLoadedAction): State => {
    const { isContentLoaded } = action.payload;
    return {
      ...state,
      isContentLoaded
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const StrapiCmsContext = createContext<StrapiCmsContextValue>({
  ...initialState,
  getLandingPageDetails: () => Promise.resolve(),
  getRecommendationPageDetails: () => Promise.resolve(),
  getDashboardPageDetails: () => Promise.resolve(),
  getGlassMorphismLandingPageDetails: () => Promise.resolve()
});

export const StrapiCmsProvider = (props: StrapiCmsProviderProps) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const getLandingPageDetails = async (slug: string, language: string): Promise<void> => {
    await cmsApiService.getLandingPageDetails('deep', slug, language).then((data) => {
      const landingPageContent = data?.data?.data[0]?.attributes;
      landingPageContent.cardUrl = landingPageContent?.Card?.card?.data?.attributes?.url;
      landingPageContent.CobranderMessage.image = landingPageContent?.CobranderMessage?.icon?.data?.attributes?.url;
      dispatch({
        type: 'GETLANDINGPAGEDETAILSACTION',
        payload: {
          landingPageContent,
          isContentLoaded: true
        }
      });
    }).catch(() => {
      dispatch({
        type: 'UPDATEISCONTENTLOADEDACTION',
        payload: {
          isContentLoaded: true
        }
      });
    });
  };

  const getGlassMorphismLandingPageDetails = async (slug: string, language: string): Promise<void> => {
    await cmsApiService.getGlassMorphismLandingPageDetails('deep', slug, language).then((data) => {
      const contentData = data?.data?.data[0]?.attributes;
      const content: any = {};
      const bannerContent = contentData?.Banner;
      content.banner = {
        heading: bannerContent?.heading,
        subHeading: bannerContent?.subheading,
        cardImage: bannerContent?.card?.data?.attributes?.url
      };

      // Journey Component Content
      const journeyContent = contentData?.Journey;
      content.journey = {
        heading: journeyContent?.heading,
        title: journeyContent?.title,
        description1: journeyContent?.description1,
        description2: journeyContent?.description2
      };
      content.journey.journeyProcess = [];
      journeyContent.JourneyProcess?.forEach((item: any) => {
        content.journey.journeyProcess.push({
          id: item?.id,
          step: item?.Step1,
          image: item?.Image?.data?.attributes?.url
        });
      });

      // Benefits Component content
      const benefitsIntroductionData = contentData?.BenefitsIntroduction;
      content.benefitsIntroduction = {
        title: benefitsIntroductionData?.title,
        heading: benefitsIntroductionData?.heading,
        subHeading: benefitsIntroductionData?.subheading
      };
      content.benefitDetails = [];
      contentData?.BenefitsDetails?.forEach((item: any) => {
        content.benefitDetails.push({
          id: item?.id,
          benefit: item?.benefit,
          description: item?.description,
          cardImage: item?.card?.data?.attributes?.url
        });
      });

      // Branding component content
      const brandingContent = contentData?.Branding;
      content.branding = {
        title: brandingContent?.title,
        heading: brandingContent?.heading,
        subHeading: brandingContent?.subheading,
        content: brandingContent?.content,
        brandImage: brandingContent?.brands?.data?.attributes?.url
      };

      // Good to know component content
      const goodToKnowIntroductionContent = contentData?.GoodToKnowIntroduction;
      content.goodToKnowIntroduction = {
        title: goodToKnowIntroductionContent?.title,
        heading: goodToKnowIntroductionContent?.heading,
        subHeading: goodToKnowIntroductionContent?.subheading
      };
      content.goodToKnowDetails = [];
      contentData?.GoodToKnowDetails?.forEach((item: any) => {
        content.goodToKnowDetails.push({
          id: item?.id,
          question: item?.question,
          answer: item?.answer
        });
      });

      // social media content
      content.socialMedia = [];
      contentData?.SocialMedia?.forEach((item: any) => {
        content.socialMedia.push({
          id: item?.id,
          name: item?.name,
          url: item?.url,
          icon: item?.icon?.data?.attributes?.url
        });
      });

      const glassMorphismLandingPageContent = content;

      dispatch({
        type: 'GETGlASSMORPHISMLANDINGPAGEDETAILSACTION',
        payload: {
          glassMorphismLandingPageContent,
          isContentLoaded: true
        }
      });
    }).catch(() => {
      dispatch({
        type: 'UPDATEISCONTENTLOADEDACTION',
        payload: {
          isContentLoaded: true
        }
      });
    });
  };

  const getRecommendationPageDetails = async (slug: string, language: string,): Promise<void> => {
    await cmsApiService.getRecommendationPageDetails('deep', slug, language).then((data) => {
      const recommendationPageContent = data?.data?.data[0]?.attributes;
      recommendationPageContent.imageUrl = recommendationPageContent.ImageBlock.image.data[0].attributes.url;
      dispatch({
        type: 'GETRECOMMENDATIONPAGEDETAILSACTION',
        payload: {
          recommendationPageContent,
          isContentLoaded: true
        }
      });
    }).catch(() => {
      dispatch({
        type: 'UPDATEISCONTENTLOADEDACTION',
        payload: {
          isContentLoaded: true
        }
      });
    });
  };

  const getDashboardPageDetails = async (slug: string, language: string): Promise<void> => {
    await cmsApiService.getDashboardPageDetails('deep', slug, language).then((data) => {
      const dashboardPageContent = data?.data?.data[0]?.attributes;
      dispatch({
        type: 'GETDASHBOARDPAGEDETAILSACTION',
        payload: {
          dashboardPageContent,
          isContentLoaded: true
        }
      });
    }).catch(() => {
      dispatch({
        type: 'UPDATEISCONTENTLOADEDACTION',
        payload: {
          isContentLoaded: true
        }
      });
    });
  };

  return (
    <StrapiCmsContext.Provider
      value={{
        ...state,
        getLandingPageDetails,
        getRecommendationPageDetails,
        getGlassMorphismLandingPageDetails,
        getDashboardPageDetails
      }}
    >
      {children}
    </StrapiCmsContext.Provider>
  );
};

export default StrapiCmsContext;
