import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import Landing from 'src/pages/dashboard/Landing';
import OnboardDashboardLayout from './dashboard/landingDashboardLayout';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const { user } = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if ((!auth.isAuthenticated || auth.preSignupAtDashboard) && !user?.isOnboardSessionReceivedAtBank) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    if (auth.isAuthenticated && auth.preSignupAtDashboard) {
      return <Navigate to="/register" />;
    }

    return (
      <OnboardDashboardLayout>
        <Landing />
      </OnboardDashboardLayout>
    );
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
