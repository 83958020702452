import {
  Dialog,
  Typography,
  experimentalStyled,
  useTheme,
  TextField
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@material-ui/icons/Clear';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { apiService } from 'src/api';
import PrimaryButton from './widgets/buttons/PrimaryButton';

const HeadingTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: '20px',
      margin: '20px',
      marginLeft: '20px',
      '@media (max-width: 600px)': {
        display: 'inline-block',
        width: '65%',
        fontSize: '16px'
      }
    }
  )
);

const ButtonWrapper = experimentalStyled('div')(() => (
  {
    marginTop: '20px',
    '@media (max-width: 600px)': {
      width: '100%'
    }
  }
));

const TextTypography = experimentalStyled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.main,
      marginRight: '10px',
      fontSize: '16px',
      fontWeight: 'bold'
    }
  )
);

const DialogWrapper = experimentalStyled(Dialog)(
  {
    '& .MuiDialog-paper': {
      paddingBottom: '40px'
    }
  }
);

const Container = experimentalStyled('div')(
  {
    marginLeft: '43px',
    marginRight: '40px',
    '@media (max-width: 600px)': {
      margin: '0 20px 0 20px'
    }
  }
);

const CrossIconWrapper = experimentalStyled('div')(
  ({ theme }) => (
    {
      position: 'absolute',
      top: 10,
      background: theme.palette.primary.main,
      color: 'white',
      width: '20px',
      height: '20px',
      borderRadius: '20px',
      right: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    }
  )
);

const HeaderWrapper = experimentalStyled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '43px',
    marginTop: '24px',
    '@media (max-width: 600px)': {
      marginLeft: '20px',
      marginRight: '20px',
      display: 'block'
    }
  }
);

const QuestionMarkWrapper = experimentalStyled('div')(
  ({ theme }) => (
    {
      background: theme.palette.primary.main,
      fontSize: '24px',
      fontWeight: 'bold',
      width: '40px',
      height: '40px',
      borderRadius: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      '@media (max-width: 600px)': {
        display: 'inline-block',
        textAlign: 'center',
        verticalAlign: 'text-bottom'
      }
    }
  )
);

const CustomTextField = experimentalStyled(TextField)(
  () => (
    {
      display: 'inline-block',
      width: '100%',
      verticalAlign: 'baseline',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderWidth: '2px',
        borderColor: useTheme().palette.primary.main
      },
      '& .MuiOutlinedInput-input': {
        color: useTheme().palette.primary.main
      },
      '@media (max-width: 600px)': {
        width: '100%'
      }
    }
  )
);

const FieldContainer = experimentalStyled('div')(
  {
    marginTop: '20px'
  }
);

interface QueriesModalProps {
  openModal: boolean,
  handleClose: () => void
}

const QueriesModal = (props: QueriesModalProps) => {
  const { openModal, handleClose } = props;
  const { t } = useTranslation();

  const [ticket, setTicket] = useState({
    title: '',
    description: '',
    name: '',
    email: ''
  });

  const updateTicketData = (key, value) => {
    const tempData = { ...ticket };
    tempData[key] = value;
    setTicket(tempData);
  };

  const submitTicket = async () => {
    await apiService.submitTicket({ ...ticket }).then((res) => {
      toast.success(res.data.message);
      handleClose();
    }).catch((error) => {
      toast.error(error && error.response && error.response.data && error.response.data.message);
    });
  };

  return (
    <DialogWrapper
      onClose={() => handleClose()}
      aria-labelledby="simple-dialog-title"
      open={openModal}
      maxWidth="lg"
      fullWidth
    >
      <HeaderWrapper>
        <QuestionMarkWrapper>
          ?
        </QuestionMarkWrapper>
        <HeadingTypography variant="h5">
          {t('general.questions_suggestions_contact_us')}
        </HeadingTypography>
      </HeaderWrapper>
      <Container>
        <FieldContainer>
          <TextTypography
            display="inline-block"
            sx={{ minWidth: '100px' }}
          >
            {t('general.title')}
          </TextTypography>
          <CustomTextField
            name="title"
            type="text"
            variant="outlined"
            fullWidth
            size="small"
            InputLabelProps={{ shrink: false }}
            onChange={(event): void => updateTicketData('title', event.target.value)}
          />
        </FieldContainer>
        <FieldContainer>
          <TextTypography
            display="inline-block"
            sx={{ minWidth: '100px' }}
          >
            {t('general.description')}
          </TextTypography>
          <CustomTextField
            name="description"
            type="text"
            variant="outlined"
            fullWidth
            rows={6}
            multiline
            size="small"
            InputLabelProps={{ shrink: false }}
            onChange={(event): void => updateTicketData('description', event.target.value)}
          />
        </FieldContainer>
        <FieldContainer>
          <TextTypography
            display="inline-block"
            sx={{ minWidth: '100px' }}
          >
            Name
          </TextTypography>
          <CustomTextField
            name="name"
            type="text"
            variant="outlined"
            fullWidth
            size="small"
            InputLabelProps={{ shrink: false }}
            onChange={(event): void => updateTicketData('name', event.target.value)}
          />
        </FieldContainer>
        <FieldContainer>
          <TextTypography
            display="inline-block"
            sx={{ minWidth: '100px' }}
          >
            Email
          </TextTypography>
          <CustomTextField
            name="email"
            type="text"
            variant="outlined"
            fullWidth
            size="small"
            InputLabelProps={{ shrink: false }}
            onChange={(event): void => updateTicketData('email', event.target.value)}
          />
        </FieldContainer>
        <ButtonWrapper>
          <PrimaryButton
            onClick={submitTicket}
            padding="10px"
          >
            {t('general.send')}
          </PrimaryButton>
        </ButtonWrapper>
        <CrossIconWrapper>
          <ClearIcon
            sx={{ fontSize: '15px' }}
            onClick={() => handleClose()}
          />
        </CrossIconWrapper>
      </Container>
    </DialogWrapper>
  );
};

export default QueriesModal;
