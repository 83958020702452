export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

export const hblBankConfig = {
  bankPlayGroundUrl: process.env.REACT_APP_HBL_BANK_URL,
  style: process.env.REACT_APP_HBL_BANK_STYLE,
  product: process.env.REACT_APP_HBL_BANK_PRODUCT,
  doapiURL: process.env.REACT_APP_HBL_DOAPI_URL
};

export const cognitoConfig = {
  domain: process.env.REACT_APP_COGNITO_DOMAIN,
  clientId: process.env.REACT_APP_CLIENT_ID,
  responseType: process.env.REACT_APP_RESPONSE_TYPE,
  scope: process.env.REACT_APP_SCOPE,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  logoutUri: process.env.REACT_APP_LOGOUT_URI,
};

export const HBLBankDetails = {
  bankName: 'Hypothekarbank Lenzburg AG',
  street: 'Bahnhofstrasse 2',
  city: 'Lenzburg',
  bankClearing: '8307',
  swiftCode: 'HYPLCH22',
  bankCode: '5600'
};

export const S3Url = process.env.REACT_APP_S3_URL;

export const S3Folders = {
  vouchers: process.env.REACT_APP_S3_BUCKET_VOUCHERS_FOLDER,
  profiles: process.env.REACT_APP_S3_BUCKET_PROFILES_FOLDER,
  cards: process.env.REACT_APP_S3_BUCKET_CARDSS_FOLDER,
  cashbackfiles: process.env.REACT_APP_S3_BUCKET_CASHBACKFILES_FOLDER,
  logos: process.env.REACT_APP_S3_BUCKET_LOGOS_FOLDER
};

export const CASHBACKTABS = {
  cashback: 'Cashbacks',
  discount: 'Discounts',
  allCashbacks: 'allCashbacks',
  coupons: 'COUPONS',
  purchases: 'PURCHASES'
};

export const PARTNERSTATUS = {
  active: 'active',
  inactive: 'inactive'
};

export const DATEFORMAT = 'dd.MM.yyyy';

export const FILEUPLOADDATEFORMAT = 'dd MMMM yyyy';

export const APPURL = process.env.REACT_APP_URL;

export const CURRENCYFORMAT = 'CHF';

export const MAX_CASHBACK = 500;

export const REALTIME_WEBSOCKET_URL = process.env.REACT_APP_REALTIME_WEBSOCKET_URL;

export const shopPortal = {
  navigationURL: process.env.REACT_APP_SHOPS_NAVIGATION_URL,
  siteName: process.env.REACT_APP_SHOPS_SITE_NAME,
  domain: process.env.REACT_APP_SHOPS_COGNITO_DOMAIN,
  clientId: process.env.REACT_APP_SHOPS_CLIENT_ID,
  responseType: process.env.REACT_APP_SHOPS_RESPONSE_TYPE,
  scope: process.env.REACT_APP_SHOPS_SCOPE,
  redirectUri: process.env.REACT_APP_SHOPS_REDIRECT_URI,
  logoutUri: process.env.REACT_APP_SHOPS_LOGOUT_URI,
};

export const IdleTimeOut = 600000; // 10 minutes

export const FINSIGNURL = process.env.REACT_APP_FINSIGN_URL;

export const NETWORKERROR = 'Network Error';

export const DEFAULT_CASHBACK_WITHDRAW_LIMIT = process.env.REACT_APP_DEFAULT_CASHBACK_WITHDRAW_LIMIT;

export const STRAPI_URL = process.env.REACT_APP_STRAPI_URL;
