/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import {
  experimentalStyled,
  Dialog,
  IconButton,
  Box,
  Button,
  Typography,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { apiService } from 'src/api';

const icon = '/static/icons/coin-reward.png';

const DialogWrapper = experimentalStyled(Dialog)(
  {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '450px',
        borderRadius: '32px'
      }
    },
    '& .MuiDialogContent-root .MuiDialogContentText-root': {
      color: 'rgb(23, 43, 77)'
    },
  }
);

const RewardsTitleWrapper = experimentalStyled('div')(
  {
    display: 'flex',
    justifyContent: 'center',
    background: '#E8F3FD',
    flexDirection: 'column',
    alignItems: 'center'
  }
);

const DialogContentWrapper = experimentalStyled(DialogContent)(
  {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 49px',
    overflow: 'initial  '
  }
);

const DialogActionsWrapper = experimentalStyled(DialogActions)(
  {
    justifyContent: 'center',
    padding: '20px 49px',
  }
);

const ButtonWrapper = experimentalStyled(Button)(
  {
    width: '100%',
    height: 56,
    justifyContent: 'center',
  }
);

const DialogTitle = experimentalStyled(Typography)(
  {
    display: 'inline-block',
    fontSize: '18px',
    color: '#5A490D',
    paddingBottom: '15px'
  }
);

const ImageContainer = experimentalStyled(Box)(
  {
    width: '200px',
    height: '200px',
    position: 'relative'
  }
);

const AmountBlock = experimentalStyled(Box)(
  {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
);

const AmountTextTypography = experimentalStyled(Typography)(
  {
    color: '#5A490D',
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '30px'
  }
);

const AmountCurrencyTypography = experimentalStyled(Typography)(
  {
    color: '#5A490D',
    fontSize: '35px',
  }
);

const ThankYouTextTypography = experimentalStyled(Typography)(
  {
    fontSize: '32px',
    color: '#170F49',
    fontWeight: 500,
    letterSpacing: 1,
    paddingTop: '15px'
  }
);

const BringFriendTextTypography = experimentalStyled(Typography)(
  {
    fontSize: '18px',
    color: '#170F49',
    fontWeight: 500,
    letterSpacing: 1,
    paddingTop: '5px',
    textAlign: 'center'
  }
);

const DonationMessageTypography = experimentalStyled(Typography)(
  {
    fontSize: '18px',
    lineHeight: '30px',
    textAlign: 'center',
    color: '#6F6C90',
    padding: '15px 0'
  }
);

const IconButtonWrapper = experimentalStyled(IconButton)(
  {
    position: 'absolute',
    right: '5px',
    top: '5px'
  }
);

const LogoWrapper = experimentalStyled('img')(
  {
  }
);

interface DonationModalProps {
  openModal: boolean,
  handleClose: () => void,
  invitation: any
}

const updateUserInvitation = async (invitation: any) => {
  await apiService.updateUserInvitation({ id: invitation?._id });
};

const RewardsCashbackModal = (props: DonationModalProps) => {
  const { openModal, handleClose, invitation } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <DialogWrapper
        open={openModal}
        onClose={() => {
          handleClose();
          updateUserInvitation(invitation);
        }}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButtonWrapper
          aria-label="close"
          onClick={() => {
            handleClose();
            updateUserInvitation(invitation);
          }}
        >
          <CloseIcon />
        </IconButtonWrapper>
        <RewardsTitleWrapper>
          <ImageContainer>
            <LogoWrapper
              alt="Rewards"
              src={icon}
            />
            <AmountBlock>
              <AmountTextTypography>{invitation?.rewarded_donation_amount}</AmountTextTypography>
              <AmountCurrencyTypography>{invitation?.rewarded_currency}</AmountCurrencyTypography>
            </AmountBlock>
          </ImageContainer>
          <DialogTitle>
            {t('rewardsSection.title')}
          </DialogTitle>
        </RewardsTitleWrapper>
        <DialogContentWrapper>
          <ThankYouTextTypography>
            {t('rewardsSection.thankYou')}
          </ThankYouTextTypography>
          <BringFriendTextTypography>
            {t('rewardsSection.bringingYourFriend')}
          </BringFriendTextTypography>
          <DonationMessageTypography>
            {t('rewardsSection.donateMessage')}
          </DonationMessageTypography>
        </DialogContentWrapper>
        <DialogActionsWrapper>
          <ButtonWrapper
            variant="contained"
            color="primary"
            onClick={() => {
              handleClose();
              updateUserInvitation(invitation);
              navigate('/dashboard/donations');
            }}
          >
            {t('donationsSections.donate')}
          </ButtonWrapper>
        </DialogActionsWrapper>
      </DialogWrapper>
    </div>
  );
};

export default RewardsCashbackModal;
