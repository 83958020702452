import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, experimentalStyled, ThemeProvider, Box } from '@material-ui/core';
import './i18n';
import RTL from './components/RTL';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import './styles.css';
import QueriesModal from './components/QueriesModal';
import UserCashbackModal from './components/UserCashbackModal';

const QueriesIcon = '/static/icons/queries-icon.svg';

const QuestionIconWrapper = experimentalStyled(Box)(
  {
    position: 'fixed',
    bottom: 0,
    right: '24px',
    cursor: 'pointer'
  }
);

const App: FC = () => {
  const content = useRoutes(routes);
  const [open, setOpenModal] = useState(false);
  const { user } = useAuth();
  const { settings } = useSettings();
  const auth = useAuth();

  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const openModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster position="top-right" />
        {auth.isInitialized ? content : <SplashScreen />}
        <QuestionIconWrapper
          onClick={openModal}
        >
          <img
            src={QueriesIcon}
            alt="Queries icon"
          />
        </QuestionIconWrapper>
        {
          open && (
            <QueriesModal
              openModal={open}
              handleClose={handleClose}
            />
          )
        }
        {
          (user?.partner?.isUserCashbackDefined !== undefined && !user.partner.isUserCashbackDefined) && (
            <UserCashbackModal
              openModal={(user?.partner?.isUserCashbackDefined !== undefined && !user.partner.isUserCashbackDefined)}
            />
          )
        }
      </RTL>
    </ThemeProvider>
  );
};

export default App;
