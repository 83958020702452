export enum DonationSource {
  'CASHBACK' = 'Cashback',
  'DONATION' = 'Donation'
}

export enum InvitationStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
}

export interface Invitation {
  id: number;
  user_id: number;
  email: number;
  rewarded: number;
  rewarded_amount: number
  rewarded_donation_amount: number;
  rewarded_currency: string;
  showed_to_user: boolean;
  status: string;
  created_at: string | Date;
  updated_at: string | Date | null;
}
