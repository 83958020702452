"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * View Capital API
 * View Capital API
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("./common");
// @ts-ignore
const base_1 = require("./base");
/**
 * DefaultApi - axios parameter creator
 * @export
 */
exports.DefaultApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Add user
         * @param {User} user user should be added here
         * @param {string} [xCsrfOmni]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser: (user, xCsrfOmni, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'user' is not null or undefined
            common_1.assertParamExists('addUser', 'user', user);
            const localVarPath = `/onboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (xCsrfOmni !== undefined && xCsrfOmni !== null) {
                localVarHeaderParameter['x-csrf-omni'] = String(xCsrfOmni);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(user, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Add user donation
         * @param {UserDonation} userDonation user donation should be added here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserDonation: (userDonation, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userDonation' is not null or undefined
            common_1.assertParamExists('addUserDonation', 'userDonation', userDonation);
            const localVarPath = `/user/donations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(userDonation, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Add a voucher
         * @param {Voucher} voucher Add a voucher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVoucher: (voucher, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'voucher' is not null or undefined
            common_1.assertParamExists('addVoucher', 'voucher', voucher);
            const localVarPath = `/voucher/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(voucher, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Cashback File Upload
         * @param {CashbackFile} cashbackFile Cashback File Upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashbackFileUpload: (cashbackFile, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'cashbackFile' is not null or undefined
            common_1.assertParamExists('cashbackFileUpload', 'cashbackFile', cashbackFile);
            const localVarPath = `/cashbackProcessor/cashbackFile/compute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(cashbackFile, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Cashback redemption
         * @param {Amount} amount login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashbackRedemption: (amount, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'amount' is not null or undefined
            common_1.assertParamExists('cashbackRedemption', 'amount', amount);
            const localVarPath = `/user/cashbacks/redemption`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(amount, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Change password
         * @param {ChangePassword} changePassword Change password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: (changePassword, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'changePassword' is not null or undefined
            common_1.assertParamExists('changePassword', 'changePassword', changePassword);
            const localVarPath = `/user/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(changePassword, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Compute Cashback
         * @param {ComputeCashback} computeCashback Compute Cashback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        computeCashback: (computeCashback, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'computeCashback' is not null or undefined
            common_1.assertParamExists('computeCashback', 'computeCashback', computeCashback);
            const localVarPath = `/document/transactionprocess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(computeCashback, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Confirm Account
         * @param {ConfirmationAccount} confirmationAccount Confirm Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAccount: (confirmationAccount, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'confirmationAccount' is not null or undefined
            common_1.assertParamExists('confirmAccount', 'confirmationAccount', confirmationAccount);
            const localVarPath = `/authenticate/token/confirmAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(confirmationAccount, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Convert crypto to currency
         * @param {number} [amount] amount
         * @param {string} [from] from which coin amount to be added
         * @param {string} [to] To which currency amount to be converted
         * @param {string} [rateOfchangePercentage] Rate of change percentage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertCryptoToCurrency: (amount, from, to, rateOfchangePercentage, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/counos/crypto2CurrencyConvert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }
            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }
            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }
            if (rateOfchangePercentage !== undefined) {
                localVarQueryParameter['rateOfchangePercentage'] = rateOfchangePercentage;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Convert crypto to currency
         * @param {number} [amount] amount
         * @param {string} [from] from which coin amount to be added
         * @param {string} [to] To which currency amount to be converted
         * @param {string} [rateOfchangePercentage] Rate of change percentage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertCurrencyToCrypto: (amount, from, to, rateOfchangePercentage, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/counos/currency2CryptoConvert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }
            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }
            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }
            if (rateOfchangePercentage !== undefined) {
                localVarQueryParameter['rateOfchangePercentage'] = rateOfchangePercentage;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Delete Partner
         * @param {string} id id of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartner: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deletePartner', 'id', id);
            const localVarPath = `/partner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Delete Partner Admin User
         * @param {string} id id of partner admin user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerAdmin: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deletePartnerAdmin', 'id', id);
            const localVarPath = `/adminUsers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Discount Report download
         * @param {string} date discount report date
         * @param {string} slug slug of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountReport: (date, slug, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'date' is not null or undefined
            common_1.assertParamExists('discountReport', 'date', date);
            // verify required parameter 'slug' is not null or undefined
            common_1.assertParamExists('discountReport', 'slug', slug);
            const localVarPath = `/discountReports/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }
            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch user bank details
         * @param {string} [sync] force sync by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBankDetails: (sync, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/account/fetch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (sync !== undefined) {
                localVarQueryParameter['sync'] = sync;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * fetch bank list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBanksList: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/banks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch cashback account entries
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {string} [source] source of cashback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCashbackAccountEntries: (skip, limit, source, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/cashbacks/fetch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch cashback details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCashbackDetails: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/cashbacks/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch cashback files
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCashbackFiles: (skip, limit, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cashbackProcessor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * fetch cashback files by search
         * @param {string} search
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCashbackFilesBySearch: (search, skip, limit, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'search' is not null or undefined
            common_1.assertParamExists('fetchCashbackFilesBySearch', 'search', search);
            const localVarPath = `/cashbackProcessor/cashbackFile/search/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch discount entries
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDiscountEntries: (skip, limit, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/cashbacks/fetch-discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch donation details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDonationDetails: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/donations/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch user donations
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDonations: (skip, limit, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/donations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch hbl token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchHBLToken: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/hbl/doapi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch latest cashback account entries
         * @param {string} [source] source of cashback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchLatestCashbacks: (source, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/cashbacks/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch latest transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchLatestTransactions: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/transactions/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch user rewards
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchNewRewards: (skip, limit, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/rewards/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * fetch partners by search
         * @param {string} search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnersBySearch: (search, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'search' is not null or undefined
            common_1.assertParamExists('fetchPartnersBySearch', 'search', search);
            const localVarPath = `/partner/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch user rewards
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchRewards: (skip, limit, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/rewards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch user total discount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTotalDiscount: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/profile/fetch-total-discount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch user transactions
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTransactions: (skip, limit, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch user invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUserInvitations: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch vouchers based on partner id
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVouchers: (partnerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            common_1.assertParamExists('fetchVouchers', 'partnerId', partnerId);
            const localVarPath = `/voucher/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * fetch vouchers by search
         * @param {string} search
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVouchersBySearch: (search, partnerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'search' is not null or undefined
            common_1.assertParamExists('fetchVouchersBySearch', 'search', search);
            // verify required parameter 'partnerId' is not null or undefined
            common_1.assertParamExists('fetchVouchersBySearch', 'partnerId', partnerId);
            const localVarPath = `/voucher/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Generate QR code
         * @param {CounosOrder} counosOrder Generate QR code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateQRCode: (counosOrder, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'counosOrder' is not null or undefined
            common_1.assertParamExists('generateQRCode', 'counosOrder', counosOrder);
            const localVarPath = `/counos/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(counosOrder, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get Active Tickers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveTickers: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/counos/tickers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get Aws Tokens
         * @param {Code} code Get AWS Tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAwsTokens: (code, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'code' is not null or undefined
            common_1.assertParamExists('getAwsTokens', 'code', code);
            const localVarPath = `/authenticate/token/awsTokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(code, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get Cashback files by date
         * @param {string} fileUploadDate file upload date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCashbackFilesByMonth: (fileUploadDate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileUploadDate' is not null or undefined
            common_1.assertParamExists('getCashbackFilesByMonth', 'fileUploadDate', fileUploadDate);
            const localVarPath = `/cashbackProcessor/cashbackfiles/fetch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (fileUploadDate !== undefined) {
                localVarQueryParameter['fileUploadDate'] = fileUploadDate;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get Crypto Exchange Rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrytoExchangeRates: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/counos/cryptoExchangeRates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get Partner Details
         * @param {string} slug slug of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartner: (slug, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'slug' is not null or undefined
            common_1.assertParamExists('getPartner', 'slug', slug);
            const localVarPath = `/partner/slug/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get partner active Cards
         * @param {string} id id of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerActiveCards: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getPartnerActiveCards', 'id', id);
            const localVarPath = `/partner/{id}/activeCards`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get Partner Details
         * @param {string} partner partner id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerAdminUsers: (partner, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partner' is not null or undefined
            common_1.assertParamExists('getPartnerAdminUsers', 'partner', partner);
            const localVarPath = `/adminUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            if (partner !== undefined) {
                localVarQueryParameter['partner'] = partner;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get Partner Details
         * @param {string} slug slug of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerCards: (slug, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'slug' is not null or undefined
            common_1.assertParamExists('getPartnerCards', 'slug', slug);
            const localVarPath = `/partner/cards/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get Partner List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerList: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get partner total cashback
         * @param {string} id id of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerTotalCashback: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getPartnerTotalCashback', 'id', id);
            const localVarPath = `/partner/cashback/total/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get platform cashback percentage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformCashbackPercentage: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get Project details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetails: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/shops/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get S3 Url
         * @param {S3Url} s3Url login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS3Url: (s3Url, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 's3Url' is not null or undefined
            common_1.assertParamExists('getS3Url', 's3Url', s3Url);
            const localVarPath = `/document/s3url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(s3Url, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get shop deals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShopDeals: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/shops/deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get shop regions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShopRegions: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/shops/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get shops
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShops: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/shops/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * get sic payment details by id
         * @param {string} id iban of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSicPaymentDetails: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getSicPaymentDetails', 'id', id);
            const localVarPath = `/user/cashbacks/sic-payments/status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Counos transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/counos/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get End User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/profile/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * login
         * @param {Login} login login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: (login, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'login' is not null or undefined
            common_1.assertParamExists('login', 'login', login);
            const localVarPath = `/authenticate/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(login, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Onboard Admin user
         * @param {AdminUser} adminUser Onboard Admin user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onBoardAdminUser: (adminUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'adminUser' is not null or undefined
            common_1.assertParamExists('onBoardAdminUser', 'adminUser', adminUser);
            const localVarPath = `/adminUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(adminUser, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Onboard Partner
         * @param {Partner} partner Onboard Partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardPartner: (partner, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partner' is not null or undefined
            common_1.assertParamExists('onboardPartner', 'partner', partner);
            const localVarPath = `/partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(partner, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Order voucher
         * @param {SendVoucher} sendVoucher Order voucher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderVoucher: (sendVoucher, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sendVoucher' is not null or undefined
            common_1.assertParamExists('orderVoucher', 'sendVoucher', sendVoucher);
            const localVarPath = `/voucher/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(sendVoucher, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * passwordRecovery
         * @param {Login} login passwordRecovery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecovery: (login, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'login' is not null or undefined
            common_1.assertParamExists('passwordRecovery', 'login', login);
            const localVarPath = `/authenticate/forgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(login, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Refresh token
         * @param {Refresh} refresh Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: (refresh, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'refresh' is not null or undefined
            common_1.assertParamExists('refreshToken', 'refresh', refresh);
            const localVarPath = `/authenticate/token/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(refresh, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Reset password
         * @param {ResetPassword} resetPassword Reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: (resetPassword, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'resetPassword' is not null or undefined
            common_1.assertParamExists('resetPassword', 'resetPassword', resetPassword);
            const localVarPath = `/authenticate/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(resetPassword, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Submit Sugestion or Query
         * @param {Ticket} ticket Submit Sugestion or Query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTicket: (ticket, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'ticket' is not null or undefined
            common_1.assertParamExists('submitTicket', 'ticket', ticket);
            const localVarPath = `/support`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(ticket, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Transactions Export
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsExport: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/transactions/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update Admin user
         * @param {AdminUser} adminUser Update Admin user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminUser: (adminUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'adminUser' is not null or undefined
            common_1.assertParamExists('updateAdminUser', 'adminUser', adminUser);
            const localVarPath = `/adminUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(adminUser, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update Partner
         * @param {Partner} partner Update Partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartner: (partner, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partner' is not null or undefined
            common_1.assertParamExists('updatePartner', 'partner', partner);
            const localVarPath = `/partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(partner, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update Partner Card Details
         * @param {User} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerCards: (user, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'user' is not null or undefined
            common_1.assertParamExists('updatePartnerCards', 'user', user);
            const localVarPath = `/partner/cards/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(user, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update platform cashback percentage
         * @param {Settings} settings Update platform cashback percentage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlatformCashbackPercentage: (settings, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'settings' is not null or undefined
            common_1.assertParamExists('updatePlatformCashbackPercentage', 'settings', settings);
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(settings, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update User
         * @param {User} user Update User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: (user, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'user' is not null or undefined
            common_1.assertParamExists('updateUser', 'user', user);
            const localVarPath = `/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(user, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update user invitation
         * @param {UserInvitation} userInvitation User invitation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInvitation: (userInvitation, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userInvitation' is not null or undefined
            common_1.assertParamExists('updateUserInvitation', 'userInvitation', userInvitation);
            const localVarPath = `/user/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(userInvitation, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update a voucher
         * @param {Voucher} voucher Update a voucher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVoucher: (voucher, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'voucher' is not null or undefined
            common_1.assertParamExists('updateVoucher', 'voucher', voucher);
            const localVarPath = `/voucher/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            yield common_1.setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(voucher, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Verify Token
         * @param {VerifyToken} verifyToken Verify token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken: (verifyToken, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'verifyToken' is not null or undefined
            common_1.assertParamExists('verifyToken', 'verifyToken', verifyToken);
            const localVarPath = `/authenticate/resetPassword/verifyToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(verifyToken, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
exports.DefaultApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.DefaultApiAxiosParamCreator(configuration);
    return {
        /**
         * Add user
         * @param {User} user user should be added here
         * @param {string} [xCsrfOmni]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(user, xCsrfOmni, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addUser(user, xCsrfOmni, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Add user donation
         * @param {UserDonation} userDonation user donation should be added here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserDonation(userDonation, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addUserDonation(userDonation, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Add a voucher
         * @param {Voucher} voucher Add a voucher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVoucher(voucher, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addVoucher(voucher, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Cashback File Upload
         * @param {CashbackFile} cashbackFile Cashback File Upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashbackFileUpload(cashbackFile, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.cashbackFileUpload(cashbackFile, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Cashback redemption
         * @param {Amount} amount login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashbackRedemption(amount, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.cashbackRedemption(amount, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Change password
         * @param {ChangePassword} changePassword Change password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePassword, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.changePassword(changePassword, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Compute Cashback
         * @param {ComputeCashback} computeCashback Compute Cashback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        computeCashback(computeCashback, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.computeCashback(computeCashback, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Confirm Account
         * @param {ConfirmationAccount} confirmationAccount Confirm Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAccount(confirmationAccount, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.confirmAccount(confirmationAccount, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Convert crypto to currency
         * @param {number} [amount] amount
         * @param {string} [from] from which coin amount to be added
         * @param {string} [to] To which currency amount to be converted
         * @param {string} [rateOfchangePercentage] Rate of change percentage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertCryptoToCurrency(amount, from, to, rateOfchangePercentage, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.convertCryptoToCurrency(amount, from, to, rateOfchangePercentage, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Convert crypto to currency
         * @param {number} [amount] amount
         * @param {string} [from] from which coin amount to be added
         * @param {string} [to] To which currency amount to be converted
         * @param {string} [rateOfchangePercentage] Rate of change percentage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertCurrencyToCrypto(amount, from, to, rateOfchangePercentage, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.convertCurrencyToCrypto(amount, from, to, rateOfchangePercentage, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Delete Partner
         * @param {string} id id of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartner(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deletePartner(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Delete Partner Admin User
         * @param {string} id id of partner admin user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerAdmin(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deletePartnerAdmin(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Discount Report download
         * @param {string} date discount report date
         * @param {string} slug slug of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountReport(date, slug, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.discountReport(date, slug, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch user bank details
         * @param {string} [sync] force sync by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBankDetails(sync, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchBankDetails(sync, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * fetch bank list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBanksList(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchBanksList(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch cashback account entries
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {string} [source] source of cashback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCashbackAccountEntries(skip, limit, source, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchCashbackAccountEntries(skip, limit, source, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch cashback details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCashbackDetails(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchCashbackDetails(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch cashback files
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCashbackFiles(skip, limit, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchCashbackFiles(skip, limit, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * fetch cashback files by search
         * @param {string} search
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCashbackFilesBySearch(search, skip, limit, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchCashbackFilesBySearch(search, skip, limit, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch discount entries
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDiscountEntries(skip, limit, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchDiscountEntries(skip, limit, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch donation details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDonationDetails(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchDonationDetails(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch user donations
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDonations(skip, limit, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchDonations(skip, limit, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch hbl token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchHBLToken(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchHBLToken(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch latest cashback account entries
         * @param {string} [source] source of cashback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchLatestCashbacks(source, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchLatestCashbacks(source, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch latest transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchLatestTransactions(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchLatestTransactions(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch user rewards
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchNewRewards(skip, limit, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchNewRewards(skip, limit, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * fetch partners by search
         * @param {string} search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnersBySearch(search, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchPartnersBySearch(search, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch user rewards
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchRewards(skip, limit, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchRewards(skip, limit, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch user total discount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTotalDiscount(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchTotalDiscount(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch user transactions
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTransactions(skip, limit, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchTransactions(skip, limit, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch user invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUserInvitations(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchUserInvitations(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Fetch vouchers based on partner id
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVouchers(partnerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchVouchers(partnerId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * fetch vouchers by search
         * @param {string} search
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVouchersBySearch(search, partnerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchVouchersBySearch(search, partnerId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Generate QR code
         * @param {CounosOrder} counosOrder Generate QR code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateQRCode(counosOrder, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generateQRCode(counosOrder, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get Active Tickers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveTickers(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getActiveTickers(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get Aws Tokens
         * @param {Code} code Get AWS Tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAwsTokens(code, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAwsTokens(code, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get Cashback files by date
         * @param {string} fileUploadDate file upload date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCashbackFilesByMonth(fileUploadDate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCashbackFilesByMonth(fileUploadDate, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get Crypto Exchange Rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrytoExchangeRates(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCrytoExchangeRates(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get Partner Details
         * @param {string} slug slug of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartner(slug, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPartner(slug, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get partner active Cards
         * @param {string} id id of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerActiveCards(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPartnerActiveCards(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get Partner Details
         * @param {string} partner partner id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerAdminUsers(partner, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPartnerAdminUsers(partner, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get Partner Details
         * @param {string} slug slug of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerCards(slug, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPartnerCards(slug, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get Partner List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerList(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPartnerList(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get partner total cashback
         * @param {string} id id of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerTotalCashback(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPartnerTotalCashback(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get platform cashback percentage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformCashbackPercentage(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPlatformCashbackPercentage(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get Project details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetails(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getProjectDetails(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get S3 Url
         * @param {S3Url} s3Url login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS3Url(s3Url, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getS3Url(s3Url, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get shop deals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShopDeals(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getShopDeals(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get shop regions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShopRegions(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getShopRegions(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get shops
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShops(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getShops(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * get sic payment details by id
         * @param {string} id iban of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSicPaymentDetails(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSicPaymentDetails(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Counos transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTransactions(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Get End User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUser(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * login
         * @param {Login} login login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.login(login, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Onboard Admin user
         * @param {AdminUser} adminUser Onboard Admin user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onBoardAdminUser(adminUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.onBoardAdminUser(adminUser, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Onboard Partner
         * @param {Partner} partner Onboard Partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardPartner(partner, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.onboardPartner(partner, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Order voucher
         * @param {SendVoucher} sendVoucher Order voucher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderVoucher(sendVoucher, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.orderVoucher(sendVoucher, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * passwordRecovery
         * @param {Login} login passwordRecovery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecovery(login, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.passwordRecovery(login, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Refresh token
         * @param {Refresh} refresh Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(refresh, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.refreshToken(refresh, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Reset password
         * @param {ResetPassword} resetPassword Reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPassword, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.resetPassword(resetPassword, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Submit Sugestion or Query
         * @param {Ticket} ticket Submit Sugestion or Query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTicket(ticket, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.submitTicket(ticket, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Transactions Export
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsExport(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.transactionsExport(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Update Admin user
         * @param {AdminUser} adminUser Update Admin user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminUser(adminUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateAdminUser(adminUser, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Update Partner
         * @param {Partner} partner Update Partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartner(partner, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePartner(partner, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Update Partner Card Details
         * @param {User} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerCards(user, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePartnerCards(user, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Update platform cashback percentage
         * @param {Settings} settings Update platform cashback percentage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlatformCashbackPercentage(settings, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePlatformCashbackPercentage(settings, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Update User
         * @param {User} user Update User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(user, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateUser(user, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Update user invitation
         * @param {UserInvitation} userInvitation User invitation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInvitation(userInvitation, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateUserInvitation(userInvitation, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Update a voucher
         * @param {Voucher} voucher Update a voucher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVoucher(voucher, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateVoucher(voucher, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Verify Token
         * @param {VerifyToken} verifyToken Verify token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken(verifyToken, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.verifyToken(verifyToken, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
exports.DefaultApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.DefaultApiFp(configuration);
    return {
        /**
         * Add user
         * @param {User} user user should be added here
         * @param {string} [xCsrfOmni]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(user, xCsrfOmni, options) {
            return localVarFp.addUser(user, xCsrfOmni, options).then((request) => request(axios, basePath));
        },
        /**
         * Add user donation
         * @param {UserDonation} userDonation user donation should be added here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserDonation(userDonation, options) {
            return localVarFp.addUserDonation(userDonation, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a voucher
         * @param {Voucher} voucher Add a voucher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVoucher(voucher, options) {
            return localVarFp.addVoucher(voucher, options).then((request) => request(axios, basePath));
        },
        /**
         * Cashback File Upload
         * @param {CashbackFile} cashbackFile Cashback File Upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashbackFileUpload(cashbackFile, options) {
            return localVarFp.cashbackFileUpload(cashbackFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Cashback redemption
         * @param {Amount} amount login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashbackRedemption(amount, options) {
            return localVarFp.cashbackRedemption(amount, options).then((request) => request(axios, basePath));
        },
        /**
         * Change password
         * @param {ChangePassword} changePassword Change password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePassword, options) {
            return localVarFp.changePassword(changePassword, options).then((request) => request(axios, basePath));
        },
        /**
         * Compute Cashback
         * @param {ComputeCashback} computeCashback Compute Cashback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        computeCashback(computeCashback, options) {
            return localVarFp.computeCashback(computeCashback, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirm Account
         * @param {ConfirmationAccount} confirmationAccount Confirm Account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAccount(confirmationAccount, options) {
            return localVarFp.confirmAccount(confirmationAccount, options).then((request) => request(axios, basePath));
        },
        /**
         * Convert crypto to currency
         * @param {number} [amount] amount
         * @param {string} [from] from which coin amount to be added
         * @param {string} [to] To which currency amount to be converted
         * @param {string} [rateOfchangePercentage] Rate of change percentage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertCryptoToCurrency(amount, from, to, rateOfchangePercentage, options) {
            return localVarFp.convertCryptoToCurrency(amount, from, to, rateOfchangePercentage, options).then((request) => request(axios, basePath));
        },
        /**
         * Convert crypto to currency
         * @param {number} [amount] amount
         * @param {string} [from] from which coin amount to be added
         * @param {string} [to] To which currency amount to be converted
         * @param {string} [rateOfchangePercentage] Rate of change percentage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertCurrencyToCrypto(amount, from, to, rateOfchangePercentage, options) {
            return localVarFp.convertCurrencyToCrypto(amount, from, to, rateOfchangePercentage, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Partner
         * @param {string} id id of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartner(id, options) {
            return localVarFp.deletePartner(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Partner Admin User
         * @param {string} id id of partner admin user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerAdmin(id, options) {
            return localVarFp.deletePartnerAdmin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Discount Report download
         * @param {string} date discount report date
         * @param {string} slug slug of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountReport(date, slug, options) {
            return localVarFp.discountReport(date, slug, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch user bank details
         * @param {string} [sync] force sync by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBankDetails(sync, options) {
            return localVarFp.fetchBankDetails(sync, options).then((request) => request(axios, basePath));
        },
        /**
         * fetch bank list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBanksList(options) {
            return localVarFp.fetchBanksList(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch cashback account entries
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {string} [source] source of cashback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCashbackAccountEntries(skip, limit, source, options) {
            return localVarFp.fetchCashbackAccountEntries(skip, limit, source, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch cashback details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCashbackDetails(options) {
            return localVarFp.fetchCashbackDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch cashback files
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCashbackFiles(skip, limit, options) {
            return localVarFp.fetchCashbackFiles(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * fetch cashback files by search
         * @param {string} search
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCashbackFilesBySearch(search, skip, limit, options) {
            return localVarFp.fetchCashbackFilesBySearch(search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch discount entries
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDiscountEntries(skip, limit, options) {
            return localVarFp.fetchDiscountEntries(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch donation details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDonationDetails(options) {
            return localVarFp.fetchDonationDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch user donations
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDonations(skip, limit, options) {
            return localVarFp.fetchDonations(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch hbl token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchHBLToken(options) {
            return localVarFp.fetchHBLToken(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch latest cashback account entries
         * @param {string} [source] source of cashback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchLatestCashbacks(source, options) {
            return localVarFp.fetchLatestCashbacks(source, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch latest transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchLatestTransactions(options) {
            return localVarFp.fetchLatestTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch user rewards
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchNewRewards(skip, limit, options) {
            return localVarFp.fetchNewRewards(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * fetch partners by search
         * @param {string} search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnersBySearch(search, options) {
            return localVarFp.fetchPartnersBySearch(search, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch user rewards
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchRewards(skip, limit, options) {
            return localVarFp.fetchRewards(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch user total discount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTotalDiscount(options) {
            return localVarFp.fetchTotalDiscount(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch user transactions
         * @param {number} [skip] get items of particular page
         * @param {number} [limit] the number of items to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTransactions(skip, limit, options) {
            return localVarFp.fetchTransactions(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch user invitations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUserInvitations(options) {
            return localVarFp.fetchUserInvitations(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch vouchers based on partner id
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVouchers(partnerId, options) {
            return localVarFp.fetchVouchers(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * fetch vouchers by search
         * @param {string} search
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchVouchersBySearch(search, partnerId, options) {
            return localVarFp.fetchVouchersBySearch(search, partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate QR code
         * @param {CounosOrder} counosOrder Generate QR code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateQRCode(counosOrder, options) {
            return localVarFp.generateQRCode(counosOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Active Tickers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveTickers(options) {
            return localVarFp.getActiveTickers(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Aws Tokens
         * @param {Code} code Get AWS Tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAwsTokens(code, options) {
            return localVarFp.getAwsTokens(code, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Cashback files by date
         * @param {string} fileUploadDate file upload date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCashbackFilesByMonth(fileUploadDate, options) {
            return localVarFp.getCashbackFilesByMonth(fileUploadDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Crypto Exchange Rates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrytoExchangeRates(options) {
            return localVarFp.getCrytoExchangeRates(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Partner Details
         * @param {string} slug slug of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartner(slug, options) {
            return localVarFp.getPartner(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * Get partner active Cards
         * @param {string} id id of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerActiveCards(id, options) {
            return localVarFp.getPartnerActiveCards(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Partner Details
         * @param {string} partner partner id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerAdminUsers(partner, options) {
            return localVarFp.getPartnerAdminUsers(partner, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Partner Details
         * @param {string} slug slug of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerCards(slug, options) {
            return localVarFp.getPartnerCards(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Partner List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerList(options) {
            return localVarFp.getPartnerList(options).then((request) => request(axios, basePath));
        },
        /**
         * Get partner total cashback
         * @param {string} id id of partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerTotalCashback(id, options) {
            return localVarFp.getPartnerTotalCashback(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get platform cashback percentage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformCashbackPercentage(options) {
            return localVarFp.getPlatformCashbackPercentage(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Project details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetails(options) {
            return localVarFp.getProjectDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * Get S3 Url
         * @param {S3Url} s3Url login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS3Url(s3Url, options) {
            return localVarFp.getS3Url(s3Url, options).then((request) => request(axios, basePath));
        },
        /**
         * Get shop deals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShopDeals(options) {
            return localVarFp.getShopDeals(options).then((request) => request(axios, basePath));
        },
        /**
         * Get shop regions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShopRegions(options) {
            return localVarFp.getShopRegions(options).then((request) => request(axios, basePath));
        },
        /**
         * Get shops
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShops(options) {
            return localVarFp.getShops(options).then((request) => request(axios, basePath));
        },
        /**
         * get sic payment details by id
         * @param {string} id iban of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSicPaymentDetails(id, options) {
            return localVarFp.getSicPaymentDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Counos transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions(options) {
            return localVarFp.getTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * Get End User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options) {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
        /**
         * login
         * @param {Login} login login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login, options) {
            return localVarFp.login(login, options).then((request) => request(axios, basePath));
        },
        /**
         * Onboard Admin user
         * @param {AdminUser} adminUser Onboard Admin user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onBoardAdminUser(adminUser, options) {
            return localVarFp.onBoardAdminUser(adminUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Onboard Partner
         * @param {Partner} partner Onboard Partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardPartner(partner, options) {
            return localVarFp.onboardPartner(partner, options).then((request) => request(axios, basePath));
        },
        /**
         * Order voucher
         * @param {SendVoucher} sendVoucher Order voucher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderVoucher(sendVoucher, options) {
            return localVarFp.orderVoucher(sendVoucher, options).then((request) => request(axios, basePath));
        },
        /**
         * passwordRecovery
         * @param {Login} login passwordRecovery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecovery(login, options) {
            return localVarFp.passwordRecovery(login, options).then((request) => request(axios, basePath));
        },
        /**
         * Refresh token
         * @param {Refresh} refresh Refresh
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(refresh, options) {
            return localVarFp.refreshToken(refresh, options).then((request) => request(axios, basePath));
        },
        /**
         * Reset password
         * @param {ResetPassword} resetPassword Reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPassword, options) {
            return localVarFp.resetPassword(resetPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * Submit Sugestion or Query
         * @param {Ticket} ticket Submit Sugestion or Query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTicket(ticket, options) {
            return localVarFp.submitTicket(ticket, options).then((request) => request(axios, basePath));
        },
        /**
         * Transactions Export
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsExport(options) {
            return localVarFp.transactionsExport(options).then((request) => request(axios, basePath));
        },
        /**
         * Update Admin user
         * @param {AdminUser} adminUser Update Admin user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminUser(adminUser, options) {
            return localVarFp.updateAdminUser(adminUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Partner
         * @param {Partner} partner Update Partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartner(partner, options) {
            return localVarFp.updatePartner(partner, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Partner Card Details
         * @param {User} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerCards(user, options) {
            return localVarFp.updatePartnerCards(user, options).then((request) => request(axios, basePath));
        },
        /**
         * Update platform cashback percentage
         * @param {Settings} settings Update platform cashback percentage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlatformCashbackPercentage(settings, options) {
            return localVarFp.updatePlatformCashbackPercentage(settings, options).then((request) => request(axios, basePath));
        },
        /**
         * Update User
         * @param {User} user Update User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(user, options) {
            return localVarFp.updateUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user invitation
         * @param {UserInvitation} userInvitation User invitation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInvitation(userInvitation, options) {
            return localVarFp.updateUserInvitation(userInvitation, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a voucher
         * @param {Voucher} voucher Update a voucher
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVoucher(voucher, options) {
            return localVarFp.updateVoucher(voucher, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify Token
         * @param {VerifyToken} verifyToken Verify token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyToken(verifyToken, options) {
            return localVarFp.verifyToken(verifyToken, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
class DefaultApi extends base_1.BaseAPI {
    /**
     * Add user
     * @param {User} user user should be added here
     * @param {string} [xCsrfOmni]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    addUser(user, xCsrfOmni, options) {
        return exports.DefaultApiFp(this.configuration).addUser(user, xCsrfOmni, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Add user donation
     * @param {UserDonation} userDonation user donation should be added here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    addUserDonation(userDonation, options) {
        return exports.DefaultApiFp(this.configuration).addUserDonation(userDonation, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Add a voucher
     * @param {Voucher} voucher Add a voucher
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    addVoucher(voucher, options) {
        return exports.DefaultApiFp(this.configuration).addVoucher(voucher, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Cashback File Upload
     * @param {CashbackFile} cashbackFile Cashback File Upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    cashbackFileUpload(cashbackFile, options) {
        return exports.DefaultApiFp(this.configuration).cashbackFileUpload(cashbackFile, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Cashback redemption
     * @param {Amount} amount login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    cashbackRedemption(amount, options) {
        return exports.DefaultApiFp(this.configuration).cashbackRedemption(amount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Change password
     * @param {ChangePassword} changePassword Change password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    changePassword(changePassword, options) {
        return exports.DefaultApiFp(this.configuration).changePassword(changePassword, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Compute Cashback
     * @param {ComputeCashback} computeCashback Compute Cashback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    computeCashback(computeCashback, options) {
        return exports.DefaultApiFp(this.configuration).computeCashback(computeCashback, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Confirm Account
     * @param {ConfirmationAccount} confirmationAccount Confirm Account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    confirmAccount(confirmationAccount, options) {
        return exports.DefaultApiFp(this.configuration).confirmAccount(confirmationAccount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Convert crypto to currency
     * @param {number} [amount] amount
     * @param {string} [from] from which coin amount to be added
     * @param {string} [to] To which currency amount to be converted
     * @param {string} [rateOfchangePercentage] Rate of change percentage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    convertCryptoToCurrency(amount, from, to, rateOfchangePercentage, options) {
        return exports.DefaultApiFp(this.configuration).convertCryptoToCurrency(amount, from, to, rateOfchangePercentage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Convert crypto to currency
     * @param {number} [amount] amount
     * @param {string} [from] from which coin amount to be added
     * @param {string} [to] To which currency amount to be converted
     * @param {string} [rateOfchangePercentage] Rate of change percentage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    convertCurrencyToCrypto(amount, from, to, rateOfchangePercentage, options) {
        return exports.DefaultApiFp(this.configuration).convertCurrencyToCrypto(amount, from, to, rateOfchangePercentage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete Partner
     * @param {string} id id of partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    deletePartner(id, options) {
        return exports.DefaultApiFp(this.configuration).deletePartner(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete Partner Admin User
     * @param {string} id id of partner admin user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    deletePartnerAdmin(id, options) {
        return exports.DefaultApiFp(this.configuration).deletePartnerAdmin(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Discount Report download
     * @param {string} date discount report date
     * @param {string} slug slug of partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    discountReport(date, slug, options) {
        return exports.DefaultApiFp(this.configuration).discountReport(date, slug, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch user bank details
     * @param {string} [sync] force sync by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchBankDetails(sync, options) {
        return exports.DefaultApiFp(this.configuration).fetchBankDetails(sync, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * fetch bank list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchBanksList(options) {
        return exports.DefaultApiFp(this.configuration).fetchBanksList(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch cashback account entries
     * @param {number} [skip] get items of particular page
     * @param {number} [limit] the number of items to return
     * @param {string} [source] source of cashback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchCashbackAccountEntries(skip, limit, source, options) {
        return exports.DefaultApiFp(this.configuration).fetchCashbackAccountEntries(skip, limit, source, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch cashback details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchCashbackDetails(options) {
        return exports.DefaultApiFp(this.configuration).fetchCashbackDetails(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch cashback files
     * @param {number} [skip] get items of particular page
     * @param {number} [limit] the number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchCashbackFiles(skip, limit, options) {
        return exports.DefaultApiFp(this.configuration).fetchCashbackFiles(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * fetch cashback files by search
     * @param {string} search
     * @param {number} [skip] get items of particular page
     * @param {number} [limit] the number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchCashbackFilesBySearch(search, skip, limit, options) {
        return exports.DefaultApiFp(this.configuration).fetchCashbackFilesBySearch(search, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch discount entries
     * @param {number} [skip] get items of particular page
     * @param {number} [limit] the number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchDiscountEntries(skip, limit, options) {
        return exports.DefaultApiFp(this.configuration).fetchDiscountEntries(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch donation details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchDonationDetails(options) {
        return exports.DefaultApiFp(this.configuration).fetchDonationDetails(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch user donations
     * @param {number} [skip] get items of particular page
     * @param {number} [limit] the number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchDonations(skip, limit, options) {
        return exports.DefaultApiFp(this.configuration).fetchDonations(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch hbl token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchHBLToken(options) {
        return exports.DefaultApiFp(this.configuration).fetchHBLToken(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch latest cashback account entries
     * @param {string} [source] source of cashback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchLatestCashbacks(source, options) {
        return exports.DefaultApiFp(this.configuration).fetchLatestCashbacks(source, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch latest transactions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchLatestTransactions(options) {
        return exports.DefaultApiFp(this.configuration).fetchLatestTransactions(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch user rewards
     * @param {number} [skip] get items of particular page
     * @param {number} [limit] the number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchNewRewards(skip, limit, options) {
        return exports.DefaultApiFp(this.configuration).fetchNewRewards(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * fetch partners by search
     * @param {string} search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchPartnersBySearch(search, options) {
        return exports.DefaultApiFp(this.configuration).fetchPartnersBySearch(search, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch user rewards
     * @param {number} [skip] get items of particular page
     * @param {number} [limit] the number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchRewards(skip, limit, options) {
        return exports.DefaultApiFp(this.configuration).fetchRewards(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch user total discount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchTotalDiscount(options) {
        return exports.DefaultApiFp(this.configuration).fetchTotalDiscount(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch user transactions
     * @param {number} [skip] get items of particular page
     * @param {number} [limit] the number of items to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchTransactions(skip, limit, options) {
        return exports.DefaultApiFp(this.configuration).fetchTransactions(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch user invitations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchUserInvitations(options) {
        return exports.DefaultApiFp(this.configuration).fetchUserInvitations(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch vouchers based on partner id
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchVouchers(partnerId, options) {
        return exports.DefaultApiFp(this.configuration).fetchVouchers(partnerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * fetch vouchers by search
     * @param {string} search
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    fetchVouchersBySearch(search, partnerId, options) {
        return exports.DefaultApiFp(this.configuration).fetchVouchersBySearch(search, partnerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Generate QR code
     * @param {CounosOrder} counosOrder Generate QR code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    generateQRCode(counosOrder, options) {
        return exports.DefaultApiFp(this.configuration).generateQRCode(counosOrder, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get Active Tickers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getActiveTickers(options) {
        return exports.DefaultApiFp(this.configuration).getActiveTickers(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get Aws Tokens
     * @param {Code} code Get AWS Tokens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getAwsTokens(code, options) {
        return exports.DefaultApiFp(this.configuration).getAwsTokens(code, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get Cashback files by date
     * @param {string} fileUploadDate file upload date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getCashbackFilesByMonth(fileUploadDate, options) {
        return exports.DefaultApiFp(this.configuration).getCashbackFilesByMonth(fileUploadDate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get Crypto Exchange Rates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getCrytoExchangeRates(options) {
        return exports.DefaultApiFp(this.configuration).getCrytoExchangeRates(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get Partner Details
     * @param {string} slug slug of partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getPartner(slug, options) {
        return exports.DefaultApiFp(this.configuration).getPartner(slug, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get partner active Cards
     * @param {string} id id of partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getPartnerActiveCards(id, options) {
        return exports.DefaultApiFp(this.configuration).getPartnerActiveCards(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get Partner Details
     * @param {string} partner partner id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getPartnerAdminUsers(partner, options) {
        return exports.DefaultApiFp(this.configuration).getPartnerAdminUsers(partner, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get Partner Details
     * @param {string} slug slug of partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getPartnerCards(slug, options) {
        return exports.DefaultApiFp(this.configuration).getPartnerCards(slug, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get Partner List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getPartnerList(options) {
        return exports.DefaultApiFp(this.configuration).getPartnerList(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get partner total cashback
     * @param {string} id id of partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getPartnerTotalCashback(id, options) {
        return exports.DefaultApiFp(this.configuration).getPartnerTotalCashback(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get platform cashback percentage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getPlatformCashbackPercentage(options) {
        return exports.DefaultApiFp(this.configuration).getPlatformCashbackPercentage(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get Project details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getProjectDetails(options) {
        return exports.DefaultApiFp(this.configuration).getProjectDetails(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get S3 Url
     * @param {S3Url} s3Url login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getS3Url(s3Url, options) {
        return exports.DefaultApiFp(this.configuration).getS3Url(s3Url, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get shop deals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getShopDeals(options) {
        return exports.DefaultApiFp(this.configuration).getShopDeals(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get shop regions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getShopRegions(options) {
        return exports.DefaultApiFp(this.configuration).getShopRegions(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get shops
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getShops(options) {
        return exports.DefaultApiFp(this.configuration).getShops(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * get sic payment details by id
     * @param {string} id iban of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getSicPaymentDetails(id, options) {
        return exports.DefaultApiFp(this.configuration).getSicPaymentDetails(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Counos transactions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getTransactions(options) {
        return exports.DefaultApiFp(this.configuration).getTransactions(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get End User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getUser(options) {
        return exports.DefaultApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * login
     * @param {Login} login login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    login(login, options) {
        return exports.DefaultApiFp(this.configuration).login(login, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Onboard Admin user
     * @param {AdminUser} adminUser Onboard Admin user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    onBoardAdminUser(adminUser, options) {
        return exports.DefaultApiFp(this.configuration).onBoardAdminUser(adminUser, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Onboard Partner
     * @param {Partner} partner Onboard Partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    onboardPartner(partner, options) {
        return exports.DefaultApiFp(this.configuration).onboardPartner(partner, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Order voucher
     * @param {SendVoucher} sendVoucher Order voucher
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    orderVoucher(sendVoucher, options) {
        return exports.DefaultApiFp(this.configuration).orderVoucher(sendVoucher, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * passwordRecovery
     * @param {Login} login passwordRecovery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    passwordRecovery(login, options) {
        return exports.DefaultApiFp(this.configuration).passwordRecovery(login, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Refresh token
     * @param {Refresh} refresh Refresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    refreshToken(refresh, options) {
        return exports.DefaultApiFp(this.configuration).refreshToken(refresh, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Reset password
     * @param {ResetPassword} resetPassword Reset password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    resetPassword(resetPassword, options) {
        return exports.DefaultApiFp(this.configuration).resetPassword(resetPassword, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Submit Sugestion or Query
     * @param {Ticket} ticket Submit Sugestion or Query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    submitTicket(ticket, options) {
        return exports.DefaultApiFp(this.configuration).submitTicket(ticket, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Transactions Export
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    transactionsExport(options) {
        return exports.DefaultApiFp(this.configuration).transactionsExport(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update Admin user
     * @param {AdminUser} adminUser Update Admin user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    updateAdminUser(adminUser, options) {
        return exports.DefaultApiFp(this.configuration).updateAdminUser(adminUser, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update Partner
     * @param {Partner} partner Update Partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    updatePartner(partner, options) {
        return exports.DefaultApiFp(this.configuration).updatePartner(partner, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update Partner Card Details
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    updatePartnerCards(user, options) {
        return exports.DefaultApiFp(this.configuration).updatePartnerCards(user, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update platform cashback percentage
     * @param {Settings} settings Update platform cashback percentage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    updatePlatformCashbackPercentage(settings, options) {
        return exports.DefaultApiFp(this.configuration).updatePlatformCashbackPercentage(settings, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update User
     * @param {User} user Update User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    updateUser(user, options) {
        return exports.DefaultApiFp(this.configuration).updateUser(user, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update user invitation
     * @param {UserInvitation} userInvitation User invitation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    updateUserInvitation(userInvitation, options) {
        return exports.DefaultApiFp(this.configuration).updateUserInvitation(userInvitation, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update a voucher
     * @param {Voucher} voucher Update a voucher
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    updateVoucher(voucher, options) {
        return exports.DefaultApiFp(this.configuration).updateVoucher(voucher, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Verify Token
     * @param {VerifyToken} verifyToken Verify token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    verifyToken(verifyToken, options) {
        return exports.DefaultApiFp(this.configuration).verifyToken(verifyToken, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.DefaultApi = DefaultApi;
