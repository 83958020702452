import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const CashBackIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <circle
      className="a"
      cx="2.5"
      cy="2.5"
      r="2.5"
      transform="translate(11)"
    />
    <path
      className="a"
      d="M16.5,7H8.617A3.892,3.892,0,0,0,4.858,5.01l-1.14.163L4.746,8.7A7.473,7.473,0,0,0,2.872,11H0v7H2.866A7.467,7.467,0,0,0,9,21.966V24h2V22h4v2h2V21.975A7.5,7.5,0,0,0,16.5,7Zm.5,6H10V11h7Z"
    />

  </svg>, 'CashBackIcon'
);

export default CashBackIcon;
