import { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import toast from 'react-hot-toast';
import useAuth from 'src/hooks/useAuth';
import { REALTIME_WEBSOCKET_URL } from 'src/config';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { WebSocketActions } from 'src/constants';
import FinSignModal from './voucher/FinSignModal';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

interface WebSocketHandlerProps {
  children?: ReactNode;
}

const WebSocketHandler: FC<WebSocketHandlerProps> = () => {
  const { user, updateBankDetails, notifyCashbackRedeemSuccess } = useAuth();
  const [open, setOpenModal] = useState(true);
  const [showFinSign, setShowFinSign] = useState(false);
  const isMediaQUeryMatches = useMediaQuery('(max-width:900px)');

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const rws = new ReconnectingWebSocket(`${REALTIME_WEBSOCKET_URL}${user.email}`);
    rws.addEventListener('message', (message) => {
      const data = JSON.parse(message.data.toString());
      switch (data.ACTION) {
        case WebSocketActions.ACCOUNT_SYNC:
          updateBankDetails(data.balance.amount);
          break;
        case WebSocketActions.VOUCHER_PURCHASE:
          toast.success(data?.message);
          setShowFinSign(true);
          setOpenModal(true);
          break;
        case WebSocketActions.ACCOUNT_SYNC_FAILURE:
          toast.error(data?.message);
          return;
        case WebSocketActions.CASHBACK_REDEEM:
          toast.success(data?.message);
          notifyCashbackRedeemSuccess();
          break;
        case WebSocketActions.CASHBACK_REDEEM_FAILURE:
          toast.error(data?.message);
          break;
        case WebSocketActions.TRANSACTIONS_SYNC_FAILURE:
          toast.error(data?.message);
          break;
        case WebSocketActions.VOUCHER_PURCHASE_FAILURE:
          toast.error(data?.message);
          break;
        default:
          toast.success(data?.message);
      }
    });
  }, []);
  return (
    <div>
      {showFinSign && (
        <FinSignModal
          openModal={open}
          handleClose={handleClose}
          isMediaQUeryMatches={isMediaQUeryMatches}
        />
      )}
    </div>
  );
};

export default WebSocketHandler;
