import type { FC } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  experimentalStyled
} from '@material-ui/core';
import useSettings from '../../hooks/useSettings';
import AlphaLandingBanner from 'src/components/dashboard/alphalanding/AlphaLandingBanner';
import AlphaLandingTopBar from 'src/components/dashboard/alphalanding/AlphaLandingTopBar';
import AlphaLandingJourney from 'src/components/dashboard/alphalanding/AlphaLandingJourney';
import AlphaLandingFooter from 'src/components/dashboard/alphalanding/AlphaLandingFooter';
import AlphaLandingBrands from 'src/components/dashboard/alphalanding/AlphaLandingBrands';
import AlphaLandingBenefit from 'src/components/dashboard/alphalanding/AlphaLandingBenefit';
import useStrapiCms from 'src/hooks/useStrapiCms';

const BoxContainer = experimentalStyled(Box)(
  {
    minHeight: '100%',
    background: 'white',
    overflow: 'auto',
    '& .MuiContainer-root': {
      paddingRight: '0px',
      paddingLeft: '0px',
    }
  }
);

const CircularProgressWrapper = experimentalStyled(CircularProgress)(
  {
    position: 'absolute',
    height: '60px !important',
    width: '60px !important',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  }
);

const AlphaLanding: FC = () => {
  const { settings } = useSettings();
  const { glassMorphismLandingPageContent, isContentLoaded } = useStrapiCms();

  return (
    <>
      {
        isContentLoaded ? (
          <BoxContainer>
            <Container maxWidth={settings.compact ? 'xl' : false}>
              <AlphaLandingTopBar />
              <AlphaLandingBanner
                banner={glassMorphismLandingPageContent?.banner}
              />
              <AlphaLandingJourney
                journey={glassMorphismLandingPageContent?.journey}
              />
              <AlphaLandingBenefit
                benefitsIntroduction={glassMorphismLandingPageContent?.benefitsIntroduction}
                benefitDetails={glassMorphismLandingPageContent?.benefitDetails}
              />
              <AlphaLandingBrands
                branding={glassMorphismLandingPageContent?.branding}
              />
              <AlphaLandingFooter
                goodToKnowIntroduction={glassMorphismLandingPageContent?.goodToKnowIntroduction}
                faqs={glassMorphismLandingPageContent?.goodToKnowDetails}
                socialMedia={glassMorphismLandingPageContent?.socialMedia}
              />
            </Container>
          </BoxContainer>
        ) : (
          <CircularProgressWrapper />
        )
      }
    </>
  );
};

export default AlphaLanding;
