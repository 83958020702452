import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  experimentalStyled
} from '@material-ui/core';
import useStrapiCms from 'src/hooks/useStrapiCms';

const StepperWrapper = experimentalStyled(Stepper)(
  ({ theme }) => (
    {
      '& .MuiStepConnector-root .MuiStepConnector-horizontal': {
        borderColor: theme.palette.primary.main
      }
    }
  )
);

const StepperContainer = experimentalStyled('div')(
  ({ theme }) => (
    {
      '& .MuiStepConnector-line': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
        margin: '0 10px'
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
        fontSize: '30px'
      },
      '& .MuiStepLabel-label': {
        color: theme.palette.text.primary
      },
      '@media (max-width:600px)': {
        marginLeft: '-40px',
        marginRight: '-20px',
        '& .MuiTypography-root': {
          fontSize: '10px'
        }
      }
    }
  )
);

const WizardStepper = () => {
  const { landingPageContent } = useStrapiCms();

  return (
    <StepperContainer>
      <StepperWrapper
        activeStep={0}
        alternativeLabel
      >
        {landingPageContent?.OnboardingSteps.map((item) => (
          <Step key={`${item.id}${item.step}`}>
            <StepLabel>{item.step}</StepLabel>
          </Step>
        ))}
      </StepperWrapper>
    </StepperContainer>
  );
};

export default WizardStepper;
