import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const RewardsIcon = createSvgIcon(
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.7366 4.19995C22.2936 4.19995 22.8277 4.4212 23.2215 4.81503C23.6153 5.20885 23.8366 5.743 23.8366 6.29995V8.25155C23.8369 8.89647 23.6589 9.52892 23.3225 10.0791C22.986 10.6293 22.504 11.0758 21.9298 11.3694L16.0638 14.3654C17.0955 14.8328 17.936 15.6396 18.4452 16.6513C18.9545 17.6631 19.1018 18.8187 18.8627 19.9259C18.6236 21.033 18.0124 22.0248 17.131 22.7362C16.2496 23.4476 15.1511 23.8356 14.0184 23.8356C12.8857 23.8356 11.7872 23.4476 10.9058 22.7362C10.0244 22.0248 9.41322 21.033 9.1741 19.9259C8.93497 18.8187 9.08231 17.6631 9.59157 16.6513C10.1008 15.6396 10.9413 14.8328 11.973 14.3654L6.1084 11.368C5.57351 11.0948 5.11791 10.6886 4.78544 10.1884C4.45296 9.68821 4.25479 9.11088 4.21 8.51195L4.2002 8.25155V6.29995C4.2002 5.743 4.42144 5.20885 4.81527 4.81503C5.2091 4.4212 5.74324 4.19995 6.3002 4.19995H21.7366ZM14.0184 15.323C13.5516 15.323 13.0894 15.4149 12.6581 15.5935C12.2268 15.7722 11.835 16.034 11.5049 16.3641C11.1748 16.6941 10.913 17.086 10.7344 17.5173C10.5557 17.9485 10.4638 18.4108 10.4638 18.8776C10.4638 19.3443 10.5557 19.8066 10.7344 20.2378C10.913 20.6691 11.1748 21.061 11.5049 21.391C11.835 21.7211 12.2268 21.9829 12.6581 22.1616C13.0894 22.3402 13.5516 22.4322 14.0184 22.4322C14.9611 22.4322 15.8653 22.0577 16.5319 21.391C17.1985 20.7244 17.573 19.8203 17.573 18.8776C17.573 17.9348 17.1985 17.0307 16.5319 16.3641C15.8653 15.6975 14.9611 15.323 14.0184 15.323V15.323ZM17.6626 5.59995H10.3686V11.9742L13.7006 13.6766C13.7992 13.7269 13.9084 13.7532 14.0191 13.7532C14.1298 13.7532 14.239 13.7269 14.3376 13.6766L17.6626 11.977V5.59855V5.59995Z" />
  </svg>, 'RewardsIcon'
);

export default RewardsIcon;
