import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  experimentalStyled
} from '@material-ui/core';

const StepperWrapper = experimentalStyled(Box)(
  {
    display: 'flex',
    flexDirection: 'column'
  }
);

const StepperLabelWrapper = experimentalStyled(Box)(
  {
    display: 'flex',
    alignItems: 'center'
  }
);

const useStyles = makeStyles((theme) => ({
  step: {
    height: '30px',
    width: '30px',
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0
  },
  showStep: {
    opacity: '1 !important',
    transition: 'opacity 5s',
    background: 'var(--secondary-color)'
  },
  stepConnector: {
    height: '80px',
    width: '3px',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '13px',
    background: 'gray',
    '@media (max-width:600px)': {
      height: '60px',
    }
  },
  stepConnectorEnabled: {
    background: 'var(--secondary-color) !important',
    transition: 'background 5s',
  },
  stepLabel: {
    color: '#666666',
    fontSize: '18px',
    marginLeft: '20px',
    '@media (max-width:450px)': {
      fontSize: '14px',
    }
  },
  stepLabelEnabled: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 600,
    transition: '1s',
  }
}));

interface JourneyProcess {
  id?: number,
  step: string,
  image: string
}

interface VerticalWizardStepperProps {
  isVisibleInPort: boolean;
  journeyProcess: JourneyProcess[];
}

const VerticalWizardStepper = (props: VerticalWizardStepperProps) => {
  const classes = useStyles();
  const { isVisibleInPort, journeyProcess } = props;
  const isInitialMount = useRef(true);
  const [timeOutIds, setTimeOutIds] = useState<any>([]);

  const refData = [];
  journeyProcess?.forEach(() => {
    refData.push(React.createRef());
  });

  const refs: any = useRef(refData);

  const removeTranslations = () => {
    setTimeout(() => {
      journeyProcess?.forEach((item, refIndex) => {
        const children = refs?.current[refIndex]?.current?.children[0];
        children?.children[0]?.classList?.remove(classes.showStep);
        if (refIndex !== journeyProcess.length - 1) {
          refs?.current[refIndex]?.current?.children[1]?.classList?.remove(classes.stepConnectorEnabled);
        }
        children?.children[1]?.classList?.remove(classes.stepLabelEnabled);
      });
    }, 1000);
  };

  const beginTranslations = (index: number) => {
    let currentIndex = index;
    if (currentIndex === journeyProcess.length) {
      currentIndex = 0;
      removeTranslations();
    } else if (isVisibleInPort) {
      const children = refs?.current[currentIndex]?.current?.children[0];
      children?.children[0]?.classList?.add(classes.showStep);
      if (currentIndex !== journeyProcess.length - 1) {
        refs?.current[currentIndex]?.current?.children[1]?.classList?.add(classes.stepConnectorEnabled);
      }
      children?.children[1]?.classList?.add(classes.stepLabelEnabled);
      currentIndex++;
    }
    if (isVisibleInPort) {
      const timerID = setTimeout(() => beginTranslations(currentIndex), 2500);
      const tempArray = timeOutIds;
      timeOutIds.push(timerID);
      setTimeOutIds(tempArray);
    } else {
      removeTranslations();
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (!isVisibleInPort && !isInitialMount.current) {
      removeTranslations();
      timeOutIds.forEach((item) => {
        clearTimeout(item);
      });
    } else if (isVisibleInPort) {
      setTimeout(() => { beginTranslations(0); }, 1000);
    }
  }, [isVisibleInPort]);

  return (
    <Box>
      <StepperWrapper>
        {
          journeyProcess?.map((item, index) => (
            <Box
              ref={refs.current[index]}
              key={item.id}
            >
              <StepperLabelWrapper>
                <Box className={classes.step}>
                  <Typography sx={{ color: 'white' }}>{index + 1}</Typography>
                </Box>
                <Typography className={classes.stepLabel}>{item.step}</Typography>
              </StepperLabelWrapper>
              {
                (index < journeyProcess.length - 1) && (
                  <Box className={classes.stepConnector} />
                )
              }
            </Box>
          ))
        }
      </StepperWrapper>
    </Box>
  );
};

export default VerticalWizardStepper;
