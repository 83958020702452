import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const DonationsIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 19.5C17.3859 19.5 21.75 15.1359 21.75 9.75C21.75 4.36406 17.3859 0 12 0C6.61406 0 2.25 4.36406 2.25 9.75C2.25 15.1359 6.61406 19.5 12 19.5ZM10.9594 4.56563V3.77812C10.9594 3.34687 11.3062 3 11.7375 3H12.2578C12.6891 3 13.0359 3.34687 13.0359 3.77812V4.575C13.7625 4.6125 14.4656 4.86094 15.0516 5.29688C15.3141 5.48906 15.3422 5.87344 15.1078 6.09844L14.3438 6.825C14.1656 6.99844 13.8984 7.00313 13.6875 6.87188C13.4344 6.7125 13.1531 6.63281 12.8531 6.63281H11.0297C10.6078 6.63281 10.2656 7.01719 10.2656 7.49063C10.2656 7.875 10.5 8.21719 10.8328 8.31562L13.7531 9.19219C14.9578 9.55313 15.8016 10.7109 15.8016 12.0094C15.8016 13.6031 14.5641 14.8922 13.0312 14.9344V15.7219C13.0312 16.1531 12.6844 16.5 12.2531 16.5H11.7328C11.3016 16.5 10.9547 16.1531 10.9547 15.7219V14.925C10.2281 14.8875 9.525 14.6391 8.93906 14.2031C8.67656 14.0109 8.64844 13.6266 8.88281 13.4016L9.64688 12.675C9.825 12.5016 10.0922 12.4969 10.3031 12.6281C10.5563 12.7875 10.8375 12.8672 11.1375 12.8672H12.9609C13.3828 12.8672 13.725 12.4828 13.725 12.0094C13.725 11.625 13.4906 11.2828 13.1578 11.1844L10.2375 10.3078C9.03281 9.94687 8.18906 8.78906 8.18906 7.49063C8.19375 5.89688 9.42656 4.60781 10.9594 4.56563V4.56563ZM22.5 16.5H20.9766C20.0578 17.7188 18.8859 18.7359 17.5547 19.5H20.5453C20.7937 19.5 20.9953 19.6687 20.9953 19.875V20.625C20.9953 20.8313 20.7937 21 20.5453 21H3.45C3.20156 21 3 20.8313 3 20.625V19.875C3 19.6687 3.20156 19.5 3.45 19.5H6.44062C5.10937 18.7359 3.94219 17.7188 3.01875 16.5H1.5C0.670312 16.5 0 17.1703 0 18V22.5C0 23.3297 0.670312 24 1.5 24H22.5C23.3297 24 24 23.3297 24 22.5V18C24 17.1703 23.3297 16.5 22.5 16.5Z" />
  </svg>, 'DonationsIcon'
);

export default DonationsIcon;
