import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const EditIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.008"
    height="18.008"
    viewBox="0 0 18.008 18.008"
    fill="currentColor"
  >
    <path
      className="a"
      d="M4.5,18.753V22.5H8.251L19.314,11.441,15.563,7.69ZM22.215,8.54a1,1,0,0,0,0-1.41L19.874,4.789a1,1,0,0,0-1.41,0L16.633,6.619l3.751,3.751Z"
      transform="translate(-4.5 -4.496)"
    />
  </svg>, 'EditIcon'
);

export default EditIcon;
