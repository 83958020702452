import React from 'react';
import { Button, experimentalStyled } from '@material-ui/core';

interface Props {
  children?: React.ReactNode;
  onClick?: () => void;
  letterSpacing?: string;
  fontSize?: string,
  padding?: string;
  disabled?: boolean,
  startIcon?: React.ReactNode;
}

const PrimaryButton = (props: Props) => {
  const { onClick, children, letterSpacing, fontSize, padding, disabled, startIcon } = props;
  const CustomButton = experimentalStyled(Button)(() => (
    {
      backgroundColor: 'var(--secondary-color)',
      letterSpacing: letterSpacing || '1.6px',
      width: '100%',
      textTransform: 'uppercase',
      color: 'white',
      fontSize: fontSize || '14px',
      padding: padding || '6px 16px',
      '&:hover': {
        backgroundColor: '#183B86',
      }
    }
  ));
  return (
    <CustomButton
      variant="contained"
      type="submit"
      startIcon={startIcon}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </CustomButton>
  );
};

export default PrimaryButton;
